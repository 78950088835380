import React from 'react';

import { Button, Divider, Paper } from '@mui/material';
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';

import classes from './CustomerInbodyAccountCard.module.scss';
import inbodyLogo from '../../../../shared/assets/image/inbody-logo.png';
import {
  CustomerInbodyAccount,
  InbodyAccountInfo,
} from '../../../interfaces/customerInbodyAccount';
import { LbChip } from '../../../../shared/components/ui-components';
import { TitleWithDescription } from '../../../../shared/components/text-formation-components';
import { DataAccessPermissionInfo } from './DataAccessPermissionInfo/DataAccessPermissionInfo';
import { CustomerAccountInfo } from './CustomerAccountInfo/CustomerAccountInfo';

type CustomerInbodyAccountCardProps = {
  inbodyAccount: CustomerInbodyAccount;
  onRefreshInbodyAccountData: (accountInfo: InbodyAccountInfo) => void;
  onRequestInbodyAccountAccess: (accountInfo: InbodyAccountInfo) => void;
};

export const CustomerInbodyAccountCard = ({
  inbodyAccount,
  onRefreshInbodyAccountData,
  onRequestInbodyAccountAccess,
}: CustomerInbodyAccountCardProps) => {
  const { inbodyAccountId, inbodyId, accountName, dataAccessPermission, inbodyKeyId } =
    inbodyAccount;

  const accountInfo: InbodyAccountInfo = {
    accountName,
    inbodyKeyId,
  };

  return (
    <Paper variant="boxWithPadding" className={classes.box}>
      <img src={inbodyLogo} alt="Inbody logo" className={classes.inbodyLogo} />
      <div className={classes.row}>
        <TitleWithDescription
          title={
            <div className={classes.idTitleBox}>
              <span>InBody USA ID:</span>
              <LbChip
                label={inbodyId}
                color="default"
                size="small"
                className="private-info"
              />
            </div>
          }
          description={
            <div>
              <span className={classes.hookName}>{accountName}</span>
              &nbsp;
              <span>
                Click the Refresh Data button to reconnect if there is a connection
                <br />
                error or resend the data request.
              </span>
            </div>
          }
        />
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => {
            onRefreshInbodyAccountData(accountInfo);
          }}
          startIcon={<RefreshRoundedIcon />}
        >
          Refresh Data
        </Button>
      </div>
      <Divider className={classes.divider} />
      <div className={classes.row}>
        <TitleWithDescription
          title="1.Customer Account"
          description={
            <div>
              Ensure customer account has been created. If the account status shows
              <br />
              &quot;No Account,&quot; click the &quot;Refresh Data&quot; button above
            </div>
          }
          titleFont="body-14-bold"
        />
        <CustomerAccountInfo inbodyAccountId={inbodyAccountId} />
      </div>
      <div className={classes.row}>
        <TitleWithDescription
          title="2.Data Access Permissions"
          description={
            <div>
              Once Account is created, click &quot;Request Data Access&quot; to obtain the
              necessary permissions <br /> for uploading InBody scans to the LifeBase
              platform. <br />
              If the app isn’t installed, an email will be sent. If installed, a push
              notification will be sent.
            </div>
          }
          titleFont="body-14-bold"
        />
        <DataAccessPermissionInfo
          inbodyAccountId={inbodyAccountId}
          accessPermission={dataAccessPermission}
          onRequestDataAccess={() => {
            onRequestInbodyAccountAccess(accountInfo);
          }}
        />
      </div>
    </Paper>
  );
};
