import { useEffect, useMemo, useState } from 'react';

import { EntityInbodyData, inbodyDataService } from '../../../../../../inbody';
import { Option } from '../../../../../../general-types';
import { getOptionsByValues } from '../../../../../../shared/utils/arrayUtils';
import {
  InbodyType,
  inbodyTypeOptions,
} from '../../../../../../shared/constants/inbody-info/InbodyType';

export const useInbodyData = (entityId: number) => {
  const [entityInbodyData, setEntityInbodyData] = useState<EntityInbodyData | null>(null);

  const getInbodyData = async () => {
    const data = await inbodyDataService.getEntityInbodyData(entityId);

    setEntityInbodyData(data);
  };

  useEffect(() => {
    getInbodyData();
  }, []);

  const supportedInbodyTypeOptions = useMemo((): Option[] => {
    if (!entityInbodyData) {
      return [];
    }
    const { allowInbodyUsers, allowNonInbodyUsers, supportedInbodyType } =
      entityInbodyData;

    const inbodyTypes = [
      allowInbodyUsers ? supportedInbodyType : '',
      allowNonInbodyUsers ? 'NON_INBODY' : '',
    ].filter(Boolean) as Exclude<InbodyType, 'H20B'>[];

    return getOptionsByValues(inbodyTypeOptions, inbodyTypes);
  }, [entityInbodyData]);

  return { supportedInbodyTypeOptions, entityInbodyData };
};
