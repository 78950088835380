'use strict';

(function () {
    angular
        .module('app.config', [
            'ngIdle',
            'ngRoute',
            'ngMaterial',
            'ngAnimate',
            'ngAria',
            'ngResource',
            'ngSanitize',
            'ngMessages',
            'ngTagsInput',
            'ngFileUpload',
            'ngCountries',
            'ui.bootstrap',
            'dndLists',
            'treasure-overlay-spinner',
            'LocalStorageModule'
        ])
        .config(appConfig)
        .constant('constants', (function () {
            if (window.location.href.indexOf('redirect=') > -1) {
                var path = window.location.href.match(/redirect=([^"]+)/)[1];
                window.location.replace('/#!/' + path);
            }

            return {
                termsAndConditionVersion: '2024.09.17',
                clientId: process.env.APP_CLIENT_ID,
                url: process.env.APP_API_URL,
                reportUrl: process.env.APP_API_REPORTS_URL,
                daysOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
                mealTypes: ['Breakfast', 'Snack', 'Lunch', 'Snack', 'Dinner', 'Snack', 'Pre-Workout', 'Post-Workout'],
                sports: ['Basketball', 'Football', 'Lacrosse', 'Baseball', 'Softball',
                    'Hockey', 'Volleyball', 'Track', 'Golf', 'Cycling', 'Swimming',
                    'Competitive Cheer', 'Running', 'Weight Lifting', 'Soccer', 'Wrestling'],
                positions: [
                    { sport: 'Basketball', positions: ['Coach', 'Guard', 'Forward', 'Center', 'Student'] },
                    { sport: 'Football', positions: ['Coach', 'Offensive Line', 'Defensive Line', 'Quarterback', 'Running Back', 'Wide Receiver', 'Tight End', 'Defensive Back', 'LineBacker', 'Kicker', 'Student'] },
                    { sport: 'Baseball', positions: ['Coach', 'Pitcher', 'Catcher', 'Infielder', 'Outfielder', 'Student'] },
                    { sport: 'Softball', positions: ['Coach', 'Pitcher', 'Catcher', 'Infielder', 'Outfielder', 'Student'] },
                    { sport: 'Volleyball', positions: ['Coach', 'Outside Hitter', 'Middle Blocker', 'Setter', 'Libero', 'Student'] },
                    { sport: 'Lacrosse', positions: ['Coach', 'Offense', 'Defense', 'Goalie', 'Student'] },
                    { sport: 'Hockey', positions: ['Coach', 'Forward', 'Center', 'Defensemen', 'Goalie', 'Student'] },
                    { sport: 'Track', positions: ['Coach', 'Sprinter', 'Medium Distance', 'Long Distance', 'Thrower', 'Hurdler', 'Pole Vaulter', 'Steeple Chase', 'Jumper', 'Student'] },
                    { sport: 'Golf', positions: ['Coach', 'Student'] },
                    { sport: 'Cycling', positions: ['Coach', 'Student'] },
                    { sport: 'Swimming', positions: ['Student'] },
                    { sport: 'Competitive Cheer', positions: ['Student'] },
                    { sport: 'Running', positions: ['Student'] },
                    { sport: 'Weight Lifting', positions: ['Student'] },
                    { sport: 'Soccer', positions: ['Center', 'Forward', 'Defense', 'Goalie'] },
                    {
                        sport: 'Wrestling',
                        positions: [
                            '125 lbs',
                            '133 lbs',
                            '141 lbs',
                            '149 lbs',
                            '157 lbs',
                            '165 lbs',
                            '174 lbs',
                            '184 lbs',
                            '197 lbs',
                            'Heavyweight',
                        ],
                    },
                ],
                sportsForOrganization: ['Basketball', 'Football', 'Baseball', 'Softball', 'Hockey', 'Volleyball', 'Running', 'Cycling'],
                entityTypeOptions: [
                    { label: 'Gym', value: 'GYM' },
                    { label: 'Trainer', value: 'TRAINER' },
                    { label: 'Clinician', value: 'CHIROPRACTOR' },
                    { label: 'Corporate Account', value: 'CORPORATE_ACCOUNT' },
                    { label: 'University', value: 'UNIVERSITY' },
                    { label: 'Sports Organization', value: 'SPORTS_ORGANIZATION' },
                    { label: 'Nutrition Store', value: 'NUTRITION_STORE' },
                    { label: 'School', value: 'SCHOOL' },
                    { label: 'Team', value: 'TEAM' },
                    { label: 'Scholastic', value: 'SCHOLASTIC' },
                    { label: 'Other', value: 'OTHER' },
                ],
                corporationTypes: [
                    {name: 'Sole Proprietorship', dbName: 'SOLE_PROPRIETORSHIP'},
                    {name: 'Single-member LLC', dbName: 'SINGLE_MEMBER_LLC'},
                    {name: 'Multi-member LLC', dbName: 'MULTI_MEMBER_LLC'},
                    {name: 'Private partnership', dbName: 'PRIVATE_PARTNERSHIP'},
                    {name: 'Private corporation', dbName: 'PRIVATE_CORPORATION'},
                ],
                businessTypes: [
                    {name: 'Individual', dbName: 'INDIVIDUAL'},
                    {name: 'Company', dbName: 'COMPANY'},
                ],
                sportsForUniversity: ['Basketball', 'Football', 'Baseball', 'Softball', 'Volleyball', 'Lacrosse', 'Ice Hockey', 'Golf', 'Track'],
                jobDepartments: ['Human Resources', 'Technology Services', 'Finance', 'Customer Service', 'Sales', 'Management', 'Marketing', 'Distribution/Manufacturing', 'Executive', 'Other'],
                occupations: ['Accounting', 'Business Management', 'Automotive', 'Banking', 'BioTech', 'Broadcasting', 'Business Development', 'Construction', 'Plumbing', 'Electrician', 'Customer Service', 'Consulting', 'Health and Fitness', 'Information Technology', 'Programming', 'Networking', 'Engineering', 'Railroad', 'Finance', 'Education', 'Medical', 'Distribution', 'Executive Leadership', 'Small Business Owner'],
                states: ['AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'],
                pages: {
                    coachList: 'coach-list-page',
                    supplementUserList: 'supplement-user-list-page',
                    foodList: 'food-list-page',
                    recipeList: 'recipe-list-page'
                },
                filters: {
                    coachList: 'coach-list-filter',
                    supplementUserList: 'supplement-user-list-filter',
                    foodList: 'food-list-filter'
                },
                tabNames: {
                    SUGGESTED_MEAL_PLAN: 'SUGGESTED_MEAL_PLAN',
                    MEAL_PLAN: 'MEAL_PLAN',
                    EDIT_MACRO_PLAN: 'EDIT_MACRO_PLAN',
                    IN_BODY_INDICATORS: 'IN_BODY_INDICATORS',
                    CHECK_IN_HISTORY: 'CHECK_IN_HISTORY',
                },
                suggestionType: {
                    NONE: 'NONE',
                    DELTA: 'DELTA',
                    SCENARIO: 'SCENARIO'
                },
                inbodyData: {
                    bmr: {
                        dbName: 'body_health_condition_bmr',
                        dbName2: 'bmr',
                        name: 'BMR (Basal Metabolic Rate)',
                        fullName: 'Basal metabolic rate (kcal)',
                        shortName: 'BMR (kcal)',
                        title: 'Basal Metabolic Rate (kcal)',
                        group: 'optional'
                    },
                    height: {
                        dbName: 'height',
                        dbName2: 'height',
                        name: 'Height',
                        fullName: 'Height (inches)',
                        shortName: 'Height (in)',
                        title: 'Height (inches)',
                        required: true,
                        group: 'required'
                    },
                    weight: {
                        dbName: 'body_comp_weight',
                        dbName2: 'weight',
                        name: 'Weight',
                        fullName: 'Weight (lbs)',
                        shortName: 'Weight (lbs)',
                        title: 'Weight (lbs)',
                        required: true,
                        group: 'required'
                    },
                    user_gender: {
                        dbName: 'gender',
                        dbName2: 'gender',
                        name: 'Gender',
                        fullName: 'Gender',
                        shortName: 'Gender',
                        required: true,
                        group: 'required'
                    },
                    percent_body_fat: {
                        dbName: 'obesity_percentBodyFat',
                        dbName2: 'percent_body_fat',
                        name: 'Body Fat %',
                        fullName: 'Pct body fat (%)',
                        shortName: 'Body fat (%)',
                        title: 'Percent Body Fat (%)',
                        required: true,
                        group: 'required'
                    },
                    poundOfFat: {
                        dbName: 'body_comp_bodyFatMass',
                        dbName2: 'poundOfFat',
                        name: 'Pound of Fat',
                        title: 'Pounds of fat (lbs)',
                        group: 'required',
                    },
                    lbm: {
                        dbName: 'body_comp_lbs',
                        dbName2: 'lbm',
                        name: 'LBM',
                        fullName: 'Lean body mass (lbs)',
                        shortName: 'LBM (lbs)',
                        title: 'Lean Body Mass (lbs)',
                        group: 'required'
                    },
                    icw: {
                        dbName: 'body_comp_intracellularWater',
                        dbName2: 'icw',
                        name: 'ICW (Intracellular Water)',
                        fullName: 'Intracellular water (lbs)',
                        shortName: 'ICW (lbs)',
                        title: 'Intracellular Water (lbs)',
                        group: 'optional'
                    },
                    ecw: {
                        dbName: 'body_comp_extracellularWater',
                        dbName2: 'ecw',
                        name: 'ECW (Extracellular Water)',
                        fullName: 'Extracellular water (lbs)',
                        shortName: 'ECW (lbs)',
                        title: 'Extracellular Water (lbs)',
                        group: 'optional'
                    },
                    tbw: {
                        dbName: 'body_comp_bodyWater',
                        dbName2: 'tbw',
                        name: 'TBW (Total Body Water)',
                        fullName: 'Total body water (lbs)',
                        shortName: 'TBW (lbs)',
                        title: 'Total Body Water (lbs)',
                        isWater: true,
                        group: 'optional'
                    },
                    dlm: {
                        dbName: 'body_comp_dryLeanMass',
                        dbName2: 'dlm',
                        name: 'DLM (Dry Lean Mass)',
                        fullName: 'Dry lean mass (lbs)',
                        shortName: 'DLM (lbs)',
                        title: 'Dry Lean Mass (lbs)',
                        group: 'optional'
                    },
                    phaseAngle: {
                        dbName2: 'phase_angle',
                        name: 'Phase Angle',
                        group: 'optional'
                    },
                    smm: {
                        dbName: 'muscle_fat_sceleton_mass',
                        dbName2: 'smm',
                        name: 'SMM (Skeletal Muscle Mass)',
                        fullName: 'Skeletal muscle mass (lbs)',
                        shortName: 'SMM (lbs)',
                        title: 'Skeletal Muscle Mass (lbs)',
                        group: 'optional'
                    },
                    chest: {
                        dbName: 'chest_measurement',
                        dbName2: 'chest',
                        name: 'Chest',
                        fullName: 'Chest circumference (inches)',
                        shortName: 'Chest (in)',
                        title: 'Chest Circumference (inches)',
                        group: 'measurements'
                    },
                    hip: {
                        dbName: 'hip_measurement',
                        dbName2: 'hip',
                        name: 'Hip',
                        fullName: 'Hip circumference (inches)',
                        shortName: 'Hip (in)',
                        title: 'Hip Circumference (inches)',
                        group: 'measurements'
                    },
                    thigh: {
                        dbName: 'thigh_measurement',
                        dbName2: 'thigh',
                        name: 'Thigh',
                        fullName: 'Thigh circumference (inches)',
                        shortName: 'Thigh (in)',
                        title: 'Thigh Circumference (inches)',
                        group: 'measurements'
                    },
                    navel: {
                        dbName: 'navel_measurement',
                        dbName2: 'navel',
                        name: 'Navel (Waist)',
                        fullName: 'Navel circumference (inches)',
                        shortName: 'Navel (in)',
                        title: 'Navel Circumference (inches)',
                        group: 'measurements'
                    },
                    bicep: {
                        dbName: 'bicep_measurement',
                        dbName2: 'bicep',
                        name: 'Bicep (Arm)',
                        fullName: 'Bicep circumference (inches)',
                        shortName: 'Bicep (in)',
                        title: 'Bicep Circumference (inches)',
                        group: 'measurements'
                    },
                    // fields not exist when add new scan
                    neck: {
                        // dbName: 'neck', not exist
                        dbName2: 'neck',
                        name: 'Neck',
                        title: 'Neck Circumference',
                        group: 'measurements'
                    },
                    ecw_tbw: {
                        dbName: 'ecw_tbw',
                        dbName2: 'ecw_tbw',
                        name: 'ECW/TBW',
                        fullName: 'ECW/TBW (ratio)',
                        shortName: 'ECW/TBW (r)',
                        title: 'ECW/TBW (ratio)',
                        isWater: true,
                    },
                },
                inbodyTypes: {
                    NON_INBODY: { dbName: 'non_inbody', value: 'NON_INBODY', name: 'Non InBody' },
                    INBODY_270: { dbName: 'inbody_270', value: 'INBODY_270', name: 'InBody 270' },
                    INBODY_370: { dbName: 'inbody_370', value: 'INBODY_370', name: 'InBody 370' },
                    INBODY_380H: { dbName: 'inbody_380H', value: 'INBODY_380H', name: 'InBody 380H' },
                    INBODY_570: { dbName: 'inbody_570', value: 'INBODY_570', name: 'InBody 570' },
                    INBODY_580H: { dbName: 'inbody_580H', value: 'INBODY_580H', name: 'InBody 580H' },
                    INBODY_770: { dbName: 'inbody_770', value: 'INBODY_770', name: 'InBody 770' },
                    INBODY_970: { dbName: 'inbody_970', value: 'INBODY_970', name: 'InBody 970' },
                },
                measureUnits: [
                    {name: 'Inches', dbName: 'INCHES'},
                    {name: 'Centimeters', dbName: 'CENTIMETERS'},
                ],
                subscriptionTypes: [
                    { dbName: 'SUPPLEMENT', name: 'Supplement Subscription' },
                    { dbName: 'NON_INBODY', name: 'Non InBody Subscription' },
                    { dbName: 'NUTRITION', name: 'Nutrition Subscription' }
                ],
                mealTimeDay: {
                    TODAY: 'TODAY',
                    TOMORROW: 'TOMORROW'
                },
                bodyFatMethods: [
                    {dbName: 'BEST_GUESS', value: 'Best Guess'},
                    {dbName: 'CALIPERS', value: 'Calipers'},
                    {dbName: 'BIO_IMPEDANCE_DEVICE', value: 'Bio-Impedance Device'},
                    {dbName: 'BOD_POD', value: 'Bod Pod'},
                    {dbName: 'DEXA_MACHINE', value: 'Dexa Machine'},
                    {dbName: 'OTHER', value: 'Other'}
                ],
                enums: {
                    subscriptionTypes: {
                        SUPPLEMENT: 'SUPPLEMENT',
                        NON_INBODY: 'NON_INBODY',
                        NUTRITION: 'NUTRITION',
                        SCHOLARSHIP: 'SCHOLARSHIP',
                        CORPORATE: 'CORPORATE',
                        SCHOLASTIC: 'SCHOLASTIC',
                    },
                    entityTypes: {
                        GYM: 'GYM',
                        TRAINER: 'TRAINER',
                        CHIROPRACTOR: 'CHIROPRACTOR',
                        CORPORATE_ACCOUNT: 'CORPORATE_ACCOUNT',
                        UNIVERSITY: 'UNIVERSITY',
                        SPORTS_ORGANIZATION: 'SPORTS_ORGANIZATION',
                        NUTRITION_STORE: 'NUTRITION_STORE',
                        SCHOOL: 'SCHOOL',
                        TEAM: 'TEAM',
                        SCHOLASTIC: 'SCHOLASTIC',
                        OTHER: 'OTHER',
                    },
                    userRoles: {
                        SUPERUSER: 'SUPERUSER',
                        ADMIN: 'ADMIN',
                        AGENT: 'AGENT',
                        CUSTOMER: 'CUSTOMER',
                        FOOD_ADMIN: 'FOOD_ADMIN',
                    },
                    patterns: {
                        PHONE: /^(\+\d{1,2}\s?)?(\(\d{3}\)|\d{3})[\s.-]?\d{3}[\s.-]?\d{4}$/,
                        LETTERS_AND_SPACES: /[A-Za-z ]{1,32}/,
                        ONLY_WORD_CHARACTERS: /^\w+$/,
                        URL: /^(?:http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&\'\(\)\*\+,;=.]+$/,
                    },
                    paidServiceTypes: {
                        BASE_SYSTEM_PRICE: 'BASE_SYSTEM_PRICE',
                        MARKETING: 'MARKETING',
                        NUTRITION: 'NUTRITION',
                        SUBSCRIPTION_MANAGEMENT: 'SUBSCRIPTION_MANAGEMENT',
                        SUPPLEMENT_MEMBER: 'SUPPLEMENT_MEMBER',
                        ACTIVE_CALENDAR: 'ACTIVE_CALENDAR',
                        SCHOLASTIC: "SCHOLASTIC"
                    },
                    paymentFlowType: {
                        OLD: 'OLD', // First version of payment flow
                        NEW: 'NEW', // version when entity pay for users created 2022
                        NEW_USER_FLOW_2024: 'NEW_USER_FLOW_2024',
                    },
                    storageKeys: {
                        PRE_CHECK_IN_USER_DATA: 'PRE_CHECK_IN_USER_DATA',
                        ENABLED_MODULES: 'ENABLED_MODULES',
                        AVAILABLE_MODULES: 'AVAILABLE_MODULES',
                        LOGGED_IN_USER: 'loggedInUser',
                        COMPLIANCE_COACH: 'complianceCoach',
                        IS_ENTITY_HAS_CHILD: 'IS_ENTITY_HAS_CHILD',
                        PAYMENT_FLOW_TYPE: 'PAYMENT_FLOW_TYPE',
                    },
                    eventKeys: {
                        INIT_USER_INFO_FINISHED: "INIT_USER_INFO_FINISHED",
                        UPDATE_USER: 'updateUser',
                    },
                },
                foodTypes: {
                    core: 'core',
                    supplement: 'supplement',
                    veggies: 'veggies',
                    barcode: 'barcode',
                }
            };
        })())
        .run(bootstrap);

    appConfig.$inject = ['$mdAriaProvider', '$httpProvider', '$qProvider', '$mdIconProvider', '$mdThemingProvider',
        'constants', 'localStorageServiceProvider', '$routeProvider', "$mdGestureProvider"];

    function appConfig($mdAriaProvider, $httpProvider, $qProvider, $mdIconProvider, $mdThemingProvider, constants,
                       localStorageServiceProvider, $routeProvider, $mdGestureProvider) {
        localStorageServiceProvider
          .setPrefix('lb')
          .setStorageType('sessionStorage')
          .setNotify(true, true);

        $routeProvider.otherwise({
            template: '<ngz-page-not-found></ngz-page-not-found>',
        });

        // We need to disable all gestures from angular-material library to prevent issues with events duplications
        // Gesture handler from this library is intercept some events and dispatching new ones which can cause issues not only with angular-material components
        // This issues are reproducing on webkit-based browsers on iOS devices
        $mdGestureProvider.disableAll();

        $mdAriaProvider.disableWarnings();

        $httpProvider.defaults.cache = false;
        $httpProvider.interceptors.push('HttpRequestInterceptor');
        $qProvider.errorOnUnhandledRejections(false);
        var ekinTheme = $mdThemingProvider.extendPalette('indigo', {
            '500': '#6d8eee',
            'contrastDefaultColor': '#ffffff'
        });
        var lifeBaseTheme = $mdThemingProvider.extendPalette('orange', {
            '500': '#FF9900',
            'contrastDefaultColor': '#ffffff'
        });
        var lifeBaseWarnTheme = $mdThemingProvider.extendPalette('red', {
            'A700': '#F66464'
        });
        $mdThemingProvider.definePalette('ekinTheme', ekinTheme);
        $mdThemingProvider.definePalette('lifeBaseTheme', lifeBaseTheme);
        $mdThemingProvider.definePalette('lifeBaseWarnTheme', lifeBaseWarnTheme);
        $mdThemingProvider.theme('default')
            .primaryPalette(constants.url !== 'dashboard.lifebase.com' ? 'lifeBaseTheme' : 'ekinTheme')
            .warnPalette('lifeBaseWarnTheme');

        $mdIconProvider
            .icon('arrow-down', 'images/material-icons/arrow-down.svg', 24)
            .icon('arrow-up', 'images/material-icons/arrow-up.svg', 24)
            .icon('arrow-left', 'images/material-icons/arrow-left.svg', 24)
            .icon('arrow-right', 'images/material-icons/arrow-right.svg', 24)
            .icon('arrow-down-full', 'images/material-icons/arrow-down-full.svg', 24)
            .icon('arrow-up-full', 'images/material-icons/arrow-up-full.svg', 24)
            .icon('arrow-age', 'images/material-icons/arrow-age.svg', 24)
            .icon('play-arrow', 'images/material-icons/play-arrow.svg', 24)
            .icon('warning', 'images/material-icons/warning.svg', 24)
            .icon('edit-option', 'images/material-icons/edit-option.svg', 24)
            .icon('edit-option-2', 'images/material-icons/edit-option-2.svg', 24)
            .icon('eye', 'images/material-icons/eye.svg', 24)
            .icon('eye-outline', 'images/material-icons/eye-outline.svg', 24)
            .icon('eye-outline-close', 'images/material-icons/eye-outline-close.svg', 24)
            .icon('mobile-menu', 'images/material-icons/mobile-menu.svg', 40)
            .icon('more-vert', 'images/material-icons/more-vert.svg', 24)
            .icon('trash', 'images/material-icons/trash.svg', 24)
            .icon('remove-pin-code', 'images/material-icons/remove-pin-code.svg', 24)
            .icon('active-status', 'images/material-icons/active-status.svg', 24)
            .icon('inactive-status', 'images/material-icons/inactive-status.svg', 24)
            .icon('logout', 'images/material-icons/logout.svg', 24)
            .icon('message', 'images/material-icons/message.svg', 13)
            .icon('chat', 'images/material-icons/chat.svg', 15)
            .icon('reassign-coach', 'images/material-icons/reassign-coach.svg', 22)
            .icon('profile', 'images/material-icons/profile.svg', 24)
            .icon('pos-status', 'images/material-icons/pos-status.svg', 24)
            .icon('pos-status-failed', 'images/material-icons/pos-status-failed.svg', 24)
            .icon('help', 'images/material-icons/help.svg', 24)
            .icon('info', 'images/material-icons/info.svg', 24)
            .icon('done', 'images/material-icons/done.svg', 24)
            .icon('back-forth-arrows', 'images/material-icons/back-forth-arrows.svg', 24)
            .icon('plus', 'images/material-icons/plus.svg', 24)
            .icon('minus', 'images/material-icons/minus.svg', 24)
            .icon('barcode', 'images/material-icons/barcode.svg', 24)
            .icon('sugar', 'images/material-icons/sugar.svg', 24)
            .icon('search', 'images/material-icons/search.svg', 24)
            .icon('alert', 'images/material-icons/alert.svg', 24)
            .icon('close', 'images/material-icons/close.svg', 24)
            .icon('error-message', 'images/material-icons/error-message.svg', 24)
            .icon('certificate-nutrition', 'images/material-icons/certificate-nutrition.svg', 24)
            .icon('certificate-supplement', 'images/material-icons/certificate-supplement.svg', 24)
            .icon('certificate-1', 'images/material-icons/certificate-1.svg', 24)
            .icon('certificate-2', 'images/material-icons/certificate-2.svg', 24)
            .icon('drag-drop-icon', 'images/material-icons/drag-drop-icon.svg', 24)
            .icon('refund', 'images/material-icons/refund.svg', 24)
            .icon('water', 'images/material-icons/water.svg', 24)
            .icon('communication', 'images/tabs/communication.svg', 1024)
            .icon('compliance', 'images/tabs/compliance.svg', 1024)
            .icon('contest', 'images/tabs/contest.svg', 1024)
            .icon('metrics', 'images/tabs/metrics.svg', 1024)
            .icon('videos', 'images/tabs/videos.svg', 1024)
            .icon('download', 'images/material-icons/download.svg', 24)
            .icon('star', 'images/material-icons/star.svg', 24)
            .icon('goals-tab', 'images/material-icons/goals-tab.svg', 24)
            .icon('goals-tab-active', 'images/material-icons/goals-tab-active.svg', 24)
            .icon('inbody-indicators-tab', 'images/material-icons/inbody-indicators-tab.svg', 24)
            .icon('inbody-indicators-tab-active', 'images/material-icons/inbody-indicators-tab-active.svg', 24)
            .icon('meal-reports-tab', 'images/material-icons/meal-reports-tab.svg', 24)
            .icon('meal-reports-tab-active', 'images/material-icons/meal-reports-tab-active.svg', 24)
            .icon('recipes-tab', 'images/material-icons/meal-reports-tab.svg', 24)
            .icon('recipes-tab-active', 'images/material-icons/meal-reports-tab-active.svg', 24)
            .icon('meal-time-tab', 'images/material-icons/meal-time-tab.svg', 24)
            .icon('meal-time-tab-active', 'images/material-icons/meal-time-tab-active.svg', 24)
            .icon('meal-plan-macros-tab', 'images/material-icons/meal-plan-macros-tab.svg', 24)
            .icon('meal-plan-macros-tab-active', 'images/material-icons/meal-plan-macros-tab-active.svg', 24)
            .icon('customer-profile-tab', 'images/material-icons/customer-profile-tab.svg', 24)
            .icon('customer-profile-tab-active', 'images/material-icons/customer-profile-tab-active.svg', 24)
            .icon('overview-tab', 'images/material-icons/overview-tab.svg', 24)
            .icon('overview-tab-active', 'images/material-icons/overview-tab-active.svg', 24)
            .icon('help-tab', 'images/material-icons/help-tab.svg', 24)
            .icon('help-tab-active', 'images/material-icons/help-tab-active.svg', 24)
            .icon('video-tab', 'images/material-icons/video-tab.svg', 24)
            .icon('video-tab-active', 'images/material-icons/video-tab-active.svg', 24)
            .icon('printer', 'images/material-icons/printer.svg', 24)
            .icon('printer-dark', 'images/material-icons/printer-dark.svg', 24)
            .icon('mail', 'images/material-icons/mail.svg', 24)
            .icon('current-goal', 'images/material-icons/current-goal.svg', 24)
            .icon('options-gear', 'images/material-icons/options-gear.svg', 24)
            .icon('tablets', 'images/material-icons/tablets.svg', 24)
            .icon('widgets', 'images/material-icons/widgets.svg', 24)
            .icon('1lvl-certificate', 'images/vectors/1lvl-certificate-1.svg', 210)
            .icon('2lvl-certificate', 'images/vectors/2lvl-certificate-1.svg', 210)
            .icon('certificate-nutr', 'images/vectors/certificate-1.svg', 210)
            .icon('certificate-suppl', 'images/vectors/certificate-2.svg', 210)
            .icon('certificate-icon', 'images/vectors/certificate-icon.svg', 54)
            .icon('refresh', 'images/material-icons/refresh.svg', 24)
            .icon('social-link', 'images/vectors/social-link.svg', 24)
            .icon('equals', 'images/vectors/equals.svg', 24);
    }

    bootstrap.$inject = ['$rootScope', 'utilService', 'AnalyticService'];

    function bootstrap($rootScope) {
        $rootScope.selectClicked = function ($event) {
            var myContainer = document.getElementsByClassName('md-select-menu-container md-active');
            setTimeout(function () {
                if (myContainer[0]) {
                    angular.element(myContainer).css({'top': Math.min($event.currentTarget.getBoundingClientRect().top, document.body.getBoundingClientRect().height - myContainer[0].getBoundingClientRect().height) + 'px'});
                }
            }, 50);
        };
    }
})();
