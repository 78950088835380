import React, { ReactNode } from 'react';

import { RadioGroup } from '@mui/material';

import classes from './SelectSubscriptionCard.module.scss';
import { InfoBox } from '../../../../shared/components/ui-components';
import { TitleWithDescription } from '../../../../shared/components/text-formation-components';
import { ShowMoreContainer } from '../../../../shared/components/ShowMoreContainer/ShowMoreContainer';
import { CustomerSubscription } from '../../../interfaces/customerSubscription';
import { SubscriptionCard } from '../../../components/SubscriptionRow/SubscriptionCard';

type SelectSubscriptionCardProps = {
  subscriptions: CustomerSubscription[];
  discountPercent: number;
  selectedSubscriptionId: number | null;
  setSelectedSubscriptionId: (subscriptionId: number) => void;
  titleDescription?: ReactNode;
  extraInfo?: ReactNode;
};

const SelectSubscriptionCard = ({
  subscriptions,
  discountPercent,
  selectedSubscriptionId,
  setSelectedSubscriptionId,
  titleDescription,
  extraInfo,
}: SelectSubscriptionCardProps) => {
  return (
    <InfoBox
      title={
        <TitleWithDescription
          title="Subscriptions"
          description={titleDescription}
          titleFont="title-16-bold"
        />
      }
    >
      {extraInfo && <div className={classes.extraInfo}>{extraInfo}</div>}
      <RadioGroup value={selectedSubscriptionId}>
        <ShowMoreContainer
          items={subscriptions}
          filter={(item) => item.owner.roleType === 'SUPERUSER'}
          className={classes.rows}
          renderItem={(item) => (
            <SubscriptionCard
              key={item.id}
              subscription={item}
              discountPercent={discountPercent}
              checked={item.id === selectedSubscriptionId}
              onClick={setSelectedSubscriptionId}
            />
          )}
          showMoreText="Show more subscriptions"
          showLessText="Show less subscriptions"
        />
      </RadioGroup>
    </InfoBox>
  );
};

export { SelectSubscriptionCard, type SelectSubscriptionCardProps };
