import React from 'react';

import { useFormContext } from 'react-hook-form';
import { observer } from 'mobx-react';

import { ScholasticCustomerSetupFormValue } from '../../NgzScholasticUserSetup.settings';
import { scholasticCustomerService } from '../../../../services/scholasticCustomerService';
import { setupScholasticCustomerStore } from '../../../../stores/setupScholasticCustomerStore';
import { ButtonsRow } from '../../components/ButtonRow/ButtonRow';
import { ScholasticProfileInputs } from '../../../../components/ScholasticProfileInputs/ScholasticProfileInputs';
import { useSchoolsList } from '../../../../../shared/hooks/schools/useSchoolsList';

const UserProfileStep = observer(() => {
  const { handleSubmit, setValue } = useFormContext<ScholasticCustomerSetupFormValue>();
  const { schoolList } = useSchoolsList({ onlyActive: true, isScholastic: true });

  const { stepperMethods, setUserId, getPredefinedMealPlansInfo } =
    setupScholasticCustomerStore;
  const { goToNextStep } = stepperMethods;

  const onSubmit = async ({ userData }: ScholasticCustomerSetupFormValue) => {
    const { user } = await scholasticCustomerService.sendUser(userData);

    setValue('userData.user.userId', user.userId);
    setUserId(user.userId);
    // get data for next step
    await getPredefinedMealPlansInfo();
    goToNextStep();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <ScholasticProfileInputs affiliateList={schoolList} isShowAddress />
      <ButtonsRow showCancel />
    </form>
  );
});

export { UserProfileStep };
