'use strict';

angular.module('app')
    .controller('CustomerMealPlanTabController', CustomerMealPlanTabController);

CustomerMealPlanTabController.$inject = [
    '$scope',
    '$rootScope',
    '$routeParams',
    'utilService',
    'ScenariosService',
    'MealPlanService',
    'ModalService',
    'CustomerService',
    'DateTimeUtil',
    'constants',
    'AnalyticService'
];

function CustomerMealPlanTabController($scope, $rootScope, $routeParams, utilService, ScenariosService,
                                       MealPlanService, ModalService, CustomerService, DateTimeUtil, constants, AnalyticService) {
    var vm = this;
    vm.loggedInUser = utilService.getLoggedInUserData();
    vm.tabName = '';
    vm.NAValue = 'n/a';
    vm.currentMealPlan = null;
    vm.suggestedMealPlan = null;
    vm.tabNames = constants.tabNames;
    // vm.tommorrowDate = DateTimeUtil.formatDate(DateTimeUtil.minusDays(DateTimeUtil.now(), -1), 'MM.DD.YYYY');
    vm.mealPlanPage = vm.tabNames.MEAL_PLAN;
    vm.spinner = {active: false};
    vm.planTab = 'SUGGESTED_PLAN';
    vm.suggestedMealPlansInfo = {};
    vm.mealPlanObject = {
        breakFast: {id: 1, name: 'Breakfast'},
        snack1: {id: 2, name: 'Snack'},
        lunch: {id: 3, name: 'Lunch'},
        snack2: {id: 4, name: 'Snack'},
        dinner: {id: 5, name: 'Dinner'},
        snack3: {id: 6, name: 'Bedtime snack'},
    };
    vm.currentCustomerDetails = {};
    vm.totalWorkouts = 0;
    vm.activityIndices = [];
    vm.currentActivity = {};

    vm.initCurrentPage = initCurrentPage;
    vm.getMealPlans = getMealPlans;
    vm.goToEditMacroPlan = goToEditMacroPlan;
    vm.calculateTotal = calculateTotal;
    vm.showSuggestScenarioDialog = showSuggestScenarioDialog;
    vm.calculateSuggestions = calculateSuggestions;
    vm.findMealPlanByName = findMealPlanByName;
    vm.showRemovePlanDialog = showRemovePlanDialog;
    vm.deleteMealPlan = deleteMealPlan;
    vm.showSaveMealPlanDialog = showSaveMealPlanDialog;
    vm.showSaveConfirmModal = showSaveConfirmModal;
    vm.saveMealPlanMacros = saveMealPlanMacros;
    vm.addNewMealPlan = addNewMealPlan;
    vm.getSuggestedMealPlanWithNewNumOfMeals = getSuggestedMealPlanWithNewNumOfMeals;
    vm.calculateCalories = calculateCalories;
    vm.findMealPlanById = findMealPlanById;
    vm.keepExistingMealPlans = keepExistingMealPlans;
    vm.showKeepExistingMealPlansDialog = showKeepExistingMealPlansDialog;
    vm.showAcceptSuggestedMealPlanDialog = showAcceptSuggestedMealPlanDialog;
    vm.generateMealPlanName = generateMealPlanName;
    vm.getCustomerDetails = getCustomerDetails;
    vm.generateNewMealPlan = generateNewMealPlan;
    vm.getActivities = getActivities;
    vm.getWorkout = getWorkout;
    vm.findActivityById = findActivityById;
    vm.compareMealPlanNames = compareMealPlanNames;

    $scope.$on('loadScans', vm.getMealPlans);

    vm.initCurrentPage();

    function initCurrentPage(selectedPlan) {
        if (vm.mealPlanPage === vm.tabNames.MEAL_PLAN || vm.mealPlanPage === vm.tabNames.EDIT_MACRO_PLAN ||
            vm.mealPlanPage === vm.tabNames.SUGGESTED_MEAL_PLAN) {

            if (JSON.parse(localStorage.getItem(vm.tabNames.EDIT_MACRO_PLAN))) {
                vm.mealPlanPage = vm.tabNames.EDIT_MACRO_PLAN;
            } else if (JSON.parse(localStorage.getItem(vm.tabNames.SUGGESTED_MEAL_PLAN))) {
                vm.mealPlanPage = vm.tabNames.SUGGESTED_MEAL_PLAN;
            }

            localStorage.removeItem(vm.tabNames.EDIT_MACRO_PLAN);
            localStorage.removeItem(vm.tabNames.SUGGESTED_MEAL_PLAN);

            scrollTo(0, 0);
            vm.getMealPlans(selectedPlan);
            vm.getCustomerDetails().then(function () {
                vm.getActivities().then(function () {
                    var activityIndex = vm.findActivityById(vm.currentCustomerDetails.customer.activityLevelId);
                    vm.currentActivity = vm.activityIndices[activityIndex];
                    vm.getWorkout();
                })
            });
        }
    }

    function goToEditMacroPlan() {
        vm.mealPlanPage = vm.tabNames.EDIT_MACRO_PLAN;
        vm.initCurrentPage({originId: vm.selectedTab.id, originName: vm.selectedTab.name});
    }

    function getMealPlans(selectedPlan) {
        vm.spinner.active = true;
        MealPlanService.getMealPlan($routeParams.id).then(function (res) {
            vm.spinner.active = false;
            if (res.status === 200) {
                vm.mealPlansInfo = res.data;
                vm.mealPlansInfo.mealPlans.forEach(function (value) {
                    value.totalWater = +value.totalWater.toFixed(1)
                });
                vm.mealPlansInfo.mealPlans = vm.mealPlansInfo.mealPlans.sort(vm.compareMealPlanNames);
                var plan = selectedPlan || vm.mealPlansInfo.mealPlans[0];
                vm.selectedTab = {id: plan.originId, name: plan.originName};
                vm.initialMealPlansInfo = angular.copy(vm.mealPlansInfo);

                if (vm.mealPlanPage === vm.tabNames.SUGGESTED_MEAL_PLAN) {
                    vm.showSuggestScenarioDialog();
                }
            }
        });
    }

    function compareMealPlanNames (a, b) {
        if (a.originName < b.originName) return -1;
        if (a.originName > b.originName) return 1;
        return 0;
    }

    function calculateCalories(type) {
        var plan = vm.mealPlansInfo[type];
        vm.mealPlansInfo[type].calories = (plan.prt * 4) + (plan.fat * 9) + (plan.crb * 4);
    }

    function calculateTotal(plan) {
        plan.total.crb = 0;
        plan.total.fat = 0;
        plan.total.prt = 0;
        plan.total.calories = 0;
        Object.keys(plan).forEach(function (key) {
            if (plan[key] && plan[key].calories) {
                if (key !== 'total') {
                    Object.keys(plan[key]).forEach(function (subkey) {
                        switch (subkey) {
                            case 'crb':
                                plan.total.crb += parseFloat(plan[key][subkey] || 0);
                                break;
                            case 'fat':
                                plan.total.fat += parseFloat(plan[key][subkey] || 0);
                                break;
                            case 'prt':
                                plan.total.prt += parseFloat(plan[key][subkey] || 0);
                                break;
                            case 'calories':
                                plan.total.calories += parseFloat(plan[key][subkey] || 0);
                                break;
                        }
                    });
                }

                plan[key].calories =
                    ((plan[key].prt || 0) * 4) +
                    ((plan[key].fat || 0) * 9) +
                    ((plan[key].crb || 0) * 4);
            }
        });

        plan.sugarTotal = Math.round(plan.total.crb * 0.2);
    }

    function showSuggestScenarioDialog() {
        vm.spinner.active = true;
        ScenariosService.getSuggestionForUser($routeParams.id).then(function (res) {
            vm.spinner.active = false;
            if (res.status === 200) {
                vm.suggestScenario = res.data;
                vm.suggestedMealPlansInfo = angular.copy(vm.mealPlansInfo);
                vm.calculateSuggestions();
                ModalService.suggestScenario(vm.suggestScenario);
            }
        });
    }

    function calculateSuggestions() {
        var maxPossibleProteinSuggestion = Math.abs(vm.suggestScenario.protein) === 14;
        var maxPossibleCarbsSuggestion = Math.abs(vm.suggestScenario.carbs) === 30;
        var maxPossibleFatsSuggestion = Math.abs(vm.suggestScenario.fats) === 10;

        var halfProtein = vm.suggestScenario.protein / 2;
        var halfCarbs = vm.suggestScenario.carbs / 2;
        var halfFats = vm.suggestScenario.fats / 2;

        vm.suggestedMealPlansInfo.mealPlans.forEach(function (plan) {
            plan.breakFast.prt += maxPossibleProteinSuggestion ? halfProtein : vm.suggestScenario.protein;
            plan.lunch.prt += maxPossibleProteinSuggestion ? halfProtein : 0;

            plan.breakFast.crb += maxPossibleCarbsSuggestion ? halfCarbs : vm.suggestScenario.carbs;
            plan.lunch.crb += maxPossibleCarbsSuggestion ? halfCarbs : 0;

            plan.breakFast.fat += maxPossibleFatsSuggestion ? halfFats : vm.suggestScenario.fats;
            plan.lunch.fat += maxPossibleFatsSuggestion ? halfFats : 0;

            vm.calculateTotal(plan);
        });
    }

    function findMealPlanByName(obj, plan) {
        return obj.mealPlans.findIndex(function (value) {
            return value.originName === plan;
        });
    }

    function findMealPlanById(obj, planId) {
        return obj.mealPlans.findIndex(function (value) {
            return value.originId === planId;
        });
    }

    function findActivityById(id) {
        return vm.activityIndices.findIndex(function (value) {
            return value.id === id;
        });
    }

    function showRemovePlanDialog(tab) {
        ModalService.confirm('Are you sure you want to delete ' + tab + '?',
            vm.deleteMealPlan, tab, null, 'Cancel', 'Delete')
    }

    function deleteMealPlan(plan) {
        var mealPlanIndex = vm.findMealPlanByName(vm.mealPlansInfo, plan);
        var mealPlans = angular.copy(vm.initialMealPlansInfo);
        mealPlans.mealPlans.splice(mealPlanIndex, 1);
        MealPlanService.updateMealPlan($routeParams.id, mealPlans)
            .then(function (result) {
                if (result.status === 200) {
                    vm.mealPlansInfo.mealPlans.splice(mealPlanIndex, 1);
                    vm.initialMealPlansInfo.mealPlans.splice(mealPlanIndex, 1);
                    var activePlan = vm.mealPlansInfo.mealPlans[0];
                    vm.selectedTab = {id: activePlan.originId, name: activePlan.originName};
                }
            });
    }

    function showSaveMealPlanDialog() {
        ModalService.confirm('Do you want to save edited meal plans?', vm.saveMealPlanMacros);
    }

    function showSaveConfirmModal() {
        ModalService.saveConfirmModal(vm.saveMealPlanMacros, 'Select how you want macro plan changes to update (make sure that you have correct meal plan in the meal schedule): ');
        AnalyticService.pushEvent('addEvents_makeConversions', 'd-v1-e9', 'meal_adjustment', 'save_btn_clicked', {'customer_id': $routeParams.id});
    }

    function saveMealPlanMacros(suggestedPlan, immediately) {
        vm.mealPlansInfo.mealPlans.forEach(function (value) {
            if (value.mealPlans === 5) {
                value.snack1 = {crb: 0, fat: 0, prt: 0, calories: 0};
            }
        });
        vm.mealPlansInfo.isImmediately = !!immediately;
        if (suggestedPlan) {
            vm.mealPlansInfo.mealPlans = vm.suggestedMealPlansInfo.mealPlans;
        }
        MealPlanService.updateMealPlan($routeParams.id, vm.mealPlansInfo).then(function (res) {
            if (res.status === 200) {
                vm.mealPlanPage = vm.tabNames.MEAL_PLAN;
                vm.mealPlansInfo = res.data;
                vm.mealPlansInfo.mealPlans.forEach(function (value) {
                    value.totalWater = +value.totalWater.toFixed(1)
                });
                vm.mealPlansInfo.mealPlans =
                    vm.mealPlansInfo.mealPlans.sort(vm.compareMealPlanNames);
                vm.initialMealPlansInfo = angular.copy(vm.mealPlansInfo);
                scrollTo(0, 0);

                if (immediately) {
                    ModalService.getInfoMessage('Meal plans changes saved');
                } else {
                    ModalService.getInfoMessage('Meal plans changes will be override by new plans from ' +
                      moment(DateTimeUtil.minusDays(DateTimeUtil.now(), -1)).format('MM.DD.YYYY'));
                }
                AnalyticService.pushEvent('addEvents_makeConversions', 'd-v1-e1', 'meal_adjustment', 'meal_plan_updated', {'customer_id': $routeParams.id});
            }
        });
    }

    function generateMealPlanName() {
        var letters = ("abcdefghijklmnopqrstuvwxyz").toUpperCase().split("");
        var currentLetter = null;
        for (var i = 0; i < letters.length; i++) {
            var plan = vm.mealPlansInfo.mealPlans.find(function (plan) {
                return plan.originName === 'Meal Plan ' + letters[i];
            });
            if (!plan) {
                currentLetter = letters[i];
                break;
            }
        }
        return 'Meal Plan ' + currentLetter;
    }

    function addNewMealPlan() {
        if (vm.mealPlansInfo.mealPlans.length === 20) {
            ModalService.alert(null, 'You have reached the limit for the number of meal plans.');
        } else {
            vm.mealPlanPage = vm.tabNames.EDIT_MACRO_PLAN;
            MealPlanService.getGeneratedMealPlan($routeParams.id).then(function (result) {
                if (result.status === 200) {
                    var newMealPLan = result.data.mealPlans[0];
                    newMealPLan.originName = vm.generateMealPlanName();
                    newMealPLan.name = angular.copy(newMealPLan.originName);
                    newMealPLan.totalWater = +newMealPLan.totalWater.toFixed(1);
                    vm.mealPlansInfo.mealPlans.push(newMealPLan);
                    vm.initialMealPlansInfo.mealPlans.push(angular.copy(newMealPLan));
                    vm.selectedTab = {name: newMealPLan.originName};
                }
            });
        }
    }

    function getSuggestedMealPlanWithNewNumOfMeals() {
        var mealPlanIndex = vm.findMealPlanByName(vm.mealPlansInfo, vm.selectedTab.name);
        if (vm.selectedTab.id) {
            if (vm.initialMealPlansInfo.mealPlans[mealPlanIndex].mealPlan !== vm.mealPlansInfo.mealPlans[mealPlanIndex].mealPlan) {
                vm.spinner.active = true;
                MealPlanService.switchAmountOfMeals($routeParams.id, { mealPlanOrigin: vm.selectedTab.id }).then(function (res) {
                    vm.spinner.active = false;
                    if (res.status === 200) {
                        vm.mealPlansInfo.mealPlans[mealPlanIndex] = res.data;
                        vm.mealPlansInfo.mealPlans[mealPlanIndex].totalWater =
                            +vm.mealPlansInfo.mealPlans[mealPlanIndex].totalWater.toFixed(1);
                        vm.calculateTotal(vm.mealPlansInfo.mealPlans[mealPlanIndex]);
                    }
                });
            } else {
                vm.mealPlansInfo.mealPlans[mealPlanIndex] =
                    angular.copy(vm.initialMealPlansInfo.mealPlans[mealPlanIndex]);
            }
        } else {
            vm.generateNewMealPlan(vm.mealPlansInfo.mealPlans[mealPlanIndex].mealPlan)
                .then(function () {
                    vm.mealPlansInfo.mealPlans[mealPlanIndex] = vm.generatedMealPlan;
                    vm.mealPlansInfo.mealPlans[mealPlanIndex].name =
                        vm.initialMealPlansInfo.mealPlans[mealPlanIndex].name;
                    vm.mealPlansInfo.mealPlans[mealPlanIndex].originName =
                        vm.initialMealPlansInfo.mealPlans[mealPlanIndex].originName;
                })
        }
    }

    function getCustomerDetails() {
        return CustomerService.getCustomerDetails(vm.loggedInUser.id, $routeParams.id)
            .then(function (res) {
                if (res.status === 200) {
                    vm.currentCustomerDetails = res.data;
                }
            });
    }

    function getActivities() {
        return CustomerService.getActivities().then(function (result) {
            if (result.status === 200) {
                vm.activityIndices = result.data;
            }
        });
    }

    function getWorkout() {
        CustomerRoleService.getType($routeParams.id).then(function (res) {
            if (res.status === 200) {
                vm.totalWorkouts = CustomerService.calculateWorkoutHours(res.data);
            }
        });
    }

    function generateNewMealPlan(mealsPerDay) {
        var userInfo = {
            weight: parseFloat(vm.currentCustomerDetails.customer.weight),
            height: parseFloat(vm.currentCustomerDetails.customer.height),
            dateOfBirth: DateTimeUtil.formatDate(vm.currentCustomerDetails.customer.dob, 'MM-DD-YYYY'),
            isMale: vm.currentCustomerDetails.customer.gender.toLowerCase() === 'male',
            bodyFat: (vm.currentCustomerDetails.customer.bodyFat/vm.currentCustomerDetails.customer.weight*100).toFixed(0),
            activityLevel: vm.currentActivity.bmrPercentage,
            totalWorkouts: vm.totalWorkouts,
            mealsPerDay: mealsPerDay
        };

        return MealPlanService.generateMealPlanByUserInfo(userInfo)
            .then(function (result) {
                if (result.status === 200) {
                    vm.generatedMealPlan = result.data.mealPlans[0];
                }
            });
    }

    function showKeepExistingMealPlansDialog() {
        ModalService.confirm(
            'Do you want to keep existing meal plans?',
            vm.keepExistingMealPlans
        );
    }

    function keepExistingMealPlans() {
        vm.mealPlanPage = vm.tabNames.MEAL_PLAN;
        vm.mealPlansInfo = angular.copy(vm.initialMealPlansInfo);
        AnalyticService.pushEvent('addEvents_makeConversions', 'd-v1-e8', 'meal_adjustment', 'keep_existing', {'customer_id': $routeParams.id});
    }

    function showAcceptSuggestedMealPlanDialog() {
        ModalService.confirm(
            'Do you want to accept suggested meal plan?',
            vm.saveMealPlanMacros,
            true
        );
        AnalyticService.pushEvent('addEvents_makeConversions', 'd-v1-e7', 'meal_adjustment', 'accept_suggested_btn_clicked', {'customer_id': $routeParams.id});
    }
}
