import React, { ReactNode } from 'react';

import { SvgIconOwnProps, Tooltip, TooltipProps } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import classes from './InfoTooltip.module.scss';

type InfoTooltipProps = {
  children: ReactNode;
  iconFontSize?: SvgIconOwnProps['fontSize'];
  color?: SvgIconOwnProps['color'];
} & Pick<TooltipProps, 'placement'>;

const InfoTooltip = ({
  children,
  placement = 'bottom',
  iconFontSize = 'small',
  color = 'tertiary',
}: InfoTooltipProps) => {
  return (
    <Tooltip
      title={<div className={classes.textBox}>{children}</div>}
      placement={placement}
    >
      <InfoOutlinedIcon fontSize={iconFontSize} color={color} />
    </Tooltip>
  );
};

export { InfoTooltip, InfoTooltipProps };
