import React, { useEffect, useMemo, useState } from 'react';

import { angularize } from 'react-in-angularjs';

import { SystemProviders } from '../../../../../shared/providers';
import { CheckInHistoryTab } from './CheckInHistoryTab/CheckInHistoryTab';
import {
  OldEntryFieldConfig,
  EntryFieldConfig,
} from '../../../../interfaces/entityInbodyData';
import { getRouteParam } from '../../../../../shared/utils/angularUtils';
import { GetInbodyScansDataResponse } from '../../../../interfaces/customerInbodyData';
import { scanServices } from '../../../../services/scanServices';
import { KeysMissingIn } from '../../../../../utils-types';
import { renameFields } from '../../../../../shared/utils/sortingAndGroupingUtil';

type NgzCheckInHistoryTabProps = {
  entryFields: OldEntryFieldConfig;
};

const renameConfig: Record<
  KeysMissingIn<EntryFieldConfig, OldEntryFieldConfig>,
  keyof EntryFieldConfig
> = {
  ecw_tbw: 'ecwTbw',
  percent_body_fat: 'percentBodyFat',
  phase_angle: 'phaseAngle',
};

const NgzCheckInHistoryTab = ({
  entryFields: oldEntryFields,
}: NgzCheckInHistoryTabProps) => {
  const customerId = getRouteParam('id');

  const entryFields: EntryFieldConfig = useMemo(
    () => renameFields(oldEntryFields, renameConfig),
    [oldEntryFields],
  );

  const [customerInbodyMetrics, setCustomerInbodyMetrics] =
    useState<GetInbodyScansDataResponse | null>(null);

  const getInbodyScans = async () => {
    const inbodyMetrics = await scanServices.getUserInbodyMetrics(customerId);

    setCustomerInbodyMetrics(inbodyMetrics);
  };

  useEffect(() => {
    getInbodyScans();
  }, []);

  return (
    <SystemProviders>
      {customerInbodyMetrics && (
        <CheckInHistoryTab
          entryFields={entryFields}
          customerInbodyMetrics={customerInbodyMetrics}
        />
      )}
    </SystemProviders>
  );
};

angularize(NgzCheckInHistoryTab, 'ngzCheckInHistoryTab', angular.module('app.inbody'), {
  entryFields: '<',
});

export { NgzCheckInHistoryTab };
