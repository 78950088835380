import React from 'react';

import { Button } from '@mui/material';
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';

import classes from './InBodyH20BRequiredInfoModal.module.scss';
import H20BInbodyImg from '../../../shared/assets/image/h20b-inbody.png';
import { LbModal } from '../../../shared/components/modals';
import {
  H20B_INFO_URL,
  H20B_PRICE,
} from '../../../shared/constants/inbody-info/h20bInfo';
import { getPriceView } from '../../../shared/utils/stringUtils';

type InBodyH20BRequiredInfoModalProps = {
  open: boolean;
  onContinue: () => void;
  onGoBack: () => void;
};

const InBodyH20BRequiredInfoModal = ({
  open,
  onContinue,
  onGoBack,
}: InBodyH20BRequiredInfoModalProps) => {
  return (
    <LbModal
      open={open}
      title="InBody H20B Scale"
      text="This scale can be purchased after finalizing your subscription"
      renderBtnBox={
        <div className={classes.btnBox}>
          <Button variant="contained" size="medium" onClick={onContinue}>
            Ok, I understand
          </Button>
          <Button variant="outlined" size="medium" onClick={onGoBack}>
            Go back and choose another coaching option
          </Button>
        </div>
      }
    >
      <div>
        <img src={H20BInbodyImg} alt="InBody H20B Scale" className={classes.img} />
        <div className={classes.infoBox}>
          <h4>REQUIRED PURCHASE</h4>
          <p>
            InBody H20B Scale at-home scale is exclusive to LifeBase clients and lets you
            work with your coach remotely!
          </p>
          <Button
            variant="text"
            size="small"
            color="primary"
            endIcon={<OpenInNewRoundedIcon />}
            href={H20B_INFO_URL}
            target="_blank"
          >
            Learn more info
          </Button>
        </div>
        <div className={classes.priceBox}>
          <span className={classes.initialPrice}>{getPriceView(H20B_PRICE.INITIAL)}</span>
          <span className={classes.discountPrice}>
            {getPriceView(H20B_PRICE.DISCOUNT)}
          </span>
        </div>
      </div>
    </LbModal>
  );
};

export { InBodyH20BRequiredInfoModal };
