import { makeAutoObservable } from 'mobx';
import { SelfSetupCustomer } from '../../interfaces/Customer';
import { resetAllStore } from '../../../shared/stores/resetAllStore';
import { Sport } from '../../../shared/interfaces/Sport';
import { sportsService } from '../../../shared/services/sportsService';
import { SchoolInfo } from '../../../shared/interfaces/school/SchoolInfo';
import { schoolsService } from '../../../shared/services/schools/schoolsService';

class PersonalInfoStore {
  customerId?: number;
  customerData?: SelfSetupCustomer;
  isTermsAccepted: boolean = false;
  entitySchools: SchoolInfo[] = [];
  sports: Sport[] = [];

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setDefaultValues() {
    this.customerId = undefined;
    this.customerData = undefined;
    this.isTermsAccepted = false;
    this.entitySchools = [];
    this.sports = [];
  }

  setCustomerData(customerData: SelfSetupCustomer) {
    this.customerData = customerData;
  }

  setCustomerId(customerId: number) {
    this.customerId = customerId;
  }

  setIsTermsAccepted(isTermsAccepted: boolean) {
    this.isTermsAccepted = isTermsAccepted;
  }

  private setEntitySchools(entitySchools: SchoolInfo[]) {
    this.entitySchools = entitySchools;
  }

  private setSports(sports: Sport[]) {
    this.sports = sports;
  }

  async getEntitySchools(entityId: number) {
    const data = await schoolsService.getPublicEntitySchools(entityId);

    this.setEntitySchools(data.customerSchoolGroups);
  }

  async getSports() {
    const data = await sportsService.getSports();

    this.setSports(data);
  }
}

const personalInfoStore = new PersonalInfoStore();
resetAllStore.addResetMethod(personalInfoStore.setDefaultValues);

export { personalInfoStore, PersonalInfoStore };
