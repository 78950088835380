import { IndicatorScanInfo } from '../../../../../../../interfaces/inbodyIndicator';

const MAX_SCANS = 16;

export const getShowScansData = (
  scans: IndicatorScanInfo[],
  selectedScanTime: string | null,
) => {
  if (scans.length <= MAX_SCANS) {
    return scans;
  }

  if (!selectedScanTime) {
    return scans.slice(-MAX_SCANS);
  }

  const selectScanIndex = scans.findIndex(
    (scan) => scan.scanDateTime === selectedScanTime,
  );

  if (selectScanIndex === -1) {
    return scans.slice(-MAX_SCANS);
  }

  // + 1 because slice exclusive end index
  const startIndex = Math.max(0, selectScanIndex + 1 - MAX_SCANS);
  const endIndex = Math.max(selectScanIndex + 1, MAX_SCANS);

  return scans.slice(startIndex, endIndex);
};
