import React, { useEffect, useState } from 'react';

import { angularize } from 'react-in-angularjs';
import { Container } from '@mui/material';

import classes from './NgzSelectSubscription.module.scss';
import { SystemProviders } from '../../../shared/providers';
import { BlackBgRow } from '../../../shared/components/ui-components';
import { PageTitle } from './PageTitle/PageTitle';
import { SelectSubscriptionBlock } from '../../containers/SelectSubscriptionBlock/SelectSubscriptionBlock';
import { YesNoBtnRow } from '../../../shared/components/buttons';
import { pathQueryParams } from '../../constants/pathQueryParams';
import { getRouteParam, goTo } from '../../../shared/utils/angularUtils';
import { getAccessParamsFromUrl } from '../../../shared/utils/apiUtils';
import { NgzPageNotFound } from '../../../shared/containers/NgzPageNotFound/NgzPageNotFound';
import {
  GetCustomerCouchDataResponse,
  signUpSubscriptionsService,
} from '../../services/signUpSubscriptionsService';
import { checkoutService } from '../../services/checkoutService';
import { getPriceView } from '../../../shared/utils/stringUtils';
import { PAYMENT_RESULT_TYPE } from '../../constants/paymentResultType';
import { CoachCard } from '../../../customer-management';
import { useSelectSubscription } from '../../hooks/useSelectSubscription';

// almost the same as SelectSubscriptionStep
const NgzSelectSubscription = () => {
  const [couchInfo, setCouchInfo] = useState<GetCustomerCouchDataResponse | null>(null);

  const onApplyPromoCode = async (promoCode: string) => {
    if (!couchInfo) {
      throw new Error('Not all required data is set');
    }

    return signUpSubscriptionsService.applyPromoCode(
      customerEmail,
      couchInfo.entityId,
      promoCode,
    );
  };

  const {
    subscriptions,
    discountPercent,
    selectedSubscription,
    isFreeAccessSubscription,
    applyPromoCode,
    setSubscriptions,
    setSelectedSubscriptionId,
  } = useSelectSubscription({ onApplyPromoCode });

  const { accessParams, allParamsExist } = getAccessParamsFromUrl();
  const customerEmail = getRouteParam('email');

  const getPageData = async () => {
    if (!allParamsExist) {
      throw new Error('Not all required data is set');
    }

    const [couchData, subscriptionsInfo] = await Promise.all([
      signUpSubscriptionsService.getCustomerCouchData(accessParams),
      signUpSubscriptionsService.getSubscriptionsForReactivation(accessParams),
    ]);

    setCouchInfo(couchData);
    setSubscriptions(subscriptionsInfo);
  };

  useEffect(() => {
    getPageData();
  }, []);

  if (!allParamsExist) {
    return <NgzPageNotFound />;
  }

  const freeAccessYesButtonText = 'Submit Form';

  const yesButtonText = `Submit Form & Pay ${getPriceView(
    selectedSubscription?.monthlyRenewalPriceFinal,
  )}`;

  const handleNext = async () => {
    if (!selectedSubscription || !couchInfo) {
      throw new Error('Not all required data is set');
    }

    const {
      redirectStatus,
      subscriptionType,
      paymentTimeEpoch,
      senderName,
      entityId,
      email,
      paymentResultType,
    } = pathQueryParams;

    if (isFreeAccessSubscription) {
      const result = await checkoutService.createFreeSubscription(
        couchInfo.entityId,
        customerEmail,
        selectedSubscription.id,
      );

      goTo('/payment-result', {
        params: {
          [redirectStatus]: 'succeeded',
          [subscriptionType]: selectedSubscription.subscriptionType,
          [paymentTimeEpoch]: result.paymentTimeEpoch,
          [senderName]: result.senderName,
          [paymentResultType]: PAYMENT_RESULT_TYPE.REACTIVATION,
        },
      });
    } else {
      goTo('/checkout', {
        params: {
          [entityId]: couchInfo.entityId,
          [email]: customerEmail,
          [pathQueryParams.selectedSubscriptionId]: selectedSubscription.id,
          [paymentResultType]: PAYMENT_RESULT_TYPE.REACTIVATION,
        },
      });
    }
  };

  return (
    <SystemProviders>
      <Container maxWidth="sm" className={classes.container}>
        <BlackBgRow bgRowHeight="330px">
          <PageTitle />
          {couchInfo && <CoachCard coachInfo={couchInfo} consultationType={null} />}
          <SelectSubscriptionBlock
            subscriptions={subscriptions}
            discountPercent={discountPercent}
            onApplyPromoCode={applyPromoCode}
            selectedSubscriptionId={selectedSubscription?.id || null}
            setSelectedSubscriptionId={setSelectedSubscriptionId}
          />
          <YesNoBtnRow
            yesText={isFreeAccessSubscription ? freeAccessYesButtonText : yesButtonText}
            yesHandler={handleNext}
            yesDisabled={!selectedSubscription}
            className={classes.buttonRow}
          />
        </BlackBgRow>
      </Container>
    </SystemProviders>
  );
};

angularize(
  NgzSelectSubscription,
  'ngzSelectSubscription',
  angular.module('app.payment'),
  {},
);

export { NgzSelectSubscription };
