import React from 'react';

import { observer } from 'mobx-react';
import { Button } from '@mui/material';

import { PageTitle } from '../../../../../shared/components/ui-components';
import { inbodyKeysStore } from '../../../../stores/inbodyKeysStore';

const InbodyKeysPageTitle = observer(() => {
  const { isAddNewButtonAvailable, changeModalOpenState } = inbodyKeysStore;

  return (
    <PageTitle title="InBody Keys">
      <Button
        variant="contained"
        disabled={!isAddNewButtonAvailable}
        onClick={changeModalOpenState}
      >
        Add New Key
      </Button>
    </PageTitle>
  );
});

export { InbodyKeysPageTitle };
