import { useEffect, useState } from 'react';

import {
  CustomerInbodyAccount,
  InbodyAccountInfo,
} from '../../../interfaces/customerInbodyAccount';
import { customerInbodyAccountService } from '../../../services/customerInbodyAccountService';
import { updateItemByField } from '../../../../shared/utils/arrayUtils';

export const useCustomerInbodyAccounts = (customerId: string) => {
  const [inbodyAccounts, setInbodyAccounts] = useState<CustomerInbodyAccount[]>([]);

  const getInbodyAccounts = async () => {
    const { customerInbodyAccounts } =
      await customerInbodyAccountService.getCustomerInbodyAccounts(customerId);

    setInbodyAccounts(customerInbodyAccounts);
  };

  useEffect(() => {
    getInbodyAccounts();
  }, []);

  const handleRefreshInbodyAccountData = async (accountInfo: InbodyAccountInfo) => {
    const account = await customerInbodyAccountService.refreshCustomerInbodyAccount(
      customerId,
      accountInfo,
    );

    updateInbodyAccount(account);
  };

  const handleRequestInbodyAccountAccess = async (accountInfo: InbodyAccountInfo) => {
    const account = await customerInbodyAccountService.requestInbodyAccountAccess(
      customerId,
      accountInfo,
    );

    updateInbodyAccount(account);
  };

  const updateInbodyAccount = (account: CustomerInbodyAccount) => {
    const updatedInbodyAccounts = updateItemByField(inbodyAccounts, account, {
      inbodyKeyId: account.inbodyKeyId,
    });

    setInbodyAccounts(updatedInbodyAccounts);
  };

  return {
    inbodyAccounts,
    handleRefreshInbodyAccountData,
    handleRequestInbodyAccountAccess,
  };
};
