import React from 'react';

import {
  FieldValues,
  Path,
  PathValue,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import { TextField, Autocomplete, TextFieldProps } from '@mui/material';
import { useCreatableAutocomplete } from '../hooks/useCreatableAutocomplete';

type LbCreatableAutocompleteProps<
  T extends FieldValues,
  TName extends Path<T>,
  TOption extends Record<string, any>,
> = {
  options: TOption[];
  valueKey?: keyof TOption;
  labelKey?: keyof TOption;
  prepareAddedOption: (value: string) => Promise<TOption> | TOption;
} & UseControllerProps<T, TName> &
  Pick<TextFieldProps, 'label' | 'variant' | 'size' | 'margin' | 'InputProps'>;

const LbCreatableAutocomplete = <
  T extends FieldValues,
  TName extends Path<T>,
  TOption extends Record<string, any>,
>({
  control,
  name,
  rules,
  defaultValue = null as PathValue<T, TName>,
  options,
  label,
  variant,
  size,
  margin,
  shouldUnregister = false,
  labelKey = 'label',
  valueKey = 'value',
  prepareAddedOption,
  InputProps,
}: LbCreatableAutocompleteProps<T, TName, TOption>) => {
  const {
    field: { onChange, ref, ...field },
    fieldState: { error },
  } = useController<any>({
    control,
    name,
    rules,
    defaultValue,
    shouldUnregister,
  });

  const { filterOptions, handleChange, getOptionLabel } = useCreatableAutocomplete({
    prepareAddedOption,
    onChange,
    labelKey,
    valueKey,
  });

  const isRequired = !!rules?.required;

  return (
    <Autocomplete
      {...field}
      onChange={handleChange}
      filterOptions={filterOptions}
      componentsProps={{
        popper: { className: 'general-style variant-select', placement: 'bottom-start' },
      }}
      size={size}
      freeSolo
      selectOnFocus
      clearOnBlur
      disableClearable
      options={options}
      getOptionLabel={getOptionLabel}
      renderOption={(props, option) => (
        <li {...props} key={option[valueKey]}>
          {option[labelKey]}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{ ...params.InputProps, ...InputProps }}
          helperText={error?.message}
          error={!!error}
          label={label}
          variant={variant}
          required={isRequired}
          margin={margin}
          inputRef={ref}
        />
      )}
    />
  );
};

export { LbCreatableAutocomplete };
