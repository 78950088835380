import { customerService } from '../../../../../services/customerService';
import { UserProfileFromValue } from '../NgzUserProfileTabFlow2024.settings';

export const getUserProfileFromValue = async (
  customerId: number,
): Promise<UserProfileFromValue> => {
  const { customer, user, inbodyInfo, additionallyInfo, customerGroup } =
    await customerService.getCustomerProfileFlow2024(customerId);

  return {
    customer,
    user,
    inbodyInfo,
    additionallyInfo,
    customerGroup: customerGroup || null,
  };
};
