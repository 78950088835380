import React from 'react';
import { useFormContext } from 'react-hook-form';

import classes from './CompareScanTable.module.scss';
import { HeaderRow } from './HeaderRow/HeaderRow';
import { CompareMetricRow } from './CompareMetricRow/CompareMetricRow';
import { EmptyList } from '../../../../../../../shared/components/ui-components';
import { InbodyIndicatorInfo } from '../../../../../../interfaces/inbodyIndicator';
import { CompareScanMetricsFormValues } from '../CompareScanMetricsModal.settings';
import { availableIndicators } from './CompareScanTable.settings';

type CompareScanTableProps = {
  customerInbodyIndicators: InbodyIndicatorInfo[];
};

const CompareScanTable = ({ customerInbodyIndicators }: CompareScanTableProps) => {
  const { watch } = useFormContext<CompareScanMetricsFormValues>();
  const { startScanDate, endScanDate } = watch();

  const isShowTable = startScanDate && endScanDate;

  return (
    <div className={classes.compareScanTable}>
      <HeaderRow />
      {isShowTable ? (
        customerInbodyIndicators.map((indicator) => {
          if (!availableIndicators.includes(indicator.key)) {
            return null;
          }

          const startScan = indicator.scans.find(
            (scan) => scan.scanDateTime === startScanDate,
          );
          const endScan = indicator.scans.find(
            (scan) => scan.scanDateTime === endScanDate,
          );

          const metric = indicator.measurement
            ? `${indicator.shortLabel}, ${indicator.measurement}`
            : indicator.shortLabel;

          return (
            <CompareMetricRow
              key={indicator.key}
              metric={metric}
              startValue={startScan?.value}
              endValue={endScan?.value}
            />
          );
        })
      ) : (
        <EmptyList
          show={!isShowTable}
          description="Select scans dates to compare the progress"
          size="small"
        />
      )}
    </div>
  );
};

export { CompareScanTable };
