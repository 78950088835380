import React, {
  MouseEvent,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import classNames from 'classnames';
import classes from './LbHorizontalScrollContainer.module.scss';

const SCROLL_BAR_PADDING = 20;

type LbHorizontalScrollContainerProps = {
  variant?: 'outBox' | 'inBox';
  children: ReactNode;
};

const LbHorizontalScrollContainer = ({
  children,
  variant = 'outBox',
}: LbHorizontalScrollContainerProps) => {
  const contentContainerRef = useRef<HTMLDivElement>(null);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const [scrollBoxWidth, setScrollBoxWidth] = useState(0);
  const [scrollBoxLeft, setScrollBoxLeft] = useState(0);
  const [lastScrollThumbPosition, setLastScrollThumbPosition] = useState(0);
  const [isDragging, setDragging] = useState(false);
  const [isScrollNeeded, setIsScrollNeeded] = useState(false);

  const handleScroll = useCallback(() => {
    if (contentContainerRef.current && scrollContainerRef.current) {
      const { scrollLeft, scrollWidth, offsetWidth } = contentContainerRef.current;

      const leftOffset = scrollContainerRef.current.offsetLeft + SCROLL_BAR_PADDING;
      let newLeft = leftOffset + (scrollLeft / scrollWidth) * offsetWidth;

      newLeft = Math.min(newLeft, leftOffset + offsetWidth - scrollBoxWidth);
      setScrollBoxLeft(newLeft);
    }
  }, [scrollBoxWidth]);

  const calculateScrollBoxWidth = useCallback(() => {
    if (contentContainerRef.current && scrollContainerRef.current) {
      const { clientWidth, scrollWidth } = contentContainerRef.current;

      setIsScrollNeeded(scrollWidth > clientWidth);

      const scrollThumbPercentage = clientWidth / scrollWidth;
      const scrollThumbWidth =
        scrollThumbPercentage * clientWidth - SCROLL_BAR_PADDING * 2;

      setScrollBoxWidth(scrollThumbWidth);

      const leftOffset = scrollContainerRef.current.offsetLeft + SCROLL_BAR_PADDING;
      setScrollBoxLeft(leftOffset);
    }
  }, []);

  const handleResize = useCallback(() => {
    calculateScrollBoxWidth();
    handleScroll();
  }, [calculateScrollBoxWidth, handleScroll]);

  const handleScrollThumbMouseDown = useCallback((event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    setLastScrollThumbPosition(event.clientX);
    setDragging(true);
  }, []);

  const handleDocumentMouseUp = useCallback(
    (event: any) => {
      event.preventDefault();
      if (isDragging) {
        setDragging(false);
      }
    },
    [isDragging],
  );

  const handleDocumentMouseMove = useCallback(
    (event: any) => {
      if (
        isDragging &&
        contentContainerRef.current &&
        scrollContainerRef.current &&
        event.clientX
      ) {
        event.preventDefault();
        event.stopPropagation();
        const { scrollWidth, offsetWidth } = contentContainerRef.current;

        const deltaX = event.clientX - lastScrollThumbPosition;
        const percentage = deltaX * (scrollWidth / offsetWidth);

        const leftBarrier = scrollContainerRef.current.offsetLeft + SCROLL_BAR_PADDING;

        setLastScrollThumbPosition(event.clientX);
        setScrollBoxLeft(
          Math.min(
            Math.max(leftBarrier, scrollBoxLeft + deltaX),
            leftBarrier + offsetWidth - scrollBoxWidth - SCROLL_BAR_PADDING * 2,
          ),
        );
        contentContainerRef.current.scrollLeft = Math.min(
          contentContainerRef.current.scrollLeft + percentage,
          scrollWidth - offsetWidth,
        );
      }
    },
    [isDragging, lastScrollThumbPosition, scrollBoxWidth, scrollBoxLeft],
  );

  useEffect(() => {
    calculateScrollBoxWidth();
  }, [calculateScrollBoxWidth]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    document.addEventListener('mousemove', handleDocumentMouseMove);
    document.addEventListener('mouseup', handleDocumentMouseUp);
    document.addEventListener('mouseleave', handleDocumentMouseUp);
    return () => {
      window.removeEventListener('resize', handleResize);
      document.removeEventListener('mousemove', handleDocumentMouseMove);
      document.removeEventListener('mouseup', handleDocumentMouseUp);
      document.removeEventListener('mouseleave', handleDocumentMouseUp);
    };
  }, [handleDocumentMouseMove, handleDocumentMouseUp, handleResize, handleScroll]);

  const scrollBarClasses = classNames(classes.scrollBar, classes[variant]);

  return (
    <div>
      <div
        ref={contentContainerRef}
        className={classes.contentContainer}
        onScroll={handleScroll}
      >
        {children}
      </div>
      <div ref={scrollContainerRef}>
        {isScrollNeeded && (
          <div className={scrollBarClasses}>
            <div
              className={classNames(classes.scrollThumb, {
                [classes.scrollThumbDragging]: isDragging,
              })}
              style={{ width: scrollBoxWidth, left: scrollBoxLeft }}
              onMouseDown={handleScrollThumbMouseDown}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export { LbHorizontalScrollContainer };
