import React from 'react';

import { useController, useFormContext } from 'react-hook-form';
import { useToggle } from 'react-use';
import { observer } from 'mobx-react';

import { RadioCard } from '../../../components/RadioCard/RadioCard';
import classes from './CollaborationTypeRow.module.scss';
import { CoachListFilterFormValues } from '../../../../../interfaces/coachList/CoachListFilters';
import { TitleWithDescription } from '../../../../../../shared/components/text-formation-components';
import { LbAlert } from '../../../../../../shared/components/ui-components';
import { InBodyH20BRequiredInfoModal } from '../../../../../../inbody';
import {
  CONSULTATION_TYPE,
  ConsultationType,
} from '../../../../../../shared/constants/coach/consultationType';
import { coachStore } from '../../../../../stores/customerSelfSetupStores/coachStore';
import { personalInfoStore } from '../../../../../stores/customerSelfSetupStores/personalInfoStore';

const { REMOTE, OFFLINE } = CONSULTATION_TYPE;

const CollaborationTypeRow = observer(() => {
  const [isShowH20BModal, toggleShowH20BModal] = useToggle(false);
  const { control, reset } = useFormContext<CoachListFilterFormValues>();

  const {
    field: { value },
  } = useController({
    control,
    name: 'consultationType',
  });

  const getFilterData = (
    consultationType: ConsultationType,
  ): CoachListFilterFormValues => {
    const filterData = {
      ...coachStore.filterMethods.defaultFilterData,
      consultationType,
    };

    if (consultationType === OFFLINE) {
      return {
        ...filterData,
        zipCode: personalInfoStore.customerData?.zipCode || '',
        maxDistance: 50,
      };
    } else if (consultationType === REMOTE) {
      return {
        ...filterData,
        zipCode: '',
        maxDistance: '',
      };
    }

    return coachStore.filterMethods.defaultFilterData;
  };

  const handleChangeCoachType = (consultationType: ConsultationType) => {
    const newFilterData = getFilterData(consultationType);

    reset(newFilterData);
    coachStore.filterMethods.resetFilterData(newFilterData); // reset filter data to omit set isSearchUsed to true
  };

  const handleClickRemote = async () => {
    if (value !== REMOTE) {
      toggleShowH20BModal(true);
    }
  };

  return (
    <>
      <div className={classes.container}>
        <RadioCard
          onClick={handleChangeCoachType}
          value={OFFLINE}
          checked={value === OFFLINE}
        >
          <TitleWithDescription
            title="Local in-person coach"
            description="Your initial consultation, and check-ins, will be conducted at your coach's place of business. Please select your coach below."
          />
        </RadioCard>
        <RadioCard onClick={handleClickRemote} value={REMOTE} checked={value === REMOTE}>
          <TitleWithDescription
            title="To work with a coach remotely"
            description={
              <>
                <p className={classes.remotelyInfo}>
                  You will meet with your personal coach via video call and communicate
                  via text and email.
                </p>
                <LbAlert severity="info" size="small" component="span">
                  All of these plans include our exclusive H20B scale which can be
                  purchased after you select your subscription
                </LbAlert>
              </>
            }
          />
        </RadioCard>
      </div>
      <InBodyH20BRequiredInfoModal
        open={isShowH20BModal}
        onContinue={() => {
          handleChangeCoachType(REMOTE);
          toggleShowH20BModal(false);
        }}
        onGoBack={toggleShowH20BModal}
      />
    </>
  );
});

export { CollaborationTypeRow };
