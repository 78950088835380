import React from 'react';

import { Control } from 'react-hook-form';
import { observer } from 'mobx-react';

import {
  InputBox,
  LbDatePicker,
  LbInput,
  LbSelect,
} from '../../../../../shared/components/form-components';
import {
  validateEmail,
  validatePhone,
  validatePercent,
} from '../../../../../shared/utils/reactHookFormUtils';
import { CoachInfoFormValue } from '../../../../interfaces/CoachData';
import { genderOptions } from '../../../../../shared/constants/user-info/gender';
import { loggedInUserDataStore } from '../../../../../shared/stores/loggedInUserDataStore';
import { PaymentFlowTypeFlags } from '../../../../../shared/constants/user-info/paymentFlowType';

type CoachInfoFormSectionProps = {
  control: Control<CoachInfoFormValue>;
  paymentFlowType: PaymentFlowTypeFlags;
};

const CoachInfoFormSection = observer(
  ({ control, paymentFlowType }: CoachInfoFormSectionProps) => {
    const { isCoach } = loggedInUserDataStore;

    const privateInfoProps = { InputProps: { className: 'private-info' } };

    const generalInputProps = {
      control,
      size: 'small',
      variant: 'standard',
      ...privateInfoProps,
    } as const;

    return (
      <InputBox title="Coach Info">
        <LbInput
          {...generalInputProps}
          label="First Name"
          name="firstName"
          rules={{
            required: 'First name is required',
          }}
        />
        <LbInput
          {...generalInputProps}
          label="Last Name"
          name="lastName"
          rules={{
            required: 'Last name is required',
          }}
        />
        <LbInput {...generalInputProps} label="Job Title" name="additional.jobTitle" />
        <LbInput
          {...generalInputProps}
          label="Email"
          name="email"
          type="email"
          rules={{
            required: 'Email is required',
            validate: { validateEmail },
          }}
        />
        <LbInput
          {...generalInputProps}
          label="Phone Number"
          name="additional.cellPhone"
          type="tel"
          rules={{
            required: 'Cell phone is required',
            validate: { validatePhone },
          }}
        />
        <LbSelect
          {...generalInputProps}
          label="Gender"
          name="additional.gender"
          options={genderOptions}
        />
        <LbDatePicker
          {...generalInputProps}
          label="Date of Birth"
          name="additional.dateOfBirth"
        />
        <LbInput {...generalInputProps} label="Facebook" name="additional.facebook" />
        <LbInput {...generalInputProps} label="Instagram" name="additional.instagram" />
        {!paymentFlowType.NEW_USER_FLOW_2024 && !isCoach && (
          <>
            <LbInput
              {...generalInputProps}
              label="Commission setup (%)"
              name="additional.commissionSetUp"
              type="number"
              rules={{
                validate: { validatePercent },
              }}
            />
            <LbInput
              {...generalInputProps}
              label="Commission renew (%)"
              name="additional.commissionRenew"
              type="number"
              rules={{
                validate: { validatePercent },
              }}
            />
          </>
        )}
      </InputBox>
    );
  },
);

export { CoachInfoFormSection };
