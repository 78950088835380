import React from 'react';

import { useFormContext } from 'react-hook-form';

import {
  ModalInputBox,
  ModalInputGroup,
} from '../../../../../../../shared/components/modals';
import { LbAutocomplete } from '../../../../../../../shared/components/form-components';
import { useScanDataRangeOptions } from '../../../../../../hooks/useScanDataRangeOptions/useScanDataRangeOptions';
import { SocialSharingScanMetricsParams } from '../../../../../../interfaces/socialSharingScanMetrics';

type DateRangeSectionProps = {
  scanDateTimes: string[];
};

const DateRangeSection = ({ scanDateTimes }: DateRangeSectionProps) => {
  const { control, watch } = useFormContext<SocialSharingScanMetricsParams>();
  const [startDate, endDate] = watch(['startDate', 'endDate']);

  const { startScanDateOptions, endScanDateOptions, getOptionLabel } =
    useScanDataRangeOptions({
      scanDateTimes,
      startScanDate: startDate,
      endScanDate: endDate,
    });

  return (
    <ModalInputBox
      title="Report Date"
      description="You can customize reports to include comparisons of values across different date ranges."
    >
      <ModalInputGroup>
        <LbAutocomplete
          control={control}
          variant="outlined"
          size="small"
          name="startDate"
          label="Start Scan Date"
          options={startScanDateOptions}
          getOptionLabel={getOptionLabel}
          rules={{ required: 'Start Scan Date is required' }}
        />
        <LbAutocomplete
          control={control}
          variant="outlined"
          size="small"
          name="endDate"
          label="End Scan Date"
          options={endScanDateOptions}
          getOptionLabel={getOptionLabel}
          rules={{ required: 'End Scan Date is required' }}
        />
      </ModalInputGroup>
    </ModalInputBox>
  );
};

export { DateRangeSection };
