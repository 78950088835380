import React from 'react';

import { Control } from 'react-hook-form';

import {
  InputBox,
  LbCreatableAutocomplete,
  LbDatePicker,
  LbInput,
  LbSelect,
} from '../../../../../../../shared/components/form-components';
import {
  validateEmail,
  validatePhone,
  validateAge,
} from '../../../../../../../shared/utils/reactHookFormUtils';
import { genderOptions } from '../../../../../../../shared/constants/user-info/gender';
import { useGroupList } from '../../../../../../../customer-group';
import { UserProfileFromValue } from '../../NgzUserProfileTabFlow2024.settings';
import { loggedInUserDataStore } from '../../../../../../../shared/stores/loggedInUserDataStore';

type PersonalDataFormProps = {
  control: Control<UserProfileFromValue>;
};

const PersonalDataForm = ({ control }: PersonalDataFormProps) => {
  const generalInputProps = {
    control,
    size: 'small',
    variant: 'standard',
  } as const;

  const privateInfoProps = { InputProps: { className: 'private-info' } };

  const { isSuperAdmin } = loggedInUserDataStore;

  const { groupList } = useGroupList({ onlyActive: true });

  return (
    <InputBox title="Personal Data">
      <LbInput
        {...generalInputProps}
        {...privateInfoProps}
        name="user.firstName"
        label="First Name"
        rules={{ required: 'First Name is required' }}
      />
      <LbInput
        {...generalInputProps}
        {...privateInfoProps}
        name="user.lastName"
        label="Last Name"
        rules={{ required: 'Last Name is required' }}
      />
      <LbInput
        {...generalInputProps}
        {...privateInfoProps}
        name="user.email"
        label="Email address"
        type="email"
        rules={{ required: 'Email is required', validate: { validateEmail } }}
        {...privateInfoProps}
      />
      <LbInput
        {...generalInputProps}
        {...privateInfoProps}
        name="customer.cellPhone"
        label="Cell Phone"
        type="tel"
        rules={{ required: 'Cell Phone is required', validate: { validatePhone } }}
      />
      <LbDatePicker
        {...generalInputProps}
        {...privateInfoProps}
        name="customer.dob"
        label="Date of Birth"
        helperText="You need to be at least 14 years to join the LifeBase"
        openTo="year"
        rules={{
          required: 'Date of Birth is required',
          validate: { validateMinAge: (value) => validateAge(value, 14) },
        }}
        {...privateInfoProps}
      />
      <LbSelect
        {...generalInputProps}
        {...privateInfoProps}
        name="customer.gender"
        label="Gender"
        options={genderOptions}
        rules={{ required: 'Gender is required' }}
      />
      {/* We should disable ability to change user group by super admin as it was before on angular flow */}
      {!isSuperAdmin && (
        <LbCreatableAutocomplete
          {...generalInputProps}
          {...privateInfoProps}
          name="customerGroup"
          label="User Group"
          options={groupList}
          labelKey="name"
          valueKey="id"
          prepareAddedOption={(label) => ({
            id: 0, // This is a fake id, need to know that this is a new group
            name: label,
            active: true,
            amountOfCustomers: 0,
          })}
        />
      )}
    </InputBox>
  );
};

export { PersonalDataForm };
