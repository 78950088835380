import { makeAutoObservable } from 'mobx';

import { resetAllStore } from '../../../shared/stores/resetAllStore';
import { Coach } from '../../interfaces/coachList/CoachListInfo';
import {
  CoachListFilterFormValues,
  CoachListFilters,
} from '../../interfaces/coachList/CoachListFilters';
import { FilterStore } from '../../../shared/util-stores';
import { coachService } from '../../services/coachService';
import { paginationSmallListLimit } from '../../../shared/constants/systemConstants';
import { ConsultationType } from '../../../shared/constants/coach/consultationType';

const defaultFilterData: CoachListFilterFormValues = {
  coachName: '',
  entityName: '',
  consultationType: '',
  paymentFlowType: '',
  entityId: '',
  zipCode: '',
  maxDistance: '',
};

class CoachStore {
  isKnownCoach: boolean | null = null;
  selectedCoach: Coach | null = null;
  // find way to update default filter set entityid data
  coachList: Coach[] = [];
  consultationType: ConsultationType | null = null;
  filterMethods: FilterStore<CoachListFilterFormValues>;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
    this.filterMethods = new FilterStore<CoachListFilterFormValues>(
      this.handleGetCoachList,
      {
        elementsPerPage: paginationSmallListLimit,
        defaultFilterData,
      },
    );
  }

  setDefaultValues() {
    this.isKnownCoach = null;
    this.selectedCoach = null;
    this.coachList = [];
    this.consultationType = null;
    this.filterMethods.setDefaultValues();
  }
  // zip code is sets in collaboration type row, need only when user do not know coach and select offline collaboration type
  private async handleGetCoachList(filterData: CoachListFilters) {
    const data = await coachService.getCoachList(filterData);

    this.setCoachList(data.content);
    this.setConsultationType(filterData.filters?.consultationType || null);
    this.setSelectedCoach(null);

    return data;
  }

  async getCoachList() {
    return this.filterMethods.getData();
  }

  private setCoachList(coachList: Coach[]) {
    this.coachList = coachList;
  }

  setIsKnownCoach(isKnownCoach: boolean) {
    this.isKnownCoach = isKnownCoach;
  }

  resetSelectCoachList() {
    this.filterMethods.resetFilterData(defaultFilterData);
    this.filterMethods.setDefaultValues();
    this.setSelectedCoach(null);
    this.setConsultationType(null);
    this.setCoachList([]);
  }

  setSelectedCoach(selectedCoach: Coach | null) {
    this.selectedCoach = selectedCoach;
  }

  private setConsultationType(consultationType: ConsultationType | null) {
    this.consultationType = consultationType;
  }
}

const coachStore = new CoachStore();
resetAllStore.addResetMethod(coachStore.setDefaultValues);

export { coachStore, CoachStore };
