import React, { useMemo } from 'react';

import classes from './InbodyIndicatorsTableView.module.scss';
import {
  GridTableCell,
  GridTableRow,
} from '../../../../../../../shared/components/table-components';
import { getFormattedDate } from '../../../../../../../shared/utils/dateUtils';
import {
  dateTimeFormat,
  localDataFormat,
} from '../../../../../../../shared/constants/apiDateFormat';
import { TitleWithDescription } from '../../../../../../../shared/components/text-formation-components';
import { useSort } from '../../../../../../../shared/hooks/useSort';
import { SortButtons } from '../../../../../../../shared/components/buttons';
import { InbodyIndicatorInfo } from '../../../../../../interfaces/inbodyIndicator';
import { InbodyIndicatorsKey } from '../../../../../../constants/inbodyIndecatorsKey';
import { getRoundedNumberOrDash } from '../../../../../../../shared/utils/mathUtils/getRoundedNumberOrDash/getRoundedNumberOrDash';
import { LbHorizontalScrollContainer } from '../../../../../../../shared/components/LbHorizontalScrollContainer/LbHorizontalScrollContainer';

type InbodyIndicatorsTableViewProps = {
  customerInbodyIndicators: InbodyIndicatorInfo[];
  selectedScanTime: string;
};

type IndicatorColumnConfig = {
  key: InbodyIndicatorsKey;
  shortLabel: string;
  measurement: string | '';
};

type CheckInHistoryRow = {
  scanDateTime: string;
  indicatorValues: {
    [key in InbodyIndicatorsKey]?: number | null;
  };
};
// TODO: refactor? create separate utils and test
const InbodyIndicatorsTableView = ({
  customerInbodyIndicators,
  selectedScanTime,
}: InbodyIndicatorsTableViewProps) => {
  const indicatorColumns: IndicatorColumnConfig[] = useMemo(() => {
    return customerInbodyIndicators.map(({ key, shortLabel, measurement }) => ({
      key,
      shortLabel,
      measurement,
    }));
  }, [customerInbodyIndicators]);

  // separate to two utils
  const checkInHistoryRows: CheckInHistoryRow[] = useMemo(() => {
    const scanDates = new Set<string>();

    if (selectedScanTime) {
      scanDates.add(selectedScanTime);
    } else {
      customerInbodyIndicators.forEach((indicator) => {
        indicator.scans.forEach((scan) => {
          scanDates.add(scan.scanDateTime);
        });
      });
    }

    return Array.from(scanDates).map((scanDate) => {
      const indicatorValues: Record<string, number | null> = {};

      customerInbodyIndicators.forEach((indicator) => {
        const scan = indicator.scans.find((s) => s.scanDateTime === scanDate);
        indicatorValues[indicator.key] = scan ? scan.value : null;
      });

      return {
        scanDateTime: scanDate,
        indicatorValues,
      };
    });
  }, [customerInbodyIndicators, selectedScanTime]);

  const { sortedData: sortedRows, sortMethods } = useSort({
    data: checkInHistoryRows,
    dateFormat: dateTimeFormat,
    sortTypeConfig: { scanDateTime: 'DATE' },
    defaultSortInfo: { fieldName: 'scanDateTime', direction: 'DESC' },
  });

  return (
    <LbHorizontalScrollContainer>
      <div
        className={classes.checkInHistoryTable}
        style={{
          display: 'grid',
          gridTemplateColumns: `120px repeat(${indicatorColumns.length}, minmax(max-content, 1fr))`,
        }}
      >
        <GridTableRow variant="header" className={classes.header}>
          <GridTableCell>
            <SortButtons
              fieldName="scanDateTime"
              onSort={sortMethods.handleSort}
              getIsActive={sortMethods.getIsSortedBy}
            >
              Date
            </SortButtons>
          </GridTableCell>
          {indicatorColumns.map((column) => (
            <GridTableCell key={column.key} className={classes.titleCell}>
              <TitleWithDescription
                title={column.shortLabel}
                description={column.measurement}
                titleFont="body-14-bold"
                withoutGap
              />
            </GridTableCell>
          ))}
        </GridTableRow>
        {sortedRows?.map((row) => (
          <GridTableRow key={row.scanDateTime} variant="row">
            <GridTableCell variant="title">
              {getFormattedDate(row.scanDateTime, localDataFormat, dateTimeFormat)}
            </GridTableCell>
            {indicatorColumns.map((column) => (
              <GridTableCell key={column.key}>
                {getRoundedNumberOrDash(row.indicatorValues[column.key])}
              </GridTableCell>
            ))}
          </GridTableRow>
        ))}
      </div>
    </LbHorizontalScrollContainer>
  );
};

export { InbodyIndicatorsTableView };
