const autoNgTemplateLoaderTemplate1 = require('./coach-edit.template.html');

'use strict';

(function () {
    angular
        .module('app.coachEdit', [])
        .component('coachEdit', {
            templateUrl: autoNgTemplateLoaderTemplate1,
            controller: 'CoachEditController',
            controllerAs: 'vm'
        })
        .config(coachEditConfig);

    coachEditConfig.$inject = ['$routeProvider'];

    function coachEditConfig($routeProvider) {
        $routeProvider
            .when('/coach-edit/:id', {
                disableCache: true,
                template: '<coach-edit></coach-edit>',
                resolve: {
                    loginRequired: ['AuthService', function (AuthService) {
                        return AuthService.loginRequired();
                    }]
                }
            })
    }
})();
