import { useEffect, useState } from 'react';

import { SocialSharingBgOption } from '../../../../../../interfaces/socialSharingScanMetrics';
import { socialSharingScanMetricsService } from '../../../../../../services/socialSharingScanMetricsService';

export const useBgOptions = () => {
  const [bgOptions, setBgOptions] = useState<SocialSharingBgOption[]>([]);

  const getBgOptions = async () => {
    const config = await socialSharingScanMetricsService.getTemplatesConfig();

    setBgOptions(config.bgOptions);
  };

  useEffect(() => {
    getBgOptions();
  }, []);

  return { bgOptions };
};
