import React from 'react';

import classes from './CompareMetricRow.module.scss';
import {
  GridTableCell,
  GridTableRow,
} from '../../../../../../../../shared/components/table-components';
import {
  getRoundedNumber,
  getRoundedNumberOrDash,
} from '../../../../../../../../shared/utils/mathUtils';

type CompareMetricRowProps = {
  metric: string;
  startValue: number | undefined;
  endValue: number | undefined;
};

const CompareMetricRow = ({ metric, startValue, endValue }: CompareMetricRowProps) => {
  return (
    <GridTableRow variant="unstyled" className={classes.row}>
      <GridTableCell variant="title">{metric}</GridTableCell>
      <GridTableCell>{getRoundedNumberOrDash(startValue)}</GridTableCell>
      <GridTableCell>{getRoundedNumberOrDash(endValue)}</GridTableCell>
      <GridTableCell>
        {startValue && endValue ? getRoundedNumber(endValue - startValue, 3) : '-'}
      </GridTableCell>
    </GridTableRow>
  );
};

export { CompareMetricRow };
