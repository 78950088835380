import React from 'react';

import { Control } from 'react-hook-form';

import classes from './AddressFormSection.module.scss';
import { CoachInfoFormValue } from '../../../../interfaces/CoachData';
import {
  InputBox,
  LbAutocomplete,
  LbInput,
} from '../../../../../shared/components/form-components';
import { TitleWithDescription } from '../../../../../shared/components/text-formation-components';
import { states } from '../../../../../shared/constants/states';
import { validateZipCodeFormat } from '../../../../../shared/utils/reactHookFormUtils';

type AddressFormSectionProps = {
  control: Control<CoachInfoFormValue>;
};

const AddressFormSection = ({ control }: AddressFormSectionProps) => {
  const privateInfoProps = { InputProps: { className: 'private-info' } };

  const generalInputProps = {
    control,
    size: 'small',
    variant: 'standard',
    ...privateInfoProps,
  } as const;

  return (
    <InputBox title="Address">
      <TitleWithDescription title="Address 1" />
      <div className={classes.addressInputsGroup}>
        <LbInput
          {...generalInputProps}
          label="Address"
          name="additional.address1"
          rules={{
            required: 'Address is required',
          }}
        />
        <LbInput
          {...generalInputProps}
          label="City"
          name="additional.city"
          rules={{
            required: 'City is required',
          }}
        />
        <LbAutocomplete
          {...generalInputProps}
          label="State"
          name="additional.state"
          options={states}
          rules={{ required: 'State is required' }}
        />
        <LbInput
          {...generalInputProps}
          name="additional.zip"
          label="Zip Code"
          rules={{
            required: 'Zip Code is required',
            validate: { validateZipCodeFormat },
          }}
        />
      </div>
      <TitleWithDescription title="Address 2" />
      <div className={classes.addressInputsGroup}>
        <LbInput {...generalInputProps} label="Address 2" name="additional.address2" />
      </div>
    </InputBox>
  );
};

export { AddressFormSection };
