import React from 'react';

import { useFormContext } from 'react-hook-form';
import { InputAdornment } from '@mui/material';

import classes from './AddScanManualForm.module.scss';
import {
  InputBox,
  LbDatePicker,
  LbInput,
  LbSelect,
  LbTimePicker,
} from '../../../shared/components/form-components';
import { CalculatedMetricInput } from './CalculatedMetricInput/CalculatedMetricInput';
import { EntryFieldConfig } from '../../interfaces/entityInbodyData';
import {
  AddScanManualFormValues,
  calculatedMetricConfigs,
  scanInputConfigs,
} from './AddScanManualForm.settings';

type AddScanManualFormProps = {
  entryFieldConfig: EntryFieldConfig;
};

const AddScanManualForm = ({ entryFieldConfig }: AddScanManualFormProps) => {
  const { control } = useFormContext<AddScanManualFormValues>();

  const generalInputProps = {
    variant: 'standard',
    size: 'small',
    control,
  } as const;

  return (
    <InputBox title="Manual Data" variant="innerBox">
      <LbDatePicker
        {...generalInputProps}
        name="scanData.scanDate"
        label="Check-in-date"
        rules={{ required: 'Check-in-date is required' }}
        disableFuture
      />
      <LbTimePicker
        {...generalInputProps}
        name="scanData.scanTime"
        label="Check-in-time"
        rules={{ required: 'Check-in-time is required' }}
      />

      {scanInputConfigs.map((inputConfig) => {
        const props = {
          ...generalInputProps,
          name: inputConfig.name,
          label: inputConfig.label,
          rules: inputConfig.rules,
        };

        if (inputConfig.configField && !entryFieldConfig[inputConfig.configField]) {
          return null;
        }

        switch (inputConfig.type) {
          case 'SELECT_INPUT':
            return (
              <LbSelect
                {...props}
                key={inputConfig.name}
                options={inputConfig.options}
                rules={{ required: 'Field is required' }}
              />
            );
          case 'NUMBER_INPUT':
            return (
              <LbInput
                {...props}
                key={inputConfig.name}
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" className={classes.measureInfo}>
                      {inputConfig.measureUnit ?? entryFieldConfig.measureUnit}
                    </InputAdornment>
                  ),
                }}
              />
            );
          default:
            return null;
        }
      })}
      <div className={classes.calculatedRow}>
        {calculatedMetricConfigs.map((inputConfig) => {
          if (inputConfig.configField && !entryFieldConfig[inputConfig.configField]) {
            return null;
          }

          return (
            <CalculatedMetricInput
              key={inputConfig.name}
              {...generalInputProps}
              name={inputConfig.name}
              label={inputConfig.label}
              metric={inputConfig.measureUnit}
              deps={inputConfig.deps}
              calcFunction={inputConfig.calcFunction}
            />
          );
        })}
      </div>
    </InputBox>
  );
};

export { AddScanManualForm };
