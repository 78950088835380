import React from 'react';
import { LbChip } from '../../../../../shared/components/ui-components';

type CustomerAccountInfoProps = {
  inbodyAccountId: number | null;
};

export const CustomerAccountInfo = ({ inbodyAccountId }: CustomerAccountInfoProps) => {
  return inbodyAccountId ? (
    <LbChip label="Account is created" color="success" size="medium" />
  ) : (
    <LbChip label="No Account" color="default" size="medium" />
  );
};
