import React from 'react';
import { angularize, getService } from 'react-in-angularjs';

import classes from './NgzPaymentResult.module.scss';
import { SystemProviders } from '../../../shared/providers';
import { ResultTitle } from './components/ResultTitle/ResultTitle';
import { ErrorInfo } from './ErrorInfo/ErrorInfo';
import { RegistrationPaymentInfo } from './RegistrationPaymentInfo/RegistrationPaymentInfo';
import { usePaymentResult } from './hooks/usePaymentResult';
import { ReactivationPaymentInfo } from './ReactivationPaymentInfo/ReactivationPaymentInfo';
import { InfoContainer } from './components/InfoContainer/InfoContainer';
import { generateFullParamsUrl } from './utils/generateUrlWithParams';

const NgzPaymentResult = () => {
  const { isSuccess, params, paymentInfo, paymentResultTypeFlags } = usePaymentResult();

  // We should take the params from the URL before the '#!' part and move them after it
  // Stripe can't handle the angular routing
  if (window.location.search.length) {
    const $routeParams = getService('$routeParams');

    window.location.replace(generateFullParamsUrl(window.location.search, $routeParams));

    return null;
  }

  const renderPaymentInfo = () => {
    if (!isSuccess) {
      return <ErrorInfo retryPath={params.retryPath} />;
    } else if (!paymentInfo) {
      return null;
    } else if (paymentResultTypeFlags.REGISTRATION) {
      return (
        <RegistrationPaymentInfo
          paymentInfo={paymentInfo}
          consultationType={params.consultationType}
        />
      );
    } else if (paymentResultTypeFlags.REACTIVATION) {
      return <ReactivationPaymentInfo paymentInfo={paymentInfo} />;
    }

    return (
      <InfoContainer>
        <ResultTitle
          isSuccessful={false}
          title="Something went wrong"
          description="Can't be processed"
        />
      </InfoContainer>
    );
  };

  return (
    <SystemProviders>
      <div className={classes.container}>{renderPaymentInfo()}</div>
    </SystemProviders>
  );
};

angularize(NgzPaymentResult, 'ngzPaymentResult', angular.module('app.payment'), {});
