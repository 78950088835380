import { makeAutoObservable } from 'mobx';

import { QuestionInfo } from '../../interfaces/QuestionnaireInfo';
import { questionnaireService } from '../../services/questionnaireService';
import { resetAllStore } from '../../../shared/stores/resetAllStore';
import {
  CUSTOMER_SPORT_TYPE,
  CustomerSportType,
} from '../../constants/customerSportType';
import { CUSTOMER_SPORT_TYPE_ANSWER_TYPE, QUESTION_TYPE } from '../../constants/question';

// use on customer-setup-flow-2024
class QuestionnaireStore {
  questions: QuestionInfo[] = [];
  userAnswers: Record<string, number> = {};
  customerSportType: CustomerSportType = CUSTOMER_SPORT_TYPE.AVERAGE_PERSON;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setDefaultValues() {
    this.questions = [];
    this.userAnswers = {};
  }

  async getQuestions(entityId?: number) {
    const data = await questionnaireService.getQuestions(entityId);

    this.setQuestions(data.questions);
  }

  private setQuestions(questions: QuestionInfo[]) {
    this.questions = questions;
  }

  private determinateCustomerSportType(): CustomerSportType {
    const customerSportTypeQuestion = this.questions.find(
      (question) => question.questionType === QUESTION_TYPE.CUSTOMER_SPORT_TYPE,
    );

    if (!customerSportTypeQuestion) {
      return CUSTOMER_SPORT_TYPE.AVERAGE_PERSON;
    }

    const selectedAnswer = customerSportTypeQuestion.answers.find(
      (answer) =>
        answer.answerId === this.userAnswers[customerSportTypeQuestion.questionId],
    );

    if (!selectedAnswer) {
      return CUSTOMER_SPORT_TYPE.AVERAGE_PERSON;
    }

    return (
      (Object.keys(CUSTOMER_SPORT_TYPE_ANSWER_TYPE) as Array<CustomerSportType>).find(
        (key) => CUSTOMER_SPORT_TYPE_ANSWER_TYPE[key] === selectedAnswer.answerOptionType,
      ) || CUSTOMER_SPORT_TYPE.AVERAGE_PERSON
    );
  }

  setUserAnswers(userAnswers: Record<string, number>) {
    this.userAnswers = userAnswers;

    this.customerSportType = this.determinateCustomerSportType();
  }
}

const questionnaireStore = new QuestionnaireStore();
resetAllStore.addResetMethod(questionnaireStore.setDefaultValues);

export { questionnaireStore, QuestionnaireStore };
