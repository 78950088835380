import { ValueOfKey } from '../../utils-types';

export const pathQueryParams = {
  retryPath: 'retryPath',
  redirectStatus: 'redirect_status',
  subscriptionType: 'subscriptionType',
  paymentTimeEpoch: 'paymentTimeEpoch',
  senderName: 'senderName',
  connectedAccountId: 'connectedAccountId',
  entityId: 'entityId',
  email: 'email',
  selectedSubscriptionId: 'selectedSubscriptionId',
  paymentResultType: 'paymentResultType',
  consultationType: 'consultationType',
} as const;

export type PathQueryParams = ValueOfKey<typeof pathQueryParams>;

export const checkoutQueryParams = {
  entityId: 'entityId',
  email: 'email',
  selectedSubscriptionId: 'selectedSubscriptionId',
  retryPath: 'retryPath',
  paymentResultType: 'paymentResultType',
  consultationType: 'consultationType',
} as const;

export type CheckoutQueryParams = ValueOfKey<typeof checkoutQueryParams>;

//  send "paymentTimeEpoch" and "senderName" like JSON in "paymentInfo" params if needed extend m
export const paymentResultQueryParams = {
  retryPath: 'retryPath',
  redirectStatus: 'redirect_status', // Stripe params
  paymentIntentId: 'payment_intent', // Stripe params
  subscriptionType: 'subscriptionType',
  paymentTimeEpoch: 'paymentTimeEpoch',
  senderName: 'senderName',
  connectedAccountId: 'connectedAccountId',
  paymentResultType: 'paymentResultType',
  consultationType: 'consultationType',
} as const;

export type PaymentResultQueryParams = ValueOfKey<typeof paymentResultQueryParams>;
export type PaymentResultQueryParamsKeys = keyof typeof paymentResultQueryParams;
