import { ValueOfKey } from '../../utils-types';

// used in new api for inbody data
export const INBODY_INDICATORS_KEY = {
  WEIGHT: 'WEIGHT',
  TBW: 'TBW',
  ICW: 'ICW',
  ECW: 'ECW',
  DLM: 'DLM',
  BFM: 'BFM',
  LBM: 'LBM',
  SMM: 'SMM',
  BMI: 'BMI',
  PBF: 'PBF',
  ECW_TBW: 'ECW_TBW',
  BMR: 'BMR',
  LBM_OF_RIGHT_ARM: 'LBM_OF_RIGHT_ARM',
  LBM_PERCENT_OF_RIGHT_ARM: 'LBM_PERCENT_OF_RIGHT_ARM',
  LBM_OF_LEFT_ARM: 'LBM_OF_LEFT_ARM',
  LBM_PERCENT_OF_LEFT_ARM: 'LBM_PERCENT_OF_LEFT_ARM',
  LBM_OF_TRUNK: 'LBM_OF_TRUNK',
  LBM_PERCENT_OF_TRUNK: 'LBM_PERCENT_OF_TRUNK',
  LBM_OF_RIGHT_LEG: 'LBM_OF_RIGHT_LEG',
  LBM_PERCENT_OF_RIGHT_LEG: 'LBM_PERCENT_OF_RIGHT_LEG',
  LBM_OF_LEFT_LEG: 'LBM_OF_LEFT_LEG',
  LBM_PERCENT_OF_LEFT_LEG: 'LBM_PERCENT_OF_LEFT_LEG',
  LEG_LEAN_MASS: 'LEG_LEAN_MASS',
  ECW_TBW_OF_RIGHT_ARM: 'ECW_TBW_OF_RIGHT_ARM',
  ECW_TBW_OF_LEFT_ARM: 'ECW_TBW_OF_LEFT_ARM',
  ECW_TBW_OF_TRUNK: 'ECW_TBW_OF_TRUNK',
  ECW_TBW_OF_RIGHT_LEG: 'ECW_TBW_OF_RIGHT_LEG',
  ECW_TBW_OF_LEFT_LEG: 'ECW_TBW_OF_LEFT_LEG',
  BFM_OF_RIGHT_ARM: 'BFM_OF_RIGHT_ARM',
  BFM_PERCENT_OF_RIGHT_ARM: 'BFM_PERCENT_OF_RIGHT_ARM',
  BFM_OF_LEFT_ARM: 'BFM_OF_LEFT_ARM',
  BFM_PERCENT_OF_LEFT_ARM: 'BFM_PERCENT_OF_LEFT_ARM',
  BFM_OF_TRUNK: 'BFM_OF_TRUNK',
  BFM_PERCENT_OF_TRUNK: 'BFM_PERCENT_OF_TRUNK',
  BFM_OF_RIGHT_LEG: 'BFM_OF_RIGHT_LEG',
  BFM_PERCENT_OF_RIGHT_LEG: 'BFM_PERCENT_OF_RIGHT_LEG',
  BFM_OF_LEFT_LEG: 'BFM_OF_LEFT_LEG',
  BFM_PERCENT_OF_LEFT_LEG: 'BFM_PERCENT_OF_LEFT_LEG',
  INBODY_SCORE: 'INBODY_SCORE',
  BFM_CONTROL: 'BFM_CONTROL',
  LBM_CONTROL: 'LBM_CONTROL',
  VFL: 'VFL',
  VFA: 'VFA',
  AC: 'AC',
  FIVE_KHZ_RA_IMPEDANCE: 'FIVE_KHZ_RA_IMPEDANCE',
  FIVE_KHZ_LA_IMPEDANCE: 'FIVE_KHZ_LA_IMPEDANCE',
  FIVE_KHZ_TR_IMPEDANCE: 'FIVE_KHZ_TR_IMPEDANCE',
  FIVE_KHZ_RL_IMPEDANCE: 'FIVE_KHZ_RL_IMPEDANCE',
  FIVE_KHZ_LL_IMPEDANCE: 'FIVE_KHZ_LL_IMPEDANCE',
  FIFTY_KHZ_RA_IMPEDANCE: 'FIFTY_KHZ_RA_IMPEDANCE',
  FIFTY_KHZ_LA_IMPEDANCE: 'FIFTY_KHZ_LA_IMPEDANCE',
  FIFTY_KHZ_TR_IMPEDANCE: 'FIFTY_KHZ_TR_IMPEDANCE',
  FIFTY_KHZ_RL_IMPEDANCE: 'FIFTY_KHZ_RL_IMPEDANCE',
  FIFTY_KHZ_LL_IMPEDANCE: 'FIFTY_KHZ_LL_IMPEDANCE',
  FIVE_HUNDRED_KHZ_RA_IMPEDANCE: 'FIVE_HUNDRED_KHZ_RA_IMPEDANCE',
  FIVE_HUNDRED_KHZ_LA_IMPEDANCE: 'FIVE_HUNDRED_KHZ_LA_IMPEDANCE',
  FIVE_HUNDRED_KHZ_TR_IMPEDANCE: 'FIVE_HUNDRED_KHZ_TR_IMPEDANCE',
  FIVE_HUNDRED_KHZ_RL_IMPEDANCE: 'FIVE_HUNDRED_KHZ_RL_IMPEDANCE',
  FIVE_HUNDRED_KHZ_LL_IMPEDANCE: 'FIVE_HUNDRED_KHZ_LL_IMPEDANCE',
  SMI: 'SMI',
  LOWER_LIMIT_WEIGHT: 'LOWER_LIMIT_WEIGHT',
  UPPER_LIMIT_WEIGHT: 'UPPER_LIMIT_WEIGHT',
  LOWER_LIMIT_TBW: 'LOWER_LIMIT_TBW',
  UPPER_LIMIT_TBW: 'UPPER_LIMIT_TBW',
  LOWER_LIMIT_ICW: 'LOWER_LIMIT_ICW',
  UPPER_LIMIT_ICW: 'UPPER_LIMIT_ICW',
  LOWER_LIMIT_ECW: 'LOWER_LIMIT_ECW',
  UPPER_LIMIT_ECW: 'UPPER_LIMIT_ECW',
  LOWER_LIMIT_BFM: 'LOWER_LIMIT_BFM',
  UPPER_LIMIT_BFM: 'UPPER_LIMIT_BFM',
  LOWER_LIMIT_LBM: 'LOWER_LIMIT_LBM',
  UPPER_LIMIT_LBM: 'UPPER_LIMIT_LBM',
  LOWER_LIMIT_SMM: 'LOWER_LIMIT_SMM',
  UPPER_LIMIT_SMM: 'UPPER_LIMIT_SMM',
  LOWER_LIMIT_BMI: 'LOWER_LIMIT_BMI',
  UPPER_LIMIT_BMI: 'UPPER_LIMIT_BMI',
  LOWER_LIMIT_PBF: 'LOWER_LIMIT_PBF',
  UPPER_LIMIT_PBF: 'UPPER_LIMIT_PBF',
  LOWER_LIMIT_LBM_OF_RIGHT_ARM: 'LOWER_LIMIT_LBM_OF_RIGHT_ARM',
  UPPER_LIMIT_LBM_OF_RIGHT_ARM: 'UPPER_LIMIT_LBM_OF_RIGHT_ARM',
  LOWER_LIMIT_LBM_OF_LEFT_ARM: 'LOWER_LIMIT_LBM_OF_LEFT_ARM',
  UPPER_LIMIT_LBM_OF_LEFT_ARM: 'UPPER_LIMIT_LBM_OF_LEFT_ARM',
  LOWER_LIMIT_LBM_OF_TRUNK: 'LOWER_LIMIT_LBM_OF_TRUNK',
  UPPER_LIMIT_LBM_OF_TRUNK: 'UPPER_LIMIT_LBM_OF_TRUNK',
  LOWER_LIMIT_LBM_OF_RIGHT_LEG: 'LOWER_LIMIT_LBM_OF_RIGHT_LEG',
  UPPER_LIMIT_LBM_OF_RIGHT_LEG: 'UPPER_LIMIT_LBM_OF_RIGHT_LEG',
  LOWER_LIMIT_LBM_OF_LEFT_LEG: 'LOWER_LIMIT_LBM_OF_LEFT_LEG',
  UPPER_LIMIT_LBM_OF_LEFT_LEG: 'UPPER_LIMIT_LBM_OF_LEFT_LEG',
  RECOMMENDED_CALORIE_INTAKE: 'RECOMMENDED_CALORIE_INTAKE',
  LOWER_LIMIT_BMR: 'LOWER_LIMIT_BMR',
  UPPER_LIMIT_BMR: 'UPPER_LIMIT_BMR',
} as const;

export type InbodyIndicatorsKey = ValueOfKey<typeof INBODY_INDICATORS_KEY>;
