'use strict';

angular.module('app').factory('CoachService', CoachService);

CoachService.$inject = ['ApiService', 'ModalService'];

function CoachService(ApiService, ModalService) {
    return {
        getAllCoaches: function (prms) {
            return ApiService.get('api/admin/coachlist/filters', prms).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        updateUserPassword: function (id, data, prms) {
            return ApiService.put('api/general/set-password/' + id, data, prms).then(function (res) {
                ModalService.savedChanges();
                return res;
            }, function (err) {
                return err;
            });
        },
        getCoachForEntity: function (id, prms) {
            return ApiService.get('api/admin/coach/' + id, prms).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        updateCoachByEntity: function (id, prms) {
            return ApiService.put('api/admin/coach/' + id, prms).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        createCoach: function (data) {
            return ApiService.post('api/admin/coach', data).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        getCurrentEntityInfo: function (customerId, customerEmail) {
            return ApiService.get('api/coach/entity', { customerId, email: customerEmail }).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        getCoachAccountSettings: function (id) {
            return ApiService.get('api/coach/account/' + id).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        updateCoachAccountSettings: function (id, data) {
            return ApiService.put('api/coach/account/' + id, data).then(function (res) {
                localStorage.setItem('loggedInUser', JSON.stringify(res.data));
                return res;
            }, function (err) {
                return err;
            });
        },
        updateCoachPassword: function (coachId, data) {
            return ApiService.put('api/admin/coach/password/' + coachId, data).then(function (res) {
                ModalService.savedChanges();
                return res;
            }, function (err) {
                return err;
            });
        },
        getComplianceDetailForSpecificDayForSpecificMeal: function (mealDayId, meal) {
            return ApiService.get('api/coach/compliance-details/' + mealDayId + (meal ? '/' + meal : '')).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        checkUserEmail: function (email) {
            return ApiService.get('api/coach/user/' + email).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        createFreeTrial: function (id) {
            return ApiService.post('api/coach/user/' + id + '/free-trial').then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        getUpdateHistory: () => ApiService.get('api/coach/update-history'),
    };
}

