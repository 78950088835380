import React from 'react';

import { angularize } from 'react-in-angularjs';
import { enqueueSnackbar } from 'notistack';
import { observer } from 'mobx-react';

import { EntityInbodyDataConfig } from '../../../../containers/EntityInbodyDataConfig/EntityInbodyDataConfig';
import { SystemProviders } from '../../../../../shared/providers';
import { YesNoBtnRow } from '../../../../../shared/components/buttons';
import { loggedInUserDataStore } from '../../../../../shared/stores/loggedInUserDataStore';

type NgzEntityInbodyInfoTabProps = {
  entityId: number;
};

const NgzEntityInbodyInfoTab = observer(({ entityId }: NgzEntityInbodyInfoTabProps) => {
  const { isSuperAdmin } = loggedInUserDataStore;

  const formId = 'entityInbodyDataConfigForm';

  const handleSuccessSubmit = () => {
    enqueueSnackbar('InBody Info has been successfully updated', { variant: 'success' });
  };

  return (
    <SystemProviders>
      <EntityInbodyDataConfig
        entityId={entityId}
        formId={formId}
        onSuccessSubmit={handleSuccessSubmit}
      />
      {isSuperAdmin && <YesNoBtnRow yesText="Save" formId={formId} />}
    </SystemProviders>
  );
});

angularize(NgzEntityInbodyInfoTab, 'ngzEntityInbodyInfoTab', angular.module('app'), {
  entityId: '<',
});

export { NgzEntityInbodyInfoTab };
