import React from 'react';

import {
  useController,
  UseControllerProps,
  FieldValues,
  Path,
  PathValue,
} from 'react-hook-form';
import { TextField, TextFieldProps } from '@mui/material';

export type TextAreaProps<
  T extends FieldValues,
  TName extends Path<T>,
> = UseControllerProps<T, TName> &
  Pick<
    TextFieldProps,
    | 'label'
    | 'size'
    | 'helperText'
    | 'className'
    | 'placeholder'
    | 'disabled'
    | 'rows'
    | 'maxRows'
    | 'margin'
  >;

const TextArea = <T extends FieldValues, TName extends Path<T>>({
  control,
  name,
  label,
  rules,
  defaultValue = '' as PathValue<T, TName>,
  shouldUnregister,
  size,
  helperText,
  className,
  placeholder,
  disabled,
  margin,
  rows,
  maxRows,
}: TextAreaProps<T, TName>) => {
  const {
    field: { ref, ...field },
    fieldState: { error },
  } = useController<any>({
    control,
    name,
    rules,
    defaultValue,
    shouldUnregister,
  });

  return (
    <TextField
      {...field}
      inputRef={ref}
      label={label}
      helperText={error?.message || helperText}
      error={!!error}
      variant="outlined"
      fullWidth
      multiline
      placeholder={placeholder}
      required={!!rules?.required}
      size={size}
      className={className}
      disabled={disabled}
      rows={rows}
      maxRows={maxRows}
      margin={margin}
    />
  );
};

export { TextArea };
