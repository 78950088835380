import React from 'react';

import {
  Chart as ChartJS,
  LineController,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import classes from './InbodyIndicatorsChartView.module.scss';
import { getFormattedDate, isSameDay } from '../../../../../../../shared/utils/dateUtils';
import {
  dateTimeFormat,
  localDataFormat,
} from '../../../../../../../shared/constants/apiDateFormat';
import { palette } from '../../../../../../../shared/theme/palette';
import { getDataLabelPosition } from './utils/getDataLabelPosition';
import { getShowScansData } from './utils/getShowScansData';
import { InfoBox } from '../../../../../../../shared/components/ui-components';
import { InbodyIndicatorInfo } from '../../../../../../interfaces/inbodyIndicator';
import { getHumanizeInbodyType } from '../../../../../../../shared/utils/inbodyUtils';
import { getRoundedNumber } from '../../../../../../../shared/utils/mathUtils';
import { INBODY_INDICATORS_KEY } from '../../../../../../constants/inbodyIndecatorsKey';
import { MealPlanOnScanDayInfo } from '../../../../../../interfaces/mealPlanOnScanDayInfo';

ChartJS.register(LineController, LineElement, PointElement, CategoryScale, LinearScale);

type InbodyIndicatorsChartViewProps = {
  customerInbodyIndicators: InbodyIndicatorInfo[];
  mealPlansOnScanDay: MealPlanOnScanDayInfo[];
  selectedScanTime: string | null;
};

// TODO: refactor, omit map
const InbodyIndicatorsChartView = ({
  customerInbodyIndicators,
  mealPlansOnScanDay,
  selectedScanTime,
}: InbodyIndicatorsChartViewProps) => {
  return customerInbodyIndicators.map(({ label, measurement, key, scans }) => {
    const showScans = getShowScansData(scans, selectedScanTime);

    const labels = showScans.map(({ scanDateTime, inbodyType }) => {
      const scanLabel = [
        getFormattedDate(scanDateTime, localDataFormat, dateTimeFormat),
        getHumanizeInbodyType(inbodyType),
      ];

      if (key === INBODY_INDICATORS_KEY.WEIGHT) {
        const mealPlan = mealPlansOnScanDay.find(({ date }) =>
          isSameDay(date, scanDateTime),
        );

        if (mealPlan) {
          scanLabel.push(
            '',
            `prot. ${mealPlan.proteins}`,
            `carbs ${mealPlan.carbs}`,
            `fats ${mealPlan.fats}`,
          );
        }
      }

      return scanLabel;
    });

    const pointRadius = showScans.map(({ scanDateTime }) => {
      return scanDateTime === selectedScanTime ? 6 : 3;
    });

    const values = showScans.map(({ value }) => {
      if (key === INBODY_INDICATORS_KEY.ECW_TBW) {
        return value;
      }

      return getRoundedNumber(value, 1);
    });

    const chartHeight = key === INBODY_INDICATORS_KEY.WEIGHT ? 300 : 242;

    const chartTitle = measurement ? `${label} (${measurement})` : label;

    return (
      <InfoBox title={chartTitle} key={key} customContentPadding>
        <div className={classes.chartContainer} style={{ height: chartHeight }}>
          <Line
            data={{ labels, datasets: [{ data: values, pointRadius }] }}
            plugins={[ChartDataLabels]}
            options={{
              events: [],
              animation: false,
              maintainAspectRatio: false,
              elements: {
                line: {
                  borderColor: palette.mainBlack,
                  borderWidth: 2,
                },
                point: {
                  backgroundColor: palette.mainBlack,
                },
              },
              plugins: {
                legend: { display: false },
                tooltip: { enabled: false },
                datalabels: {
                  color: palette.mainBlack,
                  font: { weight: 700 },
                  clamp: true,
                  offset: 10,
                  align: (context) => getDataLabelPosition(context, values),
                },
              },
              scales: {
                x: { offset: true, border: { dash: [3, 3] } },
                y: {
                  offset: true,
                  display: false,
                },
              },
            }}
          />
        </div>
      </InfoBox>
    );
  });
};

export { InbodyIndicatorsChartView };
