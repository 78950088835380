const autoNgTemplateLoaderTemplate1 = require('./coach-profile.template.html');

'use strict';

(function () {
    angular
        .module('app.coachProfile', [])
        .component('coachProfile', {
            templateUrl: autoNgTemplateLoaderTemplate1,
            controller: 'CoachProfileController',
            controllerAs: 'vm',
        })
        .config(coachProfileConfig);

    coachProfileConfig.$inject = ['$routeProvider'];

    function coachProfileConfig($routeProvider) {
        $routeProvider
            .when('/coach-profile', {
                disableCache: true,
                template: '<coach-profile></coach-profile>',
                resolve: {
                    loginRequired: ['AuthService', function (AuthService) {
                        return AuthService.loginRequired();
                    }]
                }
            })
            .when('/coach-profile/notes', {
                disableCache: true,
                template: '<coach-profile></coach-profile>',    
                resolve: {
                    loginRequired: ['AuthService', function (AuthService) {
                        return AuthService.loginRequired();
                    }]
                }
            })
    }
})();
