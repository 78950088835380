import { useEffect, useMemo, useState } from 'react';

import { SchoolInfo } from '../../interfaces/school/SchoolInfo';
import { loggedInUserDataStore } from '../../stores/loggedInUserDataStore';
import { Option } from '../../../general-types';
import { schoolsService } from '../../services/schools/schoolsService';
import { addAllOption, transformToOptions } from '../../utils/arrayUtils';

type UseSchoolListReturn = {
  schoolList: SchoolInfo[];
  schoolListOption: Option[];
  schoolListOptionWithAll: Option[];
};

type UseSchoolsListProps = {
  isScholastic: boolean;
  onlyActive?: boolean;
  isRunGlobalSpinner?: boolean;
};

export const useSchoolsList = ({
  isScholastic,
  onlyActive = false,
  isRunGlobalSpinner,
}: UseSchoolsListProps): UseSchoolListReturn => {
  const [schoolList, setSchoolList] = useState<SchoolInfo[]>([]);
  const { getEntityId, isSuperAdmin } = loggedInUserDataStore;

  const initSchoolList = async () => {
    const { customerSchoolGroups } = await getSchoolsList();

    if (onlyActive) {
      const activeSchools = customerSchoolGroups.filter((school) => school.active);
      setSchoolList(activeSchools);
    } else {
      setSchoolList(customerSchoolGroups);
    }
  };

  const getSchoolsList = async () => {
    if (isSuperAdmin) {
      return schoolsService.getSchoolsForAllEntities(isScholastic, isRunGlobalSpinner);
    }

    const entityId = getEntityId();
    return schoolsService.getSchoolsList(entityId, isRunGlobalSpinner);
  };

  useEffect(() => {
    initSchoolList();
  }, []);

  const schoolListOption = useMemo(
    () => transformToOptions({ list: schoolList, labelKey: 'name', valueKey: 'id' }),
    [schoolList],
  );

  const schoolListOptionWithAll = useMemo(
    () => addAllOption(schoolListOption),
    [schoolListOption],
  );

  return {
    schoolList,
    schoolListOption,
    schoolListOptionWithAll,
  };
};
