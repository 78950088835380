import moment from 'moment';
import {
  dateTimeFormat,
  localDateTimeFormat,
} from '../../../../../../../shared/constants/apiDateFormat';
import { addAllOption } from '../../../../../../../shared/utils/arrayUtils';
import { getFormattedDate } from '../../../../../../../shared/utils/dateUtils';
import { InbodyIndicatorInfo } from '../../../../../../interfaces/inbodyIndicator';

export const getScanDateOptions = (customerInbodyIndicators: InbodyIndicatorInfo[]) => {
  // replace get scanDates to utils, check update similar utils
  const scanDates = new Set<string>();

  customerInbodyIndicators.forEach((indicator) => {
    indicator.scans.forEach((scan) => {
      scanDates.add(scan.scanDateTime);
    });
  });

  // sort from new to old
  const sortedScanDates = Array.from(scanDates).sort((a, b) =>
    moment(a, dateTimeFormat) < moment(b, dateTimeFormat) ? 1 : -1,
  );

  const scanDateOptions = sortedScanDates.map((date) => ({
    label: getFormattedDate(date, localDateTimeFormat, dateTimeFormat),
    value: date,
  }));

  return addAllOption(scanDateOptions, true);
};
