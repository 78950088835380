import React, { useEffect } from 'react';

import { observer } from 'mobx-react';

import { UseStepperReturn } from '../../../../../shared/hooks/useStepper';
import { questionnaireStore } from '../../../../stores/customerSelfSetupStores/questionnaireStore';
import { useScrollToTop } from '../../../../../shared/hooks/useScrollToTop';
import {
  QuestionnaireForm,
  ButtonRow,
} from '../../../../components/customer-self-setup-components';

type QuestionnaireStepProps = {
  stepperMethods: UseStepperReturn;
};

const questionnaireFormId = 'questionnaireForm';

const QuestionnaireStep = observer(({ stepperMethods }: QuestionnaireStepProps) => {
  useScrollToTop();

  const { questions, userAnswers } = questionnaireStore;

  useEffect(() => {
    if (!questions.length) {
      questionnaireStore.getQuestions();
    }
  }, []);

  const handleFormSubmit = (data: Record<string, number>) => {
    questionnaireStore.setUserAnswers(data);
    stepperMethods.goToNextStep();
  };

  return (
    <div>
      <QuestionnaireForm
        formConfig={{ defaultValues: userAnswers }}
        questions={questions}
        onSubmit={handleFormSubmit}
        formId={questionnaireFormId}
      />
      <ButtonRow formId={questionnaireFormId} />
    </div>
  );
});

export { QuestionnaireStep };
