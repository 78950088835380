import React from 'react';

import { Paper, Radio } from '@mui/material';
import classNames from 'classnames';

import classes from './RemoteCoachRow.module.scss';
import { AvatarIcon } from '../../../../../../../shared/components/ui-components';
import { Coach } from '../../../../../../interfaces/coachList/CoachListInfo';
import { TitleWithDescription } from '../../../../../../../shared/components/text-formation-components';
import { getCoachDescription } from './utils/getCoachDescription';

type RemoteCoachRowProps = {
  coach: Coach;
  selectedCoachId?: number | null;
  onClick: (coach: Coach) => void;
};

const RemoteCoachRow = ({ coach, onClick, selectedCoachId }: RemoteCoachRowProps) => {
  const checked = selectedCoachId === coach.coachId;

  const rowClasses = classNames(classes.row, {
    [classes.checkedRow]: checked,
  });

  const handleClick = () => {
    onClick(coach);
  };

  return (
    <Paper
      variant="roundedBox"
      className={rowClasses}
      onClick={handleClick}
      data-test-id="coach-row"
    >
      <Radio checked={checked} />
      <AvatarIcon userName={coach.coachName} avatarImgLink={coach.coachAvatar} />
      <TitleWithDescription
        title={coach.coachName}
        description={getCoachDescription(coach)}
        titleFont="body-14-bold"
        descriptionColor="main-black"
      />
      {coach.bio && <p className={classes.bio}>&quot;{coach.bio}&quot;</p>}
    </Paper>
  );
};

export { RemoteCoachRow };
