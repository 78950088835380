import { CombinedCustomerInfo } from '../interfaces/Customer';
import { apiService } from '../../shared/services/apiService';

export const customerService = {
  async createCustomer(customerInfo: CombinedCustomerInfo) {
    const resp = await apiService.post<CombinedCustomerInfo>(
      'api/coach/customer',
      customerInfo,
    );

    return resp.data;
  },

  async updateCustomer(customerId: number, customerInfo: CombinedCustomerInfo) {
    const resp = await apiService.put<CombinedCustomerInfo>(
      `api/coach/customer/${customerId}`,
      customerInfo,
    );

    return resp.data;
  },

  // TODO replace to auth module
  async sendSetPasswordLink(customerId: number) {
    const resp = await apiService.post<undefined>(
      `/api/user/${customerId}/set-password-link`,
    );

    return resp.data;
  },

  async getCustomerInfo(customerId: number): Promise<CombinedCustomerInfo> {
    const resp = await apiService.get<CombinedCustomerInfo>(
      `/api/coach/customer/${customerId}`,
    );

    return resp.data;
  },

  // TODO: should be refactored in task https://lifebasellc.atlassian.net/browse/LMP-3650
  async getCustomerProfileFlow2024(customerId: number) {
    const resp = await apiService.get<CombinedCustomerInfo>(
      `/api/customer/${customerId}`,
    );

    return resp.data;
  },

  async updateCustomerProfileFlow2024(
    customerId: number,
    customerInfo: CombinedCustomerInfo,
  ) {
    const resp = await apiService.put<CombinedCustomerInfo>(
      `/api/customer/${customerId}`,
      customerInfo,
    );

    return resp.data;
  },
};
