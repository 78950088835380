import { getIsNullish } from '../../jsUtils';

export const validatePercent = (value: number | undefined | null) => {
  if (getIsNullish(value)) {
    return true;
  }

  if (value < 0 || value > 100) {
    return 'Must be between 0 and 100';
  }

  return true;
};
