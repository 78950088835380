import React from 'react';

import { useFormContext } from 'react-hook-form';
import { Button } from '@mui/material';

import { CustomFilterFormValues } from '../../AddCustomFilterForm.settings';
import { useClearFilter } from '../../hooks/useClearFilter';
import { ModalInputBox, ModalInputGroup } from '../../../../../../../components/modals';
import { SelectSportPickers } from '../../../../../../../components/form-components';
import { useSports } from './hooks/useSports';

const SportFilters = () => {
  const { control, register } = useFormContext<CustomFilterFormValues>();

  const { sports } = useSports();

  const { disabled, handleClearFields } = useClearFilter('SCHOOL', {
    position: '',
    sport: null,
  });

  return (
    <ModalInputBox
      title="Sport"
      adornment={
        <Button
          size="small"
          variant="text"
          color="secondary"
          onClick={handleClearFields}
          disabled={disabled}
        >
          Clear
        </Button>
      }
    >
      <input {...register('filters.SCHOOL.filterType', { value: 'SCHOOL' })} hidden />
      <ModalInputGroup>
        <SelectSportPickers
          control={control}
          sports={sports}
          variant="outlined"
          sportFieldProps={{
            name: 'filters.SCHOOL.sport',
          }}
          sportPositionFieldProps={{
            name: 'filters.SCHOOL.position',
          }}
        />
      </ModalInputGroup>
    </ModalInputBox>
  );
};

export { SportFilters };
