import React from 'react';

import { Divider } from '@mui/material';

import classes from './RegistrationPaymentInfo.module.scss';
import { ResultTitle } from '../components/ResultTitle/ResultTitle';
import { PaymentDetails } from '../components/PaymentDetails/PaymentDetails';
import { WELCOME_VIDEO_TITLE, WELCOME_VIDEO_URL } from '../../../constants/welcomeVIdeo';
import { VideoBlock } from '../components/VideoBlock/VideoBlock';
import { H20bScaleBlock } from '../components/H20bScaleBlock/H20bScaleBlock';
import { PaymentInfo } from '../../../interfaces/checkoutInfo';
import { ConsultationType } from '../../../../shared/constants/coach/consultationType';

type RegistrationPaymentInfoProps = {
  paymentInfo: PaymentInfo;
  consultationType: ConsultationType;
};

const RegistrationPaymentInfo = ({
  paymentInfo,
  consultationType,
}: RegistrationPaymentInfoProps) => {
  return (
    <>
      <div className={classes.container}>
        <div className={classes.titleBlock}>
          <ResultTitle
            isSuccessful
            title="Thank you for your payment!"
            description="Access to the LifeBase app starts when your coach finalizes your setup and custom macro plan."
          />
          <PaymentDetails paymentInfo={paymentInfo} />
        </div>
        <Divider className={classes.divider} />
        <H20bScaleBlock
          className={classes.h20bScaleBlock}
          consultationType={consultationType}
        />
        <VideoBlock
          title="We're excited to help you reach your goals."
          description="Your consultation with your personal nutrition coach is next. Watch the video
        below to get started."
          videoName={WELCOME_VIDEO_TITLE}
          videoUrl={WELCOME_VIDEO_URL}
          className={classes.videoBlock}
        />
      </div>
      <div className={classes.whiteBackground} />
    </>
  );
};

export { RegistrationPaymentInfo };
