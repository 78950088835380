import { apiService } from '../../shared/services/apiService';
import { CoachBio, CoachData, CoachInfoFormValue } from '../interfaces/CoachData';

// check types for resp, check use one api for all
export const coachService = {
  /** @deprecated */
  async getCoachByEntity(coachId: number) {
    const resp = await apiService.get<CoachData>(`/api/admin/coach/${coachId}`);

    return resp.data;
  },
  /** @deprecated */
  async updateCoachByEntity(id: number, data: CoachInfoFormValue) {
    const resp = await apiService.put(`/api/admin/coach/${id}`, data);

    return resp.data;
  },

  async getCoachAccountSettings(coachId: number) {
    const resp = await apiService.get<CoachData>(`/api/coach/account/${coachId}`);

    return resp.data;
  },

  async updateCoachAccountSettings(coachId: number, data: CoachInfoFormValue) {
    const resp = await apiService.put<CoachData>(`/api/coach/account/${coachId}`, data);

    return resp.data;
  },

  async updateBioByCoach(coachId: number, data: { bio: string }) {
    const resp = await apiService.put<CoachBio>(`/api/coach/${coachId}/bio`, data);

    return resp.data;
  },

  async updateBioBySuperAdmin(coachId: number, data: CoachBio) {
    const resp = await apiService.put<CoachBio>(`/api/super/coach/${coachId}/bio`, data);

    return resp.data;
  },
};
