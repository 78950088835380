import React from 'react';

import { Button, Paper } from '@mui/material';
import { openConfirmationModal } from '../../../../shared/features/confirmation';

type CoachStatusToggleProps = {
  active: boolean;
  coachName: string;
  onActivationChange: () => void;
};

export const CoachStatusToggle = ({
  active,
  coachName,
  onActivationChange,
}: CoachStatusToggleProps) => {
  const handleDeactivateAccount = () => {
    openConfirmationModal({
      title: `Are you sure you want to deactivate the account “${coachName}”?`,
      text: 'Make sure all customers of this coach are reassigned to another coach',
      confirmBtnConfig: {
        text: 'Confirm',
        onClick: () => onActivationChange(),
      },
    });
  };

  const handleActivateAccount = () => {
    openConfirmationModal({
      title: `Are you sure you want to activate the account “${coachName}”?`,
      confirmBtnConfig: {
        text: 'Confirm',
        onClick: () => onActivationChange(),
      },
    });
  };

  return (
    <Paper variant="boxWithPadding">
      {active ? (
        <Button variant="text" color="primary" onClick={handleDeactivateAccount}>
          Deactivate Account
        </Button>
      ) : (
        <Button variant="text" color="primary" onClick={handleActivateAccount}>
          Activate Account
        </Button>
      )}
    </Paper>
  );
};
