import React, { useMemo } from 'react';

import {
  Control,
  FieldValues,
  Path,
  useController,
  UseControllerProps,
} from 'react-hook-form';

import { Autocomplete, MenuItem, TextField, TextFieldProps } from '@mui/material';
import { Sport } from '../../../interfaces/Sport';
import { useAutocompleteOneValue } from '../autocomplete-components/hooks/useAutocompleteOneValue';
import { TextFieldIcon } from '../LbSelect/LbSelect.settings';
import { lbSelectMenuStyles } from '../LbSelect/LbSelect.styles';

type SelectSportPickersProps<
  T extends FieldValues,
  TSportFieldName extends Path<T>,
  TSportPositionFieldName extends Path<T>,
> = {
  control: Control<T>;
  sports: Sport[];
  sportFieldProps: UseControllerProps<T, TSportFieldName>;
  sportPositionFieldProps: UseControllerProps<T, TSportPositionFieldName>;
} & Pick<TextFieldProps, 'variant' | 'InputProps'>;

const SelectSportPickers = <
  T extends FieldValues,
  TSportFieldName extends Path<T>,
  TSportPositionFieldName extends Path<T>,
>({
  control,
  sports,
  sportFieldProps,
  sportPositionFieldProps,
  variant,
  InputProps,
}: SelectSportPickersProps<T, TSportFieldName, TSportPositionFieldName>) => {
  const {
    field: sportField,
    fieldState: { error: sportError },
  } = useController<any>({
    control,
    defaultValue: null,
    ...sportFieldProps,
  });

  const {
    field: sportPositionField,
    fieldState: { error: sportPositionError },
  } = useController<any>({
    control,
    defaultValue: '',
    ...sportPositionFieldProps,
  });

  const positionsList = useMemo(
    () => sports.find((sport) => sport.value === sportField.value)?.positions || [],
    [sportField.value, sports],
  );

  const { options: sportOptions, getOptionLabel: getSportOptionLabel } =
    useAutocompleteOneValue({
      userOptions: sports.map((sport) => ({
        value: sport.value,
        label: sport.label,
      })),
      valueKey: 'value',
      labelKey: 'label',
    });

  const onSportChange = (_: any, value: string) => {
    if (sportPositionField.value) {
      const newPositions = sports.find((sport) => sport.value === value)?.positions || [];

      const isPositionExist = newPositions.find(
        (item) => item.value === sportPositionField.value,
      );

      if (!isPositionExist) {
        sportPositionField.onChange('');
      }
    }

    sportField.onChange(value);
  };

  return (
    <>
      <Autocomplete
        {...sportField}
        tabIndex={-1}
        fullWidth
        size="small"
        disableClearable
        onChange={onSportChange}
        componentsProps={{
          popper: {
            className: 'general-style variant-select',
            placement: 'bottom-start',
          },
        }}
        options={sportOptions}
        getOptionLabel={getSportOptionLabel}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{ ...params.InputProps, ...InputProps }}
            helperText={sportError?.message}
            error={!!sportError}
            label="Sport"
            required={!!sportFieldProps.rules?.required}
            variant={variant}
            inputRef={sportField.ref}
          />
        )}
      />
      {sportField.value && (
        <TextField
          select
          {...sportPositionField}
          value={sportPositionField.value}
          inputRef={sportPositionField.ref}
          label="Position"
          error={!!sportPositionError}
          required={!!sportPositionFieldProps.rules?.required}
          SelectProps={{
            IconComponent: TextFieldIcon,
            displayEmpty: false,
            MenuProps: { sx: lbSelectMenuStyles },
          }}
          fullWidth
          size="small"
          variant={variant}
          InputProps={InputProps}
        >
          {positionsList.map((option) => (
            <MenuItem value={option.value} key={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      )}
    </>
  );
};

export { SelectSportPickers };
