import { ValueOfKey } from '../../utils-types';
import { CUSTOMER_SPORT_TYPE } from './customerSportType';

export const QUESTION_TYPE = {
  ACTIVITY_INDEX: 'ACTIVITY_INDEX',
  WORKOUTS_PER_WEEK: 'WORKOUTS_PER_WEEK',
  MULTIPLYING_FACTOR_1: 'MULTIPLYING_FACTOR_1',
  MULTIPLYING_FACTOR_2: 'MULTIPLYING_FACTOR_2',
  COMMON: 'COMMON',
  GOAL: 'GOAL',
  OPTIONAL: 'OPTIONAL',
  CUSTOMER_SPORT_TYPE: 'CUSTOMER_SPORT_TYPE',
} as const;

export type QuestionType = ValueOfKey<typeof QUESTION_TYPE>;

export const QUESTION_FIELD_TYPE = {
  TEXT: 'TEXT',
  RADIO: 'RADIO',
} as const;
export type QuestionFieldType = ValueOfKey<typeof QUESTION_FIELD_TYPE>;

export const GOAL_ANSWER_TYPE = {
  FAT_LOSS_AND_MUSCLE_GAIN: 'A',
  FAT_LOSS_AND_MUSCLE_LOSS: 'B',
  GAIN_WEIGHT_HEAVY_BULK: 'C',
  GAIN_WEIGHT_LEAN_BULK: 'D',
} as const;

export const CUSTOMER_SPORT_TYPE_ANSWER_TYPE = {
  [CUSTOMER_SPORT_TYPE.AVERAGE_PERSON]: 'A',
  [CUSTOMER_SPORT_TYPE.ATHLETE]: 'B',
  [CUSTOMER_SPORT_TYPE.ATHLETE_SCHOOL]: 'C',
} as const;
