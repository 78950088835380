import React from 'react';

import { IconButton, SvgIcon, Switch } from '@mui/material';
import classNames from 'classnames';

import classes from './AffiliateInfoRow.module.scss';
import {
  GridTableCell,
  GridTableRow,
} from '../../../../../../shared/components/table-components';
import { SeparatorLine } from '../../../../../../shared/components/ui-components';
import { ReactComponent as PenIcon } from '../../../../../../shared/assets/icons/penBig.svg';
import { SchoolInfo } from '../../../../../../shared/interfaces/school/SchoolInfo';
// TODO pen and penBig need update to one icon

type AffiliateInfoRowProps = {
  onEdit: (affiliateInfo: SchoolInfo) => void;
  onActivationStatusChange: (value: number, status: boolean) => void;
  affiliateInfo: SchoolInfo;
};
// TODO disabled icon btn set theme style
const AffiliateInfoRow = ({
  onEdit,
  onActivationStatusChange,
  affiliateInfo,
}: AffiliateInfoRowProps) => {
  const { name, amountOfCustomers, active, id } = affiliateInfo;

  const handelEditAffiliate = () => {
    onEdit(affiliateInfo);
  };

  const handelAffiliateActivationChange = () => {
    onActivationStatusChange(id, !active);
  };

  const rowClasses = classNames({ [classes.deactivated]: !active });

  return (
    <GridTableRow variant="row" className={rowClasses}>
      <GridTableCell variant="title">{name}</GridTableCell>
      <GridTableCell>{amountOfCustomers}</GridTableCell>
      <GridTableCell justify="end" className={classes.optionBlock}>
        <Switch checked={active} onChange={handelAffiliateActivationChange} />
        <SeparatorLine />
        <IconButton color="secondary" onClick={handelEditAffiliate} disabled={!active}>
          <SvgIcon component={PenIcon} inheritViewBox fontSize="large" />
        </IconButton>
      </GridTableCell>
    </GridTableRow>
  );
};

export { AffiliateInfoRow };
