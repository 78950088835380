import React from 'react';

import { useFormContext } from 'react-hook-form';
import { observer } from 'mobx-react';

import { InfoBox, LbAlert } from '../../../../shared/components/ui-components';
import { LbSwitch } from '../../../../shared/components/form-components';
import { TitleWithDescription } from '../../../../shared/components/text-formation-components';
import { InbodyInfo } from './InbodyInfo/InbodyInfo';
import { EntityInbodyKeyTable } from './EntityInbodyKeyTable/EntityInbodyKeyTable';
import { useEntityInbodyKey } from '../../../hooks/useEntityInbodyKey';
import { loggedInUserDataStore } from '../../../../shared/stores/loggedInUserDataStore';
import { DisabledSelectInfo } from '../component/DisabledSelectInfo/DisabledSelectInfo';
import { inbodyTypeOptions } from '../../../../shared/constants/inbody-info/InbodyType';
import { EntityInbodyDataFormValue } from '../EntityInbodyDataConfig.settings';

type InbodyWidgetProps = {
  entityId: number;
};

const InbodyWidget = observer(({ entityId }: InbodyWidgetProps) => {
  const { control, watch } = useFormContext<EntityInbodyDataFormValue>();

  const { isSuperAdmin } = loggedInUserDataStore;

  const inbodyKeysMethods = useEntityInbodyKey(entityId);
  const isInbodyKeyExist = !!inbodyKeysMethods.inbodyKeys.length;

  const isAllowInbodyUsers = watch('allowInbodyUsers');
  const isCanNotUnselect = isAllowInbodyUsers && isInbodyKeyExist;

  const SwitchTitle = (
    <LbSwitch
      control={control}
      name="allowInbodyUsers"
      label={
        <TitleWithDescription
          title="InBody machine configuration"
          description="Turn on switcher and select type of InBody machine"
        />
      }
      align="start"
      disabled={isCanNotUnselect || !isSuperAdmin}
      defaultChecked={isInbodyKeyExist}
    />
  );

  return (
    <InfoBox title={SwitchTitle} hideConfig={{ show: isAllowInbodyUsers }}>
      {isSuperAdmin ? (
        <>
          <InbodyInfo control={control} />
          <EntityInbodyKeyTable {...inbodyKeysMethods} />
        </>
      ) : (
        <div>
          <DisabledSelectInfo
            name="supportedInbodyType"
            label="InBody:"
            options={inbodyTypeOptions}
          />
          <LbAlert severity="info" size="small">
            If you want to reconnect InBody or update any information, please contact our
            support team.
          </LbAlert>
        </div>
      )}
    </InfoBox>
  );
});

export { InbodyWidget };
