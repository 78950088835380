import { useState, useEffect } from 'react';

import { useForm } from 'react-hook-form';
import { useToggle } from 'react-use';

import { loggedInUserDataStore } from '../../../../../../shared/stores/loggedInUserDataStore';
import { updateItemByField } from '../../../../../../shared/utils/arrayUtils';
import { SchoolInfo } from '../../../../../../shared/interfaces/school/SchoolInfo';
import {
  AddSchool,
  schoolsService,
} from '../../../../../../shared/services/schools/schoolsService';

// TODO look maybe better reset function to store, look at used CorporateAccountsModal
export const useScholasticAffiliate = () => {
  const [affiliatesList, setAffiliatesList] = useState<SchoolInfo[]>([]);
  const [isOpenModalAffiliate, toggleOpenModalAffiliate] = useToggle(false);
  const [editAffiliateId, setEditAffiliateId] = useState<number | null>(null);

  const entityId = loggedInUserDataStore.getEntityId();

  const { control, handleSubmit, setValue } = useForm<AddSchool>({
    shouldUnregister: true,
  });

  const onSubmit = async (affiliateInfo: AddSchool) => {
    if (editAffiliateId) {
      editAffiliate(affiliateInfo);
    } else {
      addNewAffiliate(affiliateInfo);
    }

    handleCloseModalAffiliate();
  };

  const addNewAffiliate = async (affiliateInfo: AddSchool) => {
    const newAffiliate = await schoolsService.addNewSchool(entityId, affiliateInfo);

    setAffiliatesList([newAffiliate, ...affiliatesList]);
  };

  const editAffiliate = async (affiliateInfo: AddSchool) => {
    const editedAffiliateInfo = await schoolsService.editSchool(
      entityId,
      editAffiliateId!,
      affiliateInfo,
    );

    updateAffiliate(editedAffiliateInfo);
  };

  const handleAffiliateActiveStatusChange = async (
    affiliateId: number,
    status: boolean,
  ) => {
    const resp = await schoolsService.changeSchoolStatus(entityId, affiliateId, status);

    updateAffiliate(resp);
  };

  const updateAffiliate = (newAffiliateInfo: SchoolInfo) => {
    const editedAffiliateList = updateItemByField(affiliatesList, newAffiliateInfo, {
      id: newAffiliateInfo.id,
    });

    setAffiliatesList(editedAffiliateList);
  };

  const openAddNewAffiliateModal = () => {
    toggleOpenModalAffiliate();
  };

  const openEditAffiliateModal = (affiliateInfo: SchoolInfo) => {
    const { id, name } = affiliateInfo;

    setEditAffiliateId(id);
    setValue('name', name);
    toggleOpenModalAffiliate();
  };

  const handleCloseModalAffiliate = () => {
    setEditAffiliateId(null);
    toggleOpenModalAffiliate();
  };

  const getAffiliateList = async () => {
    const { customerSchoolGroups } = await schoolsService.getSchoolsList(entityId);

    setAffiliatesList(customerSchoolGroups);
  };

  const validateIsAffiliateAlreadyExist = (newAffiliateName: string) => {
    const isExist = affiliatesList.some(
      (affiliate) => affiliate.name === newAffiliateName,
    );

    return !isExist || 'Affiliate already exist';
  };

  useEffect(() => {
    getAffiliateList();
  }, []);

  return {
    affiliatesList,
    isOpenModalAffiliate,
    editAffiliateId,
    control,
    handleFormSubmit: handleSubmit(onSubmit),
    openAddNewAffiliateModal,
    openEditAffiliateModal,
    handleCloseModalAffiliate,
    handleAffiliateActiveStatusChange,
    validateIsAffiliateAlreadyExist,
  };
};
