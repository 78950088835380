import React from 'react';
import PropTypes from 'prop-types';

import {
  GridTableCell,
  EditDeleteButtonGroup,
} from '../../../../../../../../shared/components/table-components';
import { inbodyKeysStore } from '../../../../../../../stores/inbodyKeysStore';
import { TextTruncate } from '../../../../../../../../shared/components/ui-components';

const NormalView = ({ setIsEditable, inbodyKeyInfo, index }) => {
  const { deleteKey, increaseEditableKeysCount } = inbodyKeysStore;

  const { entityName, inbodyKey, accountName, description, id } = inbodyKeyInfo;

  const onEdit = () => {
    setIsEditable(true);
    increaseEditableKeysCount();
  };

  const onDelete = () => {
    deleteKey(id);
  };

  return (
    <>
      <GridTableCell variant="title">{index + 1}</GridTableCell>
      <p>{entityName}</p>
      <TextTruncate text={inbodyKey} />
      <p>{accountName}</p>
      <TextTruncate text={description} />
      <EditDeleteButtonGroup
        onEdit={onEdit}
        onDelete={onDelete}
        modalTitle="Are you sure you want to delete an InBody key?"
      />
    </>
  );
};

NormalView.propTypes = {
  setIsEditable: PropTypes.func.isRequired,
  inbodyKeyInfo: PropTypes.shape({
    entityName: PropTypes.string,
    inbodyKey: PropTypes.string,
    accountName: PropTypes.string,
    description: PropTypes.string,
    id: PropTypes.number,
    entityId: PropTypes.number,
  }).isRequired,
  index: PropTypes.number.isRequired,
};

export { NormalView };
