import React, { useState } from 'react';

import { Button } from '@mui/material';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';

import classes from './DataAccessPermissionInfo.module.scss';
import {
  INBODY_ACCOUNT_ACCESS_PERMISSION,
  InbodyAccountAccessPermission,
} from '../../../../constants/inbodyAccountAccessPermission';
import { InfoTooltip, LbChip } from '../../../../../shared/components/ui-components';

type DataAccessPermissionInfoProps = {
  inbodyAccountId: number | null;
  accessPermission: InbodyAccountAccessPermission;
  onRequestDataAccess: () => void;
};

const DataAccessPermissionInfo = ({
  inbodyAccountId,
  accessPermission,
  onRequestDataAccess,
}: DataAccessPermissionInfoProps) => {
  const [isRequestSent, setIsRequestSent] = useState(false);

  const handleRequestDataAccess = () => {
    onRequestDataAccess();
    setIsRequestSent(true);
  };

  const { NO, REQUESTED, YES } = INBODY_ACCOUNT_ACCESS_PERMISSION;

  if (!inbodyAccountId) {
    return (
      <LbChip
        label={
          <div className={classes.chipLabel}>
            <span>No Access to Data</span>
            <InfoTooltip placement="bottom-end" color="inherit">
              You will be able to request data access
              <br /> once the account has been created
            </InfoTooltip>
          </div>
        }
        color="default"
        size="medium"
      />
    );
  }

  if (isRequestSent) {
    return (
      <Button variant="outlined" size="small" startIcon={<CheckRoundedIcon />} disabled>
        Request Sent
      </Button>
    );
  }

  switch (accessPermission) {
    case NO:
    case REQUESTED:
      return (
        <Button variant="outlined" size="small" onClick={handleRequestDataAccess}>
          Request Data Access
        </Button>
      );
    case YES:
      return <LbChip label="Data Access is agreed" color="success" size="medium" />;
    default:
      return <div>Invalid access permission</div>;
  }
};

export { DataAccessPermissionInfo };
