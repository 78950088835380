import { apiService } from './apiService';
import { Sport } from '../interfaces/Sport';

export const sportsService = {
  async getSports() {
    const resp = await apiService.get<Sport[]>('/api/sign-up/customers/sports');

    return resp.data;
  },
};
