import { QuestionInfo, QuestionWithAnswer } from '../../interfaces/QuestionnaireInfo';
import { QUESTION_FIELD_TYPE } from '../../constants/question';

export const convertUserAnswers = (
  questions: QuestionInfo[],
  answers: Record<string, number>,
): QuestionWithAnswer[] =>
  questions.map((question) => {
    const answer = answers[question.questionId];

    switch (question.fieldType) {
      case QUESTION_FIELD_TYPE.TEXT:
        return {
          questionId: question.questionId,
          answer: { answerId: question.answers[0].answerId, name: answer },
        };
      case QUESTION_FIELD_TYPE.RADIO:
      default:
        return {
          questionId: question.questionId,
          answer: { answerId: Number(answer) },
        };
    }
  });
