import { ValueOfKey } from '../../../utils-types';

export const H20B_PRICE = {
  INITIAL: 599,
  DISCOUNT: 399,
};

export const H20B_INFO_URL = 'https://inbody.lifebasesolutions.com/h20b-scale';

export const H20B_BUY_URL = process.env.APP_BUY_H20B_URL;

export const H20B_SCALE_STATUS = {
  ORDERED: 'ORDERED',
  NOT_ORDERED: 'NOT_ORDERED',
  SCAN_RECEIVED: 'SCAN_RECEIVED',
} as const;

export type H20BScaleStatus = ValueOfKey<typeof H20B_SCALE_STATUS>;
