import React from 'react';

import { angularize } from 'react-in-angularjs';

import classes from './NgzAffiliatesListTab.module.scss';
import { LbInput } from '../../../../../shared/components/form-components';
import { LbModal } from '../../../../../shared/components/modals/LbModal/LbModal';
import { SystemProviders } from '../../../../../shared/providers';
import { useScholasticAffiliate } from './hooks/useScholasticAffiliate';
import { AffiliatesHeaderRow } from './AffiliateHeaderRow/AffiliatesHeaderRow';
import { AffiliatesTitle } from './AffiliateTitle/AffiliatesTitle';
import { AffiliateInfoRow } from './AffiliateInfoRow/AffiliateInfoRow';
import { useSort } from '../../../../../shared/hooks/useSort';

const NgzAffiliatesListTab = () => {
  const {
    affiliatesList,
    isOpenModalAffiliate,
    editAffiliateId,
    control,
    handleFormSubmit,
    handleAffiliateActiveStatusChange,
    handleCloseModalAffiliate,
    openAddNewAffiliateModal,
    openEditAffiliateModal,
    validateIsAffiliateAlreadyExist,
  } = useScholasticAffiliate();

  const { sortedData: sortedAffiliatesList, sortMethods } = useSort({
    data: affiliatesList,
    sortTypeConfig: { name: 'STRING', amountOfCustomers: 'NUMBER' },
  });

  return (
    <SystemProviders>
      <AffiliatesTitle onShowModalAffiliate={openAddNewAffiliateModal} />
      <div className={classes.schoolsTable}>
        <AffiliatesHeaderRow sortMethods={sortMethods} />
        {sortedAffiliatesList.map((affiliate) => (
          <AffiliateInfoRow
            onActivationStatusChange={handleAffiliateActiveStatusChange}
            onEdit={openEditAffiliateModal}
            affiliateInfo={affiliate}
            key={affiliate.id}
          />
        ))}
      </div>
      <LbModal
        open={isOpenModalAffiliate}
        title={editAffiliateId ? 'Edit Affiliate' : 'Add New Affiliate'}
        primaryBtnConfig={{ text: 'Confirm', onClick: handleFormSubmit }}
        secondaryBtnConfig={{ onClick: handleCloseModalAffiliate }}
      >
        <LbInput
          name="name"
          label="Affiliate Name"
          control={control}
          rules={{
            required: 'Affiliate Name is required',
            validate: validateIsAffiliateAlreadyExist,
          }}
        />
      </LbModal>
    </SystemProviders>
  );
};

angularize(NgzAffiliatesListTab, 'ngzAffiliatesListTab', angular.module('app'), {});
