import React, { ReactNode } from 'react';

import classNames from 'classnames';

import classes from './CustomerParam.module.scss';

type CustomerParamProps = {
  title: ReactNode;
  value: ReactNode;
  className?: string;
};

const CustomerParam = ({ title, value, className }: CustomerParamProps) => {
  return (
    <div className={classNames(classes.customerParam, className)}>
      <div className={classes.title}>{title}</div>
      <div className={classes.value}>{value}</div>
    </div>
  );
};

export { CustomerParam };
