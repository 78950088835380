import React from 'react';

import { useFormContext } from 'react-hook-form';

import {
  ModalInputBox,
  ModalInputGroup,
} from '../../../../../../../shared/components/modals';
import { LbAutocomplete } from '../../../../../../../shared/components/form-components';
import { useScanDataRangeOptions } from '../../../../../../hooks/useScanDataRangeOptions/useScanDataRangeOptions';
import { CompareScanMetricsFormValues } from '../CompareScanMetricsModal.settings';

type CompareDateFormProps = {
  scanDateTimes: string[];
};

const CompareDateForm = ({ scanDateTimes }: CompareDateFormProps) => {
  const { control, watch } = useFormContext<CompareScanMetricsFormValues>();
  const { startScanDate, endScanDate } = watch();

  const { startScanDateOptions, endScanDateOptions, getOptionLabel } =
    useScanDataRangeOptions({
      scanDateTimes,
      startScanDate,
      endScanDate,
    });

  return (
    <ModalInputBox
      title="Select Scans  dates for the period you want to compare"
      showDivider={false}
    >
      <ModalInputGroup>
        <LbAutocomplete
          control={control}
          variant="outlined"
          size="small"
          name="startScanDate"
          label="Start Scan Date"
          options={startScanDateOptions}
          getOptionLabel={getOptionLabel}
        />
        <LbAutocomplete
          control={control}
          variant="outlined"
          size="small"
          name="endScanDate"
          label="End Scan Date"
          options={endScanDateOptions}
          getOptionLabel={getOptionLabel}
        />
      </ModalInputGroup>
    </ModalInputBox>
  );
};

export { CompareDateForm };
