import React from 'react';

import classes from './H20BStatus.module.scss';
import {
  H20BScaleStatus,
  H20B_SCALE_STATUS,
} from '../../../../../shared/constants/inbody-info/h20bInfo';
import { InfoTooltip, LbChip } from '../../../../../shared/components/ui-components';

type H20BStatusProps = {
  status: H20BScaleStatus;
};

const H20BStatus = ({ status }: H20BStatusProps) => {
  switch (status) {
    case H20B_SCALE_STATUS.SCAN_RECEIVED:
      return (
        <LbChip
          label={
            <div className={classes.chipLabel}>
              <span>H20B</span>
              <InfoTooltip placement="top" color="inherit">
                The customer received the first scan H20B type <br /> in the LifeBase
                Solutions system
              </InfoTooltip>
            </div>
          }
          color="success"
          size="extraSmall"
        />
      );
    case H20B_SCALE_STATUS.ORDERED:
      return (
        <LbChip
          label={
            <div className={classes.chipLabel}>
              <span>H20B</span>
              <InfoTooltip placement="top" color="inherit" iconFontSize="extraSmall">
                H20B order created in the GHL shop
              </InfoTooltip>
            </div>
          }
          color="default"
          size="extraSmall"
        />
      );
    case H20B_SCALE_STATUS.NOT_ORDERED:
    default:
      return null;
  }
};

export { H20BStatus };
