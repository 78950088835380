import React from 'react';

import { useFormContext } from 'react-hook-form';

import classes from './MetricsSection.module.scss';
import { ModalInputBox } from '../../../../../../../shared/components/modals';
import { LbCheckbox } from '../../../../../../../shared/components/form-components';
import { metricsOptions } from './MetricsSection.settings';
import { getIsMetricDisabled } from './utils/getIsMetricDisabled';
import { SocialSharingScanMetricsParams } from '../../../../../../interfaces/socialSharingScanMetrics';

const MetricsSection = () => {
  const { control, watch } = useFormContext<SocialSharingScanMetricsParams>();

  const metrics = watch('metrics');

  return (
    <ModalInputBox title="Customer metrics" description="Select a maximum of 3 metrics">
      <div className={classes.metricsGroup}>
        {metricsOptions.map((option) => (
          <LbCheckbox
            control={control}
            name={`metrics.${option.value}`}
            label={option.label}
            color="primary"
            disabled={getIsMetricDisabled(metrics, option.value)}
            key={option.value}
          />
        ))}
      </div>
    </ModalInputBox>
  );
};

export { MetricsSection };
