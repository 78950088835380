import React, { ChangeEventHandler } from 'react';

import PhotoCameraRoundedIcon from '@mui/icons-material/PhotoCameraRounded';
import classNames from 'classnames';

import classes from './UploadAvatarIcon.module.scss';
import { AvatarIcon } from '../AvatarIcon/AvatarIcon';

type UploadAvatarIconProps = {
  name?: string;
  avatar?: string | null;
  disabled?: boolean;
  onAvatarChange: ChangeEventHandler<HTMLInputElement>;
};

const UploadAvatarIcon = ({
  name,
  avatar,
  onAvatarChange,
  disabled,
}: UploadAvatarIconProps) => {
  return (
    <div className={classNames(classes.avatarBox, 'private-info')}>
      <AvatarIcon userName={name} avatarImgLink={avatar} size="large" />
      {!disabled && (
        <label htmlFor="avatar-file" className={classes.avatarUpload}>
          <PhotoCameraRoundedIcon color="white" fontSize="medium" />
          <span>Upload</span>
          <input
            onChange={onAvatarChange}
            id="avatar-file"
            type="file"
            accept="image/*"
          />
        </label>
      )}
    </div>
  );
};

export { UploadAvatarIcon };
