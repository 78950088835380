import React from 'react';

import { Paper } from '@mui/material';
import { observer } from 'mobx-react';
import { useWindowSize } from 'react-use';

import classes from './CoachTable.module.scss';
import { FiltersRow } from './FiltersRow/FiltersRow';
import { OfflineCoachRow } from './OfflineCoachRow/OfflineCoachRow';
import { RemoteCoachRow } from './RemoteCoachRow/RemoteCoachRow';
import {
  EmptyList,
  LbPagination,
} from '../../../../../../shared/components/ui-components';
import { coachStore } from '../../../../../stores/customerSelfSetupStores/coachStore';
import { CONSULTATION_TYPE } from '../../../../../../shared/constants/coach/consultationType';

const CoachTable = observer(() => {
  const { coachList, selectedCoach, consultationType, setSelectedCoach, filterMethods } =
    coachStore;

  const { pagesCount, elementsPerPage, totalElements, isSearchUsed, filterData } =
    filterMethods;
  const { page, onChange } = filterMethods.getPaginationProps();

  const { width } = useWindowSize();
  const isMobile = width < 768;

  const getDescriptionForEmptyList = () => {
    if (!consultationType) {
      return 'To find coach, please select collaboration format';
    }

    if (filterData.zipCode && filterData.maxDistance) {
      return `There is no coach within ${filterData.maxDistance} miles, we recommend working remotely`;
    }

    return 'Coach list is empty';
  };

  return (
    <>
      {consultationType === CONSULTATION_TYPE.OFFLINE && (
        <div className={classes.table}>
          <FiltersRow isMobile={isMobile} />
          {coachList.map((coach) => (
            <OfflineCoachRow
              key={coach.coachId}
              coach={coach}
              selectedCoachId={selectedCoach?.coachId}
              onClick={setSelectedCoach}
              isMobile={isMobile}
            />
          ))}
        </div>
      )}
      {consultationType === CONSULTATION_TYPE.REMOTE &&
        coachList.map((coach) => (
          <RemoteCoachRow
            key={coach.coachId}
            coach={coach}
            selectedCoachId={selectedCoach?.coachId}
            onClick={setSelectedCoach}
          />
        ))}
      <LbPagination
        elementsPerPage={elementsPerPage}
        count={pagesCount}
        variant="outBox"
        totalElements={totalElements}
        page={page}
        onChange={onChange}
      />
      <Paper variant="roundedBox">
        <EmptyList
          show={!totalElements}
          isSearch={isSearchUsed}
          description={getDescriptionForEmptyList()}
        />
      </Paper>
    </>
  );
});

export { CoachTable };
