import { SchoolInfo } from '../../interfaces/school/SchoolInfo';
import { apiService } from '../apiService';

export type GetSchoolListResponse = {
  customerSchoolGroups: SchoolInfo[];
};

export type AddSchool = {
  name: string;
};

export type EditSchool = {
  name: string;
};

export const schoolsService = {
  async getSchoolsList(entityId: number, isRunGlobalSpinner?: boolean) {
    const resp = await apiService.get<GetSchoolListResponse>(
      `/api/entity/${entityId}/school`,
      { isRunGlobalSpinner },
    );

    return resp.data;
  },

  async addNewSchool(entityId: number, school: AddSchool) {
    const resp = await apiService.post(`/api/entity/${entityId}/school`, school);

    return resp.data;
  },

  async editSchool(entityId: number, schoolId: number, schoolInfo: EditSchool) {
    const resp = await apiService.put(
      `/api/entity/${entityId}/school/${schoolId}`,
      schoolInfo,
    );

    return resp.data;
  },

  async changeSchoolStatus(entityId: number, schoolId: number, status: boolean) {
    const params = { status };

    const resp = await apiService.put(
      `/api/entity/${entityId}/school/${schoolId}/status`,
      undefined,
      { params },
    );

    return resp.data;
  },

  // used for self-setup
  async getPublicEntitySchools(entityId: number) {
    const params = { entityId };

    const resp = await apiService.get<GetSchoolListResponse>(
      `api/sign-up/customers/schools`,
      { params },
    );

    return resp.data;
  },

  // for superAdmin
  async getSchoolsForAllEntities(isScholastic: boolean, isRunGlobalSpinner?: boolean) {
    const resp = await apiService.get<GetSchoolListResponse>('/api/customers/schools', {
      isRunGlobalSpinner,
      params: { isScholastic },
    });

    return resp.data;
  },
};
