import { useEffect, useState } from 'react';

import { Sport } from '../../../../../../../../interfaces/Sport';
import { sportsService } from '../../../../../../../../services/sportsService';

type UseSportsReturns = {
  sports: Sport[];
};

export const useSports = (): UseSportsReturns => {
  const [sports, setSports] = useState<Sport[]>([]);

  const getSports = async () => {
    const data = await sportsService.getSports();

    setSports(data);
  };

  useEffect(() => {
    getSports();
  }, []);

  return { sports };
};
