import { SocialSharingMetrics } from '../../../../../../../interfaces/socialSharingScanMetrics';

export const getIsMetricDisabled = (
  metrics: Record<SocialSharingMetrics, boolean> | undefined,
  metricKey: SocialSharingMetrics,
): boolean => {
  const maxMetrics = 3;

  return (
    !!metrics &&
    !metrics[metricKey] &&
    Object.values(metrics).filter(Boolean).length === maxMetrics
  );
};
