import {
  INBODY_INDICATORS_KEY,
  InbodyIndicatorsKey,
} from '../../../../../../constants/inbodyIndecatorsKey';

export const availableIndicators: InbodyIndicatorsKey[] = [
  INBODY_INDICATORS_KEY.WEIGHT,
  INBODY_INDICATORS_KEY.SMM,
  INBODY_INDICATORS_KEY.BFM,
  INBODY_INDICATORS_KEY.PBF,
  INBODY_INDICATORS_KEY.TBW,
  INBODY_INDICATORS_KEY.ICW,
  INBODY_INDICATORS_KEY.ECW,
  INBODY_INDICATORS_KEY.DLM,
  INBODY_INDICATORS_KEY.ECW_TBW,
];
