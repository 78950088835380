import React, { ChangeEvent, useState } from 'react';
import { Checkbox, Link } from '@mui/material';

import classes from './TermsAcceptCheckbox.module.scss';
import { LbFormControlLabel } from '../../../../shared/components/form-components';
import { TermsLinks } from '../../../../shared/components/TermsLinks/TermsLinks';

type TermsAcceptCheckboxProps = {
  isTermsAccepted: boolean;
  setIsTermsAccepted: (value: boolean) => void;
  entityName?: string;
};

const TermsAcceptCheckbox = ({
  isTermsAccepted,
  setIsTermsAccepted,
  entityName,
}: TermsAcceptCheckboxProps) => {
  const [isCommunicationsAccepted, setIsCommunicationsAccepted] =
    useState<boolean>(isTermsAccepted);
  const [isMarketingAccepted, setIsMarketingAccepted] =
    useState<boolean>(isTermsAccepted);

  const entityInfoText = entityName || "it's affiliated entities and coaches";

  const handleCommunicationsChange = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    setIsCommunicationsAccepted(checked);
    setIsTermsAccepted(checked && isMarketingAccepted);
  };

  const handleMarketingChange = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    setIsMarketingAccepted(checked);
    setIsTermsAccepted(checked && isCommunicationsAccepted);
  };

  return (
    <div className={classes.terms}>
      <LbFormControlLabel
        label={
          <div>
            <span>
              By submitting the form, you agree to receive SMS and Email communications
              from LifeBase Solutions and {entityInfoText}. Text HELP to
            </span>
            &nbsp;
            <Link href="tel:3082705580">3082705580</Link>
            &nbsp;
            <span>for assistance. You can reply STOP to unsubscribe at any time.</span>
          </div>
        }
        control={
          <Checkbox
            checked={isCommunicationsAccepted}
            onChange={handleCommunicationsChange}
            color="primary"
          />
        }
      />
      <LbFormControlLabel
        label={`By checking this box I agree to receive occasional marketing messages from LifeBase Solutions and ${entityInfoText}.`}
        control={
          <Checkbox
            checked={isMarketingAccepted}
            onChange={handleMarketingChange}
            color="primary"
          />
        }
      />
      <TermsLinks />
    </div>
  );
};

export { TermsAcceptCheckbox, TermsAcceptCheckboxProps };
