import React, { ChangeEvent } from 'react';

import { Paper } from '@mui/material';
import { angularize } from 'react-in-angularjs';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import classes from './NgzCoachProfileCard.module.scss';
import { SystemProviders } from '../../../shared/providers';
import { StatusInfo } from './components/StatusInfo/StatusInfo';
import { Point } from './components/Point/Point';
import { UploadAvatarIcon } from '../../../shared/components/ui-components';
import { loggedInUserDataStore } from '../../../shared/stores/loggedInUserDataStore';

type NgzCoachProfileCardProps = {
  name?: string;
  gymName?: string;
  avatar?: string | null;
  isActive: boolean;
  onAvatarChange?: (data: { newAvatar: File }) => void;
};

const NgzCoachProfileCard = observer(
  ({ name, gymName, avatar, isActive, onAvatarChange }: NgzCoachProfileCardProps) => {
    const { isCoach } = loggedInUserDataStore;

    const handleUploadAvatar = async (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.files && onAvatarChange) {
        onAvatarChange({ newAvatar: event.target.files[0] });
      }
    };

    return (
      <SystemProviders>
        <Paper variant="roundedBox" className={classes.container}>
          <UploadAvatarIcon
            name={name}
            avatar={avatar}
            onAvatarChange={handleUploadAvatar}
            disabled={!isCoach}
          />
          <div className={classes.infoBox}>
            <h3 className={classNames(classes.name, 'private-info')}>{name}</h3>
            <div className={classes.descriptionBox}>
              <span>Coach</span>
              <Point />
              <div className="private-info">
                <span className={classes.label}>Entity:&nbsp;</span>
                {gymName}
              </div>
              <Point />
              <StatusInfo isActive={isActive} />
            </div>
          </div>
        </Paper>
      </SystemProviders>
    );
  },
);

angularize(NgzCoachProfileCard, 'ngzCoachProfileCard', angular.module('app'), {
  name: '<',
  gymName: '<',
  avatar: '<',
  isActive: '<',
  onAvatarChange: '&',
});

export { NgzCoachProfileCard };
