import React from 'react';

import { Control, useWatch } from 'react-hook-form';

import classes from './DiscountFormSection.module.scss';
import { CoachInfoFormValue } from '../../../../interfaces/CoachData';
import {
  InputBox,
  LbInput,
  LbSwitch,
} from '../../../../../shared/components/form-components';
import { LbAlert } from '../../../../../shared/components/ui-components';
import { validatePercent } from '../../../../../shared/utils/reactHookFormUtils';

type DiscountFormSectionProps = {
  control: Control<CoachInfoFormValue>;
};

const DiscountFormSection = ({ control }: DiscountFormSectionProps) => {
  const privateInfoProps = { InputProps: { className: 'private-info' } };

  const generalInputProps = {
    control,
    size: 'small',
    variant: 'standard',
    ...privateInfoProps,
  } as const;

  const ableToGiveDiscount = useWatch({ control, name: 'additional.ableToGiveDiscount' });

  return (
    <InputBox
      title="Max Discounts"
      icon={
        <LbSwitch
          control={control}
          name="additional.ableToGiveDiscount"
          label="Allow coach to give discounts"
        />
      }
      hideConfig={{ show: ableToGiveDiscount }}
    >
      <LbInput
        {...generalInputProps}
        label="Initial setup (%)"
        name="additional.setupDiscountPercent"
        type="number"
        rules={{
          required: 'Initial setup is required',
          validate: { validatePercent },
        }}
      />
      <LbInput
        {...generalInputProps}
        label="Initial membership invoice (%)"
        name="additional.membershipDiscountPercent"
        type="number"
        rules={{
          required: 'Initial membership invoice is required',
          validate: { validatePercent },
        }}
      />
      <LbInput
        {...generalInputProps}
        label="Renewal (%)"
        name="additional.renewalDiscountPercent"
        type="number"
        rules={{
          required: 'Renewal is required',
          validate: { validatePercent },
        }}
      />
      <LbAlert severity="info" size="small" className={classes.alertRow}>
        <span>Maximum discounts available to entity coaches.</span>
        <br />
        <span>
          Note - the discount percentages only apply to entity profit. Entity coaches will
          be unable to discount below minimum software pricing floor
        </span>
      </LbAlert>
    </InputBox>
  );
};

export { DiscountFormSection };
