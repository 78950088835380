import React, { useEffect } from 'react';

import { observer } from 'mobx-react';

import {
  ButtonRow,
  PersonalInfoForm,
  PersonalInfoFormFormValues,
  TermsAcceptCheckbox,
} from '../../../../components/customer-self-setup-components';
import { UseStepperReturn } from '../../../../../shared/hooks/useStepper';
import { personalInfoStore } from '../../../../stores/customerSelfSetupStores/personalInfoStore';
import { useScrollToTop } from '../../../../../shared/hooks/useScrollToTop';
import { questionnaireStore } from '../../../../stores/customerSelfSetupStores/questionnaireStore';
import { CUSTOMER_SPORT_TYPE } from '../../../../constants/customerSportType';

type PersonalInfoStepProps = {
  stepperMethods: UseStepperReturn;
  entityId: number;
  entityName: string;
};

const personalInfoFormId = 'personalInfoForm';

const PersonalInfoStep = observer(
  ({ stepperMethods, entityId, entityName }: PersonalInfoStepProps) => {
    useScrollToTop();

    const {
      customerData,
      isTermsAccepted,
      entitySchools,
      sports,
      setIsTermsAccepted,
      getEntitySchools,
      getSports,
    } = personalInfoStore;

    const { customerSportType } = questionnaireStore;

    const handleFormSubmit = async (data: PersonalInfoFormFormValues) => {
      personalInfoStore.setCustomerData(data);

      stepperMethods.goToNextStep();
    };

    useEffect(() => {
      if (customerSportType === CUSTOMER_SPORT_TYPE.ATHLETE_SCHOOL) {
        getEntitySchoolsIfNeeded();
        getSportsIfNeeded();
      }
    }, []);

    const getEntitySchoolsIfNeeded = () => {
      if (!entitySchools.length) {
        getEntitySchools(entityId);
      }
    };

    const getSportsIfNeeded = () => {
      if (!sports.length) {
        getSports();
      }
    };

    return (
      <div>
        <PersonalInfoForm
          // We are using shouldUnregister here for cases when user goes back to questionnaire and changes his sport type
          // In such cases we need to clear the additional info fields
          formConfig={{ defaultValues: customerData, shouldUnregister: true }}
          onSubmit={handleFormSubmit}
          formId={personalInfoFormId}
          customerSportType={customerSportType}
          entitySchools={entitySchools}
          sports={sports}
        />
        <TermsAcceptCheckbox
          isTermsAccepted={isTermsAccepted}
          setIsTermsAccepted={setIsTermsAccepted}
          entityName={entityName}
        />
        <ButtonRow
          yesDisabled={!isTermsAccepted}
          noHandler={stepperMethods.goToPreviousStep}
          formId={personalInfoFormId}
        />
      </div>
    );
  },
);

export { PersonalInfoStep };
