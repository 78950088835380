import { apiService } from '../../shared/services/apiService';
import {
  CustomerInbodyAccount,
  InbodyAccountInfo,
} from '../interfaces/customerInbodyAccount';

type GetCustomerInbodyAccountsResponse = {
  customerInbodyAccounts: CustomerInbodyAccount[];
};

export const customerInbodyAccountService = {
  async getCustomerInbodyAccounts(customerId: string) {
    const resp = await apiService.get<GetCustomerInbodyAccountsResponse>(
      `/api/coach/users/${customerId}/inbody-data`,
    );

    return resp.data;
  },

  async refreshCustomerInbodyAccount(
    customerId: string,
    inbodyAccountInfo: InbodyAccountInfo,
  ) {
    const resp = await apiService.post<CustomerInbodyAccount>(
      `/api/coach/users/${customerId}/inbody-data`,
      inbodyAccountInfo,
    );

    return resp.data;
  },

  async requestInbodyAccountAccess(
    customerId: string,
    inbodyAccountInfo: InbodyAccountInfo,
  ) {
    const resp = await apiService.post<CustomerInbodyAccount>(
      `api/coach/users/${customerId}/inbody-data/request-access`,
      inbodyAccountInfo,
    );

    return resp.data;
  },
};
