import {
  CONSULTATION_TYPE,
  ConsultationType,
} from '../../../../../../shared/constants/coach/consultationType';

type Config = {
  chip: string;
  title: string;
  description: string;
};

const configData: Record<ConsultationType, Config> = {
  [CONSULTATION_TYPE.REMOTE]: {
    chip: 'Purchase Now',
    title: 'REQUIRED PURCHASE',
    description: 'Please proceed with a purchase to finalize your setup!',
  },
  [CONSULTATION_TYPE.OFFLINE]: {
    chip: 'Limited time offer',
    title: 'RECOMMENDED PURCHASE',
    description:
      'InBody H20B Scale at-home scale is exclusive to LifeBase clients and lets you work with your coach remotely!',
  },
};

export const getVariantConfig = (consultationType: ConsultationType) => {
  return configData[consultationType];
};
