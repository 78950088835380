import React, { ReactNode } from 'react';

import classNames from 'classnames';

import classes from './VideoBlock.module.scss';

type VideoBlockProps = {
  title: ReactNode;
  description: ReactNode;
  videoUrl: string;
  videoName: string;
  className?: string;
};

const VideoBlock = ({
  title,
  description,
  videoUrl,
  videoName,
  className,
}: VideoBlockProps) => {
  return (
    <div className={classNames(classes.container, className)}>
      <p className={classes.title}>{title}</p>
      <p className={classes.subTitle}>{description}</p>
      <iframe
        src={videoUrl}
        title={videoName}
        allowFullScreen
        className={classes.video}
      />
    </div>
  );
};

export { VideoBlock };
