import React from 'react';

import { observer } from 'mobx-react';
import { useForm } from 'react-hook-form';

import classes from './CoachBioModal.module.scss';
import { LbBigModal, ModalInputBox } from '../../../../../../shared/components/modals';
import { LbSwitch, TextArea } from '../../../../../../shared/components/form-components';
import { loggedInUserDataStore } from '../../../../../../shared/stores/loggedInUserDataStore';
import { CoachBio } from '../../../../../interfaces/CoachData';
import { validateWhitespace } from '../../../../../../shared/utils/reactHookFormUtils';

type CoachBioModalProps = {
  bio: string | null;
  onSubmit: (data: CoachBio) => void;
  onClose: () => void;
};

type CoachBioFormValue = {
  bio: string;
  publishBio: boolean;
};

const CoachBioModal = observer(({ bio, onSubmit, onClose }: CoachBioModalProps) => {
  const { isSuperAdmin } = loggedInUserDataStore;
  const { control, handleSubmit } = useForm<CoachBioFormValue>({
    defaultValues: { bio: bio || '', publishBio: true },
  });

  const tipsList = (
    <ul className={classes.tipsList}>
      <li>Share your experience as a nutrition coach.</li>
      <li>Mention your expertise in specific areas.</li>
      <li>Highlight what makes you unique.</li>
    </ul>
  );

  const handleFormSubmit = handleSubmit(async (data: CoachBioFormValue) => {
    onSubmit(data);
    onClose();
  });

  return (
    <LbBigModal
      open
      title="Edit Bio"
      description="Write a brief description of your experience or skills. This will be shown to customers when they select a coach during registration."
      buttonOptions={{
        yesText: 'Confirm',
        yesHandler: handleFormSubmit,
        noText: 'Cancel',
        noHandler: onClose,
      }}
    >
      <div>
        <ModalInputBox title="Recommended Tips" description={tipsList}>
          <div className={classes.description}>
            “With 12+ years experience serving clients with disabilities, I customize
            nutrition strategies that accommodate unique needs, ensuring accessible and
            nourishing meal plans.”
          </div>
          <TextArea
            control={control}
            name="bio"
            label="Description"
            rows={4}
            helperText="The recommended characters count is up to 180 characters"
            rules={{
              required: 'Bio is required',
              maxLength: { value: 180, message: 'Bio must be less than 180 characters' },
              validate: { validateWhitespace },
            }}
          />
        </ModalInputBox>
        {isSuperAdmin && (
          <LbSwitch
            control={control}
            name="publishBio"
            label="Publish updated coach's bio during the client registration process"
          />
        )}
      </div>
    </LbBigModal>
  );
});

export { CoachBioModal };
