import { CoachInfoFormValue, CoachData } from '../../../../interfaces/CoachData';

export const getCoachInfoFormDefaultValues = (
  coachInfo: CoachData,
): CoachInfoFormValue => {
  const { firstName, lastName, email, additional } = coachInfo;

  return {
    firstName,
    lastName,
    email,
    additional: {
      cellPhone: additional.cellPhone,
      jobTitle: additional.jobTitle,
      address1: additional.address1,
      address2: additional.address2 || '',
      city: additional.city,
      state: additional.state,
      zip: additional.zip,
      commissionSetUp: additional.commissionSetUp,
      commissionRenew: additional.commissionRenew,
      facebook: additional.facebook || '',
      instagram: additional.instagram || '',
      ableToGiveDiscount: additional.ableToGiveDiscount,
      setupDiscountPercent: additional.setupDiscountPercent,
      membershipDiscountPercent: additional.membershipDiscountPercent,
      renewalDiscountPercent: additional.renewalDiscountPercent,
      gender: additional.gender || '',
      dateOfBirth: additional.dateOfBirth,
      consultationType: additional.consultationType,
    },
  };
};
