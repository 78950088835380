import React from 'react';

import classes from './ChipLabel.module.scss';
import { InfoTooltip } from '../../../../../../shared/components/ui-components';

type ChipLabelProps = {
  label: string;
};

const ChipLabel = ({ label }: ChipLabelProps) => {
  return (
    <div className={classes.chipLabel}>
      <span>{label}</span>
      <InfoTooltip placement="right" color="inherit">
        Only a superadmin can <br /> publish a coach&apos;s bio
      </InfoTooltip>
    </div>
  );
};

export { ChipLabel };
