import React from 'react';

import { Button } from '@mui/material';

import { PageTitle } from '../../../../../../shared/components/ui-components';

type SchoolTitleProps = {
  numberOfSchools: number;
  onShowModalSchool: () => void;
};

const SchoolTitle = ({ numberOfSchools, onShowModalSchool }: SchoolTitleProps) => {
  return (
    <PageTitle title={`School/Team/Organization (${numberOfSchools})`} customTopMargin>
      <Button variant="contained" onClick={onShowModalSchool}>
        Add New School/Team/Organization
      </Button>
    </PageTitle>
  );
};

export { SchoolTitle };
