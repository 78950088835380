import React from 'react';

import { angularize } from 'react-in-angularjs';

import classes from './NgzSchoolListTab.module.scss';
import { SystemProviders } from '../../../../../shared/providers';
import { SchoolTitle } from './SchoolTitle/SchoolTitle';
import { SchoolHeaderRow } from './SchoolHeaderRow/SchoolHeaderRow';
import { LbModal } from '../../../../../shared/components/modals';
import { LbInput } from '../../../../../shared/components/form-components';
import { SchoolInfoRow } from './SchoolInfoRow/SchoolInfoRow';
import { useSchools } from './hooks/useSchools';
import { useSort } from '../../../../../shared/hooks/useSort';

type NgzSchoolListTabProps = {
  entityId: number;
};

const NgzSchoolListTab = ({ entityId }: NgzSchoolListTabProps) => {
  const {
    schoolsList,
    isOpenModalSchool,
    editSchoolId,
    control,
    handleFormSubmit,
    handleSchoolActiveStatusChange,
    handleCloseModalSchool,
    openAddNewSchoolModal,
    openEditSchoolModal,
    validateIsSchoolAlreadyExist,
  } = useSchools({ entityId });

  const { sortedData: sortedSchools, sortMethods } = useSort({
    data: schoolsList,
    sortTypeConfig: { name: 'STRING', amountOfCustomers: 'NUMBER' },
  });

  return (
    <SystemProviders>
      <SchoolTitle
        numberOfSchools={sortedSchools.length}
        onShowModalSchool={openAddNewSchoolModal}
      />
      <div className={classes.schoolsTable}>
        <SchoolHeaderRow sortMethods={sortMethods} />
        {sortedSchools.map((school) => (
          <SchoolInfoRow
            onActivationStatusChange={handleSchoolActiveStatusChange}
            onEdit={openEditSchoolModal}
            schoolInfo={school}
            key={school.id}
          />
        ))}
      </div>
      <LbModal
        open={isOpenModalSchool}
        title={
          editSchoolId
            ? 'Edit School/Team/Organization'
            : 'Add New School/Team/Organization'
        }
        primaryBtnConfig={{ text: 'Confirm', onClick: handleFormSubmit }}
        secondaryBtnConfig={{ onClick: handleCloseModalSchool }}
      >
        <LbInput
          name="name"
          label="School/Team/Organization Name"
          control={control}
          rules={{
            required: 'School/Team/Organization Name is required',
            validate: validateIsSchoolAlreadyExist,
          }}
        />
      </LbModal>
    </SystemProviders>
  );
};

angularize(NgzSchoolListTab, 'ngzSchoolListTab', angular.module('app'), {
  entityId: '<',
});
