import React from 'react';

import { TextFieldProps } from '@mui/material';
import { AdapterMoment as DateAdapter } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { useController, UseControllerProps, FieldValues, Path } from 'react-hook-form';
import { Moment } from 'moment';

import {
  DatePikerMode,
  addDataIsValidRule,
  getAvailableDatesValidation,
  getDatePickerValue,
  getDefaultValue,
  getFormValue,
  iconComponents,
  modeSettings,
} from './LbDatePicker.settings';

export type LbDatePickerProps<T extends FieldValues, TName extends Path<T>> = {
  format?: string;
  defaultToday?: boolean;
  defaultValue?: Moment | string;
  mode?: DatePikerMode;
  availableDates?: string[];
} & Omit<UseControllerProps<T, TName>, 'defaultValue'> &
  Pick<
    TextFieldProps,
    | 'label'
    | 'variant'
    | 'size'
    | 'helperText'
    | 'className'
    | 'placeholder'
    | 'fullWidth'
    | 'margin'
    | 'InputProps'
  > &
  Pick<
    DatePickerProps<Moment>,
    'disablePast' | 'disableFuture' | 'minDate' | 'maxDate' | 'openTo'
  >;

const LbDatePicker = <T extends FieldValues, TName extends Path<T>>({
  name,
  rules,
  control,
  label,
  defaultValue,
  defaultToday,
  fullWidth = true,
  format,
  disablePast,
  disableFuture,
  minDate,
  maxDate,
  className,
  helperText,
  size,
  placeholder,
  variant,
  margin,
  openTo,
  mode = 'day',
  availableDates,
  InputProps,
}: LbDatePickerProps<T, TName>) => {
  const pickerSettings = modeSettings[mode];
  const formFormat = format || pickerSettings.formFormat;

  const {
    field: { value, onChange, onBlur, ref },
    fieldState: { error },
  } = useController<any>({
    control,
    name,
    rules: addDataIsValidRule<T, TName>(rules),
    defaultValue: getDefaultValue({ defaultValue, format: formFormat, defaultToday }),
  });

  const handlerChange: DatePickerProps<Moment>['onChange'] = (date, context) => {
    const formValue = getFormValue(date, formFormat, context?.validationError);

    onChange(formValue);
  };

  const isRequired = !!rules?.required;

  return (
    <LocalizationProvider dateAdapter={DateAdapter}>
      <DatePicker
        views={pickerSettings.views}
        format={pickerSettings.viewFormat}
        onClose={onBlur}
        value={getDatePickerValue(value, formFormat)}
        onChange={handlerChange}
        disablePast={disablePast}
        disableFuture={disableFuture}
        minDate={minDate}
        maxDate={maxDate}
        showDaysOutsideCurrentMonth
        label={label}
        openTo={openTo || pickerSettings.openTo}
        inputRef={ref}
        className={className}
        {...getAvailableDatesValidation(availableDates)}
        slots={iconComponents}
        slotProps={{
          textField: {
            variant,
            onBlur,
            error: !!error,
            helperText: error?.message || helperText,
            fullWidth,
            size,
            placeholder: placeholder || pickerSettings.placeholder,
            required: isRequired,
            margin,
            InputProps,
          },
        }}
      />
    </LocalizationProvider>
  );
};

export { LbDatePicker };
