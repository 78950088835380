import React from 'react';

import { useForm } from 'react-hook-form';
import { observer } from 'mobx-react';

import { CoachBio, CoachData, CoachInfoFormValue } from '../../../interfaces/CoachData';
import { PaymentFlowTypeFlags } from '../../../../shared/constants/user-info/paymentFlowType';
import { loggedInUserDataStore } from '../../../../shared/stores/loggedInUserDataStore';
import { BioFormSection } from './BioFormSection/BioFormSection';
import { CoachInfoFormSection } from './CoachInfoFormSection/CoachInfoFormSection';
import { AddressFormSection } from './AddressFormSection/AddressFormSection';
import { DiscountFormSection } from './DiscountFormSection/DiscountFormSection';
import { getCoachInfoFormDefaultValues } from './utils/getCoachInfoFormDefaultValues';

export type CoachInfoFormProps = {
  formId: string;
  coachInfo: CoachData;
  paymentFlowType: PaymentFlowTypeFlags;
  onSubmit: (data: CoachInfoFormValue) => void;
  onUpdateCoachBio: (data: CoachBio) => void;
};

const CoachInfoForm = observer(
  ({
    formId,
    coachInfo,
    paymentFlowType,
    onSubmit,
    onUpdateCoachBio,
  }: CoachInfoFormProps) => {
    const { isCoach } = loggedInUserDataStore;

    const { control, handleSubmit, watch } = useForm<CoachInfoFormValue>({
      defaultValues: getCoachInfoFormDefaultValues(coachInfo),
      mode: 'onBlur',
      shouldUnregister: true,
    });

    // We have an issue when using useWatch directly in the BioFormSection component
    // In this case consultationType is become undefined after few renders
    // So we need to use watch here and pass it as a prop to the BioFormSection component
    // It is related to the shouldUnregister issues which we was facing before
    const consultationType = watch('additional.consultationType');

    return (
      <form onSubmit={handleSubmit(onSubmit)} noValidate id={formId}>
        <CoachInfoFormSection control={control} paymentFlowType={paymentFlowType} />
        <AddressFormSection control={control} />
        {!paymentFlowType.NEW_USER_FLOW_2024 &&
          !coachInfo.additional.complianceCoach &&
          !isCoach && <DiscountFormSection control={control} />}
        {paymentFlowType.NEW_USER_FLOW_2024 && (
          <BioFormSection
            control={control}
            coachId={coachInfo.id}
            consultationType={consultationType}
            coachBio={{
              bio: coachInfo.additional.bio,
              publishBio: coachInfo.additional.publishBio,
            }}
            onUpdateCoachBio={onUpdateCoachBio}
          />
        )}
      </form>
    );
  },
);

export { CoachInfoForm };
