import { useEffect, useState } from 'react';

import { Control, useForm } from 'react-hook-form';
import { useToggle } from 'react-use';

import { updateItemByField } from '../../../../../../shared/utils/arrayUtils';
import { SchoolInfo } from '../../../../../../shared/interfaces/school/SchoolInfo';
import {
  AddSchool,
  schoolsService,
} from '../../../../../../shared/services/schools/schoolsService';

type UseSchoolsReturn = {
  schoolsList: SchoolInfo[];
  isOpenModalSchool: boolean;
  editSchoolId: number | null;
  control: Control<AddSchool>;
  handleFormSubmit: () => void;
  onSubmit: (data: AddSchool) => void;
  openAddNewSchoolModal: () => void;
  openEditSchoolModal: (schoolInfo: SchoolInfo) => void;
  handleCloseModalSchool: () => void;
  handleSchoolActiveStatusChange: (schoolId: number, status: boolean) => void;
  validateIsSchoolAlreadyExist: (newAffiliateName: string) => boolean | string;
};

type UseSchoolsProps = {
  entityId: number;
};

export const useSchools = ({ entityId }: UseSchoolsProps): UseSchoolsReturn => {
  const [schoolsList, setSchoolsList] = useState<SchoolInfo[]>([]);
  const [isOpenModalSchool, toggleOpenModalSchool] = useToggle(false);
  const [editSchoolId, setEditSchoolId] = useState<number | null>(null);

  const { control, handleSubmit, setValue } = useForm<AddSchool>({
    shouldUnregister: true,
  });

  const onSubmit = async (schoolInfo: AddSchool) => {
    if (editSchoolId) {
      editSchool(schoolInfo);
    } else {
      addNewSchool(schoolInfo);
    }

    handleCloseModalSchool();
  };

  const addNewSchool = async (schoolInfo: AddSchool) => {
    const newSchool = await schoolsService.addNewSchool(entityId, schoolInfo);

    setSchoolsList([newSchool, ...schoolsList]);
  };

  const editSchool = async (schoolInfo: AddSchool) => {
    const editedSchoolInfo = await schoolsService.editSchool(
      entityId,
      editSchoolId!,
      schoolInfo,
    );

    updateSchool(editedSchoolInfo);
  };

  const handleSchoolActiveStatusChange = async (schoolId: number, status: boolean) => {
    const response = await schoolsService.changeSchoolStatus(entityId, schoolId, status);

    updateSchool(response);
  };

  const updateSchool = (newSchoolInfo: SchoolInfo) => {
    const editedSchoolList = updateItemByField(schoolsList, newSchoolInfo, {
      id: newSchoolInfo.id,
    });

    setSchoolsList(editedSchoolList);
  };

  const openAddNewSchoolModal = () => {
    toggleOpenModalSchool();
  };

  const openEditSchoolModal = (schoolInfo: SchoolInfo) => {
    const { id, name } = schoolInfo;

    setEditSchoolId(id);
    setValue('name', name);
    toggleOpenModalSchool();
  };

  const handleCloseModalSchool = () => {
    setEditSchoolId(null);
    toggleOpenModalSchool();
  };

  const getSchoolsList = async () => {
    const { customerSchoolGroups } = await schoolsService.getSchoolsList(entityId);

    setSchoolsList(customerSchoolGroups);
  };

  const validateIsSchoolAlreadyExist = (newAffiliateName: string) => {
    const isExist = schoolsList.some((affiliate) => affiliate.name === newAffiliateName);

    return !isExist || 'School already exist';
  };

  useEffect(() => {
    getSchoolsList();
  }, []);

  return {
    schoolsList,
    isOpenModalSchool,
    editSchoolId,
    control,
    handleFormSubmit: handleSubmit(onSubmit),
    onSubmit,
    openAddNewSchoolModal,
    openEditSchoolModal,
    handleCloseModalSchool,
    handleSchoolActiveStatusChange,
    validateIsSchoolAlreadyExist,
  };
};
