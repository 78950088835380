import React from 'react';

import { angularize } from 'react-in-angularjs';

import { SystemProviders } from '../../../shared/providers';
import { CustomerInbodyAccountCard } from './CustomerInbodyAccountCard/CustomerInbodyAccountCard';
import { useCustomerInbodyAccounts } from './hooks/useCustomerInbodyAccounts';

type NgzCustomerInbodyAccountsWidgetProps = {
  customerId: string;
};

const NgzCustomerInbodyAccountsWidget = ({
  customerId,
}: NgzCustomerInbodyAccountsWidgetProps) => {
  const {
    inbodyAccounts,
    handleRefreshInbodyAccountData,
    handleRequestInbodyAccountAccess,
  } = useCustomerInbodyAccounts(customerId);

  return (
    <SystemProviders>
      {inbodyAccounts.map((inbodyAccount) => (
        <CustomerInbodyAccountCard
          key={inbodyAccount.inbodyKeyId}
          inbodyAccount={inbodyAccount}
          onRefreshInbodyAccountData={handleRefreshInbodyAccountData}
          onRequestInbodyAccountAccess={handleRequestInbodyAccountAccess}
        />
      ))}
    </SystemProviders>
  );
};

angularize(
  NgzCustomerInbodyAccountsWidget,
  'ngzCustomerInbodyAccountsWidget',
  angular.module('app'),
  { customerId: '<' },
);

export { NgzCustomerInbodyAccountsWidget };
