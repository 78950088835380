import React from 'react';

import { Button } from '@mui/material';
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';
import classNames from 'classnames';

import classes from './H20bScaleBlock.module.scss';
import H20BInbodyImg from '../../../../../shared/assets/image/h20b-inbody.png';
import { getPriceView } from '../../../../../shared/utils/stringUtils';
import {
  H20B_BUY_URL,
  H20B_INFO_URL,
  H20B_PRICE,
} from '../../../../../shared/constants/inbody-info/h20bInfo';
import {
  CONSULTATION_TYPE,
  ConsultationType,
} from '../../../../../shared/constants/coach/consultationType';
import { getVariantConfig } from './utils/getVariantConfig';

type H20bScaleBlockProps = {
  className?: string;
  consultationType: ConsultationType;
};

const H20bScaleBlock = ({
  className,
  consultationType = CONSULTATION_TYPE.OFFLINE,
}: H20bScaleBlockProps) => {
  const { chip, title, description } = getVariantConfig(consultationType);

  return (
    <div className={classNames(classes.container, className)}>
      <div className={classes.titleBlock}>
        <div className={classes.chip}>{chip}</div>
        <h3 className={classes.title}>InBody H20B Scale</h3>
      </div>
      <img src={H20BInbodyImg} alt="InBody H20B Scale" className={classes.scaleImage} />
      <div className={classes.infoBox}>
        <h4>{title}</h4>
        <p>{description}</p>
        <Button
          variant="text"
          size="small"
          color="primary"
          endIcon={<OpenInNewRoundedIcon />}
          href={H20B_INFO_URL}
          target="_blank"
        >
          Learn more info
        </Button>
      </div>

      <div className={classes.priceBox}>
        <span className={classes.initialPrice}>{getPriceView(H20B_PRICE.INITIAL)}</span>
        <span className={classes.discountPrice}>{getPriceView(H20B_PRICE.DISCOUNT)}</span>
      </div>

      <Button
        variant="contained"
        size="medium"
        endIcon={<OpenInNewRoundedIcon />}
        fullWidth
        href={H20B_BUY_URL}
      >
        Buy it
      </Button>
    </div>
  );
};

export { H20bScaleBlock };
