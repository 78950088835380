import { useEffect, useState } from 'react';
import { SchoolInfo } from '../../../../../../shared/interfaces/school/SchoolInfo';
import { schoolsService } from '../../../../../../shared/services/schools/schoolsService';

export const useAffiliateList = (entityId: number) => {
  const [affiliateList, setAffiliateList] = useState<SchoolInfo[]>([]);

  const getAffiliateList = async () => {
    const { customerSchoolGroups } =
      await schoolsService.getPublicEntitySchools(entityId);
    setAffiliateList(customerSchoolGroups);
  };

  useEffect(() => {
    getAffiliateList();
  }, []);

  return { affiliateList };
};
