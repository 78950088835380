import React from 'react';

import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import RestoreFromTrashRoundedIcon from '@mui/icons-material/RestoreFromTrashRounded';

import { Status } from '../../../../../shared/components/ui-components';

type StatusInfoProps = {
  isActive: boolean;
};

const StatusInfo = ({ isActive }: StatusInfoProps) => {
  return isActive ? (
    <Status icon={<CheckCircleRoundedIcon fontSize="small" color="success" />}>
      Active
    </Status>
  ) : (
    <Status icon={<RestoreFromTrashRoundedIcon fontSize="small" color="tertiary" />}>
      Deactivated
    </Status>
  );
};

export { StatusInfo };
