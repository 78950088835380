import React from 'react';

import { angularize } from 'react-in-angularjs';
import { observer } from 'mobx-react';

import classes from './NgzCoachInfoTab.module.scss';
import { SystemProviders } from '../../../shared/providers';
import { CoachInfoForm } from './CoachInfoForm/CoachInfoForm';
import { CoachBio, CoachData, CoachInfoFormValue } from '../../interfaces/CoachData';
import { PAYMENT_FLOW_TYPE } from '../../../shared/constants/user-info/paymentFlowType';
import { getFlagsObject } from '../../../shared/utils/sortingAndGroupingUtil';
import { YesNoBtnRow } from '../../../shared/components/buttons/YesNoBtnRow/YesNoBtnRow';
import { CoachStatusToggle } from './CoachStatusToggle/CoachStatusToggle';
import { loggedInUserDataStore } from '../../../shared/stores/loggedInUserDataStore';

type NgzCoachInfoTabProps = {
  coachInfo: CoachData;
  onCoachUpdate: ({ data }: { data: CoachInfoFormValue }) => void;
  onActivationChange: () => void;
  onUpdateCoachBio: ({ bio }: { bio: CoachBio }) => void;
};

const CoachInfoFormId = 'coach-info-form';

const NgzCoachInfoTab = observer(
  ({
    coachInfo,
    onCoachUpdate,
    onActivationChange,
    onUpdateCoachBio,
  }: NgzCoachInfoTabProps) => {
    const { isCoach } = loggedInUserDataStore;

    if (!coachInfo.id) {
      return null;
    }

    const paymentFlowType = getFlagsObject(PAYMENT_FLOW_TYPE, [
      coachInfo.entity.paymentFlowType,
    ]);

    return (
      <SystemProviders>
        <CoachInfoForm
          formId={CoachInfoFormId}
          coachInfo={coachInfo}
          paymentFlowType={paymentFlowType}
          onSubmit={(data) => onCoachUpdate({ data })}
          onUpdateCoachBio={(data) => onUpdateCoachBio({ bio: data })}
        />
        {!isCoach && (
          <CoachStatusToggle
            active={coachInfo.active}
            coachName={`${coachInfo.firstName} ${coachInfo.lastName}`}
            onActivationChange={onActivationChange}
          />
        )}
        <YesNoBtnRow
          formId={CoachInfoFormId}
          className={classes.submitButton}
          yesText="Save"
        />
      </SystemProviders>
    );
  },
);

export { NgzCoachInfoTab };

angularize(NgzCoachInfoTab, 'ngzCoachInfoTab', angular.module('app.authManagement'), {
  coachInfo: '<',
  onCoachUpdate: '&',
  onActivationChange: '&',
  onUpdateCoachBio: '&',
});
