import React, { ReactNode } from 'react';

import classNames from 'classnames';

import classes from './TitleWithDescription.module.scss';

export type TitleWithDescriptionProps = {
  title: ReactNode;
  titleFont?: 'body-14-bold' | 'title-16-bold' | 'title-22-bold' | 'inherit';
  description?: ReactNode;
  descriptionColor?: 'natural-600' | 'main-black';
  withoutGap?: boolean;
  direction?: 'row' | 'column';
  className?: string;
  fullWidth?: boolean;
};

const TitleWithDescription = ({
  title,
  titleFont = 'title-16-bold',
  description,
  descriptionColor = 'natural-600',
  withoutGap,
  direction = 'column',
  className,
  fullWidth,
}: TitleWithDescriptionProps) => {
  const boxClasses = classNames(classes.box, className, classes[direction], {
    [classes.gap]: !withoutGap,
    [classes.fullWidth]: fullWidth,
  });

  const descriptionClasses = classNames(classes.description, classes[descriptionColor]);

  return (
    <div className={boxClasses}>
      <h4 className={classes[titleFont]}>{title}</h4>
      {description && <div className={descriptionClasses}>{description}</div>}
    </div>
  );
};

export { TitleWithDescription };
