import React from 'react';

import { Control, useWatch } from 'react-hook-form';

import { UserProfileFromValue } from '../UserProfileStep.settings';
import {
  ComboBox,
  InputBox,
  LbInput,
  LbSelect,
  SelectSportPickers,
} from '../../../../../../shared/components/form-components';
import {
  CUSTOMER_SPORT_TYPE,
  customerSportTypeOptions,
} from '../../../../../constants/customerSportType';
import { useAdditionalInfoOptions } from '../../../../../hooks/useAdditionalInfoOptions';
import { transformToOptions } from '../../../../../../shared/utils/arrayUtils';
import {
  isDateLessMinAge,
  validateEmail,
} from '../../../../../../shared/utils/reactHookFormUtils';

type AdditionalInfoFormProps = {
  control: Control<UserProfileFromValue>;
  entityId: number;
  defaultSchoolId?: number;
};

const AdditionalInfoForm = ({
  control,
  entityId,
  defaultSchoolId,
}: AdditionalInfoFormProps) => {
  const generalInputProps = {
    control,
    size: 'small',
    variant: 'standard',
  } as const;

  const [dateOfBirth, sportType] = useWatch({
    control,
    name: ['customer.dob', 'additionallyInfo.sportType'],
  });

  const { schools, sports } = useAdditionalInfoOptions(entityId, defaultSchoolId);

  return (
    <InputBox title="Additionally">
      <LbSelect
        {...generalInputProps}
        options={customerSportTypeOptions}
        name="additionallyInfo.sportType"
        label="Type"
        rules={{
          required: 'Type is required',
        }}
      />
      {sportType === CUSTOMER_SPORT_TYPE.ATHLETE_SCHOOL && (
        <>
          <ComboBox
            {...generalInputProps}
            name="additionallyInfo.schoolId"
            label="School/Team/Organization"
            rules={{ required: 'School/Team/Organization is required' }}
            options={transformToOptions({
              list: schools || [],
              valueKey: 'id',
              labelKey: 'name',
            })}
          />
          <LbInput
            {...generalInputProps}
            name="additionallyInfo.parentEmail"
            label="Parent/Guardian email"
            rules={{
              required: isDateLessMinAge(dateOfBirth, 18)
                ? false
                : 'Parent/Guardian email is required',
              validate: { validateEmail },
            }}
          />
          <SelectSportPickers
            control={control}
            sports={sports}
            sportFieldProps={{
              name: 'additionallyInfo.sport',
              rules: { required: 'Sport is required' },
            }}
            sportPositionFieldProps={{
              name: 'additionallyInfo.sportPosition',
              rules: { required: 'Position is required' },
            }}
          />
        </>
      )}
    </InputBox>
  );
};

export { AdditionalInfoForm };
