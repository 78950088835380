'use strict';

angular.module('app').controller('CustomerSetupStep5Controller', CustomerSetupStep5Controller);

CustomerSetupStep5Controller.$inject = [
    '$scope',
    '$rootScope',
    'utilService',
    'ApiService',
    'GeneralService',
    'PaymentService',
    'CustomerService',
    'CorporateUsersService',
    'CustomerGroupsService',
    'CoachService',
    'DateTimeUtil',
    'GoalService',
    'ModalService',
    'constants'
];

function CustomerSetupStep5Controller($scope, $rootScope, utilService, ApiService, GeneralService, PaymentService,
                                      CustomerService, CorporateUsersService, CustomerGroupsService, CoachService,
                                      DateTimeUtil, GoalService, ModalService, constants) {
    var vm = this;
    vm.paymentFlowType = {};
    vm.isStripeUsed = false;

    vm.createUser = $scope.createUser;
    vm.userGoal = $scope.userGoal;
    vm.spinner = $scope.spinner;
    vm.userGroup = $scope.userGroup;
    vm.user = $scope.user;
    vm.inbodyTypes = constants.inbodyTypes;
    vm.currentSubscriptionType = {};

    vm.loggedInUser = utilService.getLoggedInUserData();
    vm.selectedSubscription = null;
    vm.initialSubscriptionsDetails = [];
    vm.selectedSubscriptionIndex = -1;
    vm.currentCoach = {};

    vm.inbodyDataList = angular.copy($scope.inbodyDataList);
    vm.inbodyDataList.bodyFatMass = vm.inbodyDataList.bodyFatMass || 0;
    vm.inbodyDataList.leanBodyMass = vm.inbodyDataList.leanBodyMass || 0;
    vm.inbodyDataList.totalBodyWatter = vm.inbodyDataList.totalBodyWatter || 0;
    vm.inbodyDataList.ecwtbw = vm.inbodyDataList.ecwtbw || 0;

    vm.init = init;
    vm.getNumberOfCorporateUsers = getNumberOfCorporateUsers;
    vm.getEntityInfo = getEntityInfo;
    vm.processPrioritySubscription = processPrioritySubscription;
    vm.prepareSubscriptions = prepareSubscriptions;
    vm.filterSubscriptionsByPreSelected = filterSubscriptionsByPreSelected;
    vm.preSelectSubscription = preSelectSubscription;
    vm.getSubscriptionsWithCouchDiscount = getSubscriptionsWithCouchDiscount;
    vm.getMaxValue = getMaxValue;
    vm.getPaymentPublicKey = getPaymentPublicKey;
    vm.assignGroup = assignGroup;
    vm.saveInitialGoal = saveInitialGoal;
    vm.payCustomerSubscription = payCustomerSubscription;
    vm.createInitialScan = createInitialScan;
    vm.createUserPay = createUserPay;
    vm.changeCorporateSubscriptionState = changeCorporateSubscriptionState;
    vm.isUserSetupAvailable = isUserSetupAvailable;
    vm.selectSubscription = selectSubscription;
    vm.showDialog = showDialog;
    vm.afterUserSetup = afterUserSetup;
    vm.checkSubscriptions = checkSubscriptions;
    vm.goToNextStep = goToNextStep;
    vm.openHandler = openHandler;
    vm.processNewFlow = processNewFlow;
    vm.processForPreCheckIn = processForPreCheckIn;
    vm.proceedWithCreatingPaymentIntent = proceedWithCreatingPaymentIntent;
    vm.proceedWithManualCreatingTransaction = proceedWithManualCreatingTransaction;
    vm.createPaymentIntent = createPaymentIntent;
    vm.openHandlerNewFlow = openHandlerNewFlow;
    vm.openHandlerOldFlow = openHandlerOldFlow;
    vm.getPaymentBody = getPaymentBody;
    vm.createManualTransaction = createManualTransaction;
    vm.createFreeTrial = createFreeTrial;
    vm.createFreeTrialNewFlow = createFreeTrialNewFlow;
    vm.createFreeTrialOldFlow = createFreeTrialOldFlow;
    vm.confirmFreeTrial = confirmFreeTrial;

    vm.init();

    function init() {
        // set value for vm.paymentFlowType and vm.isStripeUsed, need for correct work runFuncDependOnFlow
        PaymentService.getPaymentFlowTypeAndIsStripeUsed(vm);
        vm.getNumberOfCorporateUsers();
        vm.getSubscriptionsWithCouchDiscount();
        vm.getPaymentPublicKey();
    }

    function checkSubscriptions() {
        return vm.gymDetails.subscriptions.filter(function (value) {
            return value.active && value.subscriptionType === vm.currentSubscriptionType.value;
        }).length > 0;
    }

    function getNumberOfCorporateUsers() {
        CorporateUsersService.getNumberOfCorporateUsers().then(function (res) {
            if (res.status === 200) {
                vm.corporateAccounts = res.data.corporateAccounts;
            }
        });
    }

    function getSubscriptionsWithCouchDiscount() {
        vm.createUser.customer.corporateSubscription = false;
        vm.spinner.active = true;
        CoachService.getCoachAccountSettings(vm.loggedInUser.id).then(function (coach) {
            vm.spinner.active = false;
            if (coach.status === 200) {
                vm.currentCoach = coach.data;
                vm.getEntityInfo();
            }
        });
    }

    function getEntityInfo() {
        vm.spinner.active = true;
        CoachService.getCurrentEntityInfo(null, vm.createUser.user.email).then(function (results) {
            vm.spinner.active = false;
            if (results.status === 200) {
                for (var key in results.data.subscriptions) {
                    var subs = results.data.subscriptions[key];

                    subs.minSetupValue = vm.getMaxValue(subs.minChargeSetup, subs.setUpEntityPrice, vm.currentCoach.additional.setupDiscountPercent) / 100;
                    subs.minInitValue = vm.getMaxValue(subs.minChargeInit, subs.initInvoiceEntityPrice, vm.currentCoach.additional.membershipDiscountPercent) / 100;
                    subs.minRenewalValue = vm.getMaxValue(subs.minChargeRenew, subs.renewFeeEntityPrice, vm.currentCoach.additional.renewalDiscountPercent) / 100;

                    subs.setUpEntityPrice = subs.setUpEntityPrice / 100;
                    subs.setUpEkinPrice = subs.setUpEkinPrice / 100;
                    subs.initInvoiceEntityPrice = subs.initInvoiceEntityPrice / 100;
                    subs.initInvoiceEkinPrice = subs.initInvoiceEkinPrice / 100;
                    subs.renewFeeEntityPrice = subs.renewFeeEntityPrice / 100;
                    subs.renewFeeEkinPrice = subs.renewFeeEkinPrice / 100;
                    subs.minChargeSetup = subs.minChargeSetup / 100;
                    subs.minChargeInit = subs.minChargeInit / 100;
                    subs.minChargeRenew = subs.minChargeRenew / 100;
                }

                vm.gymDetails = results.data;
                const subscriptions = vm.gymDetails.subscriptions;
                const customerInbodyType = vm.createUser.customer.inbodyType;
                vm.gymDetails.subscriptions = CustomerService.filterSubscriptions(subscriptions, customerInbodyType);

                vm.currentSubscriptionType = vm.createUser.customer.inbodyType === vm.inbodyTypes.NON_INBODY.value ?
                    {name: 'Non InBody', value: 'NON_INBODY'} : {name: 'Nutrition', value: 'NUTRITION'};

                vm.processPrioritySubscription();

                vm.initialSubscriptionsDetails = [...vm.gymDetails.subscriptions];
            }
        });
    }

    function processPrioritySubscription() {
        if (vm.gymDetails.prioritySubscriptionId) {
            vm.prepareSubscriptions();
        }
    }

    function prepareSubscriptions() {
        vm.filterSubscriptionsByPreSelected();

        if (vm.gymDetails.subscriptions.length) {
            vm.preSelectSubscription();
        }
    }

    function filterSubscriptionsByPreSelected() {
        vm.gymDetails.subscriptions = vm.gymDetails.subscriptions.filter(subscription => {
            return subscription.id === vm.gymDetails.prioritySubscriptionId;
        });
    }

    function preSelectSubscription() {
        vm.selectedSubscriptionIndex = 0;
        vm.selectedSubscription = vm.gymDetails.subscriptions[vm.selectedSubscriptionIndex];
    }

    function getPaymentPublicKey() {
        GeneralService.getStripePublicKey().then(function (res) {
            if (res.status === 200 && res.data.key) {
                vm.handler = StripeCheckout.configure({
                    key: res.data.key,
                    image: 'https://content.lifebase.solutions/static-content/logo.png',
                    locale: 'auto',
                    token: vm.payCustomerSubscription
                });
            }
        });
    }

    function getMaxValue(minFee, fee, percent) {
        var feeWithPercent = fee * percent / 100;

        if (minFee > fee - feeWithPercent) return Math.ceil(minFee);
        else return Math.ceil(fee - feeWithPercent);
    }

    function afterUserSetup(userid) {
        vm.newCustomerId = userid;
        if (vm.userGroup.name === '') {
            vm.saveInitialGoal(vm.newCustomerId)
        } else if (vm.userGroup.id) {
            vm.assignGroup(vm.userGroup.id, vm.newCustomerId);
        } else if (vm.userGroup.name) {
            vm.spinner.active = true;
            CustomerGroupsService.createCustomerGroup({name: vm.userGroup.name, active: true}).then(function (result) {
                vm.spinner.active = false;
                if (result.status === 200) {
                    vm.userGroup.id = result.data.id;
                    vm.assignGroup(vm.userGroup.id, vm.newCustomerId);
                }
            });
        }
    }

    function assignGroup(groupId, userid) {
        vm.spinner.active = true;
        CustomerGroupsService.assignCustomersToCustomerGroup(groupId, {ids: [userid]}).then(function (result) {
            vm.spinner.active = false;
            if (result.status === 200) {
                vm.saveInitialGoal(userid);
            }
        });
    }

    function saveInitialGoal(id) {
        vm.spinner.active = true;
        GoalService.addGoal(id, {
            name: vm.userGoal.goal.tag,
            startDate: DateTimeUtil.formatDate(DateTimeUtil.now(), 'MM.DD.YYYY'),
            targetDate: DateTimeUtil.formatDate(vm.userGoal.targetDate, 'MM.DD.YYYY'),
            targetWeight: vm.userGoal.weight,
            targetBodyFatPercent: vm.userGoal.bodyFat
        }, !CustomerService.checkInbodyData(vm.inbodyDataList) || vm.createUser.customer.inbodyType === constants.inbodyTypes.NON_INBODY.value ?
            {allowToCreateWithoutScans: true} : null).then(function (res) {
            vm.spinner.active = false;
            if (res.status === 200) {
                vm.createUser.customer.corporateSubscription ? vm.goToNextStep() : vm.openHandler();
            }
        });
    }

    function openHandler() {
        if (!vm.paymentFlowType.OLD) {
           vm.processNewFlow();
        } else {
            vm.openHandlerOldFlow();
        }
    }

    function processNewFlow() {
        if (vm.createUser.isPreCheckInCustomer) {
            vm.processForPreCheckIn();
        } else {
            vm.openHandlerNewFlow();
        }
    }

    async function processForPreCheckIn() {
        const isNeedStripe = PaymentService.getIsNeedStripePayForSubscription(vm.selectedSubscription.subscriptionType);

        if (vm.createUser.paymentMethodId && isNeedStripe) {
            await vm.proceedWithCreatingPaymentIntent();
        } else {
            vm.proceedWithManualCreatingTransaction();
        }
    }

    async function proceedWithCreatingPaymentIntent() {
        const createPaymentIntentResp = await vm.createPaymentIntent();

        if (createPaymentIntentResp.status === 200) {
            vm.goToNextStep();
        }
    }

    function proceedWithManualCreatingTransaction() {
        vm.createManualTransaction();
        vm.goToNextStep();
    }

    async function createPaymentIntent() {
        const body = {
            customerId: vm.user.id,
            initialPrice: vm.selectedSubscription.initInvoiceEntityPrice,
            paymentMethod: vm.createUser.paymentMethodId,
            renewalPrice: vm.selectedSubscription.renewFeeEntityPrice,
            subscriptionId: vm.selectedSubscription.id,
        };
        vm.spinner.active = true;

        try {
            return await PaymentService.createPaymentIntent(body);
        } finally {
            vm.spinner.active = false;
            utilService.applyAsyncFunc($scope);
        }
    }

    function openHandlerNewFlow() {
        const isShowStripe = PaymentService.getIsNeedStripePayForSubscription(vm.selectedSubscription.subscriptionType);
        if (isShowStripe) {
            const paymentBody = vm.getPaymentBody();
            ModalService.showStripe(null, vm.goToNextStep, paymentBody);
        } else {
            vm.proceedWithManualCreatingTransaction();
        }
    }

    function openHandlerOldFlow(){
        vm.setUpEntityPrice = Math.round(vm.selectedSubscription.setUpEntityPrice * 100);
        vm.initInvoiceEntityPrice = Math.round(vm.selectedSubscription.initInvoiceEntityPrice * 100);
        vm.renewFeeEntityPrice = Math.round(vm.selectedSubscription.renewFeeEntityPrice * 100);

        vm.handler.open({
            name: vm.selectedSubscription.title,
            description: vm.selectedSubscription.info,
            email: vm.createUser.user.billingEmail,
            amount: vm.setUpEntityPrice + vm.initInvoiceEntityPrice,
            currency: 'usd'
        });
    }

    function getPaymentBody() {
        return {
            subscriptionId: vm.selectedSubscription.id,
            customerId: vm.newCustomerId,
            initialPrice: vm.selectedSubscription.initInvoiceEntityPrice,
            renewalPrice: vm.selectedSubscription.renewFeeEntityPrice,
        };
    }

    function createManualTransaction() {
        const selectedSubscriptionId = vm.gymDetails.subscriptions[vm.selectedSubscriptionIndex].id;
        PaymentService.createManualTransaction(selectedSubscriptionId, vm.newCustomerId);
    }

    function goToNextStep() {
        $rootScope.$broadcast('updateUser');
        $scope.next();
    }

    function payCustomerSubscription(token) {
        vm.spinner.active = true;
        PaymentService.paySubscriptionByUser(vm.newCustomerId, {
            setup_price: vm.setUpEntityPrice,
            init_invoice_price: vm.initInvoiceEntityPrice,
            renew_fee_price: vm.renewFeeEntityPrice,
            token: token.id,
            subscription: vm.selectedSubscription.id
        }).then(function (result) {
            vm.spinner.active = false;
            result.status === 200 ?
                vm.goToNextStep() : vm.confirmFreeTrial(result);
        });
    }

    function confirmFreeTrial(result) {
        ModalService.confirm('Do you want to create a free trial?', vm.createFreeTrial,
            vm.newCustomerId, result ? 'Payment error occurred. ' + result.data.message : '', 'Cancel', 'OK');
    }

    function createFreeTrial() {
        PaymentService.runFuncDependOnFlow(vm.createFreeTrialNewFlow, vm.createFreeTrialOldFlow);
    }

    function createFreeTrialNewFlow() {
        const paymentBody = vm.getPaymentBody();
        vm.spinner.active = true;
        PaymentService.createFreeTrial(paymentBody)
          .then(res => {
              if (res.status === 200) {
                  vm.goToNextStep();
              }
          })
          .finally(() => vm.spinner.active = false);
    }

    function createFreeTrialOldFlow() {
        vm.spinner.active = true;
        CoachService.createFreeTrial(vm.newCustomerId).then(function (result) {
            vm.spinner.active = false;
            if (result.status === 200) {
                vm.goToNextStep();
            }
        })
    }

    function createInitialScan() {
        if (CustomerService.checkInbodyData(vm.inbodyDataList) ||
            vm.createUser.customer.inbodyType === constants.inbodyTypes.NON_INBODY.value) {
            vm.spinner.active = true;
            vm.inbodyDataList.scanType = vm.createUser.customer.inbodyType;
            CustomerService.createScanManually(vm.user.id, angular.copy(vm.inbodyDataList)).then(function (result) {
                vm.spinner.active = false;
                if (result.status === 200) {
                    vm.afterUserSetup(vm.user.id);
                }
            });
        } else {
            CustomerService.getInbodyDataForUser(vm.user.id);
            vm.afterUserSetup(vm.user.id);
        }
    }

    function createUserPay() {
        if (!vm.user.id) {
            vm.spinner.active = true;
            CustomerService.createCustomer(angular.copy(vm.createUser)).then(function (results) {
                vm.spinner.active = false;
                if (results.status === 200) {
                    vm.user.id = results.data.id;
                    vm.createInitialScan();
                }
            });
        } else {
            vm.createInitialScan();
        }
    }

    function changeCorporateSubscriptionState() {
        if (vm.createUser.customer.corporateSubscription) {
            vm.selectedSubscription = null;
            vm.selectedSubscriptionIndex = -1;
        }
    }

    function selectSubscription(index) {
        if (!vm.createUser.customer.corporateSubscription) {
            vm.selectedSubscriptionIndex = index;

            for (var i = 0; i < vm.gymDetails.subscriptions.length; i++) {
                var currentSubscription = vm.gymDetails.subscriptions[i];
                if (i !== index) {
                    currentSubscription.setUpEntityPrice = vm.initialSubscriptionsDetails[i].setUpEntityPrice;
                    currentSubscription.initInvoiceEntityPrice = vm.initialSubscriptionsDetails[i].initInvoiceEntityPrice;
                    currentSubscription.renewFeeEntityPrice = vm.initialSubscriptionsDetails[i].renewFeeEntityPrice;
                }
            }
        }
    }

    function isUserSetupAvailable() {
        return (vm.selectedSubscription === null && !vm.createUser.customer.corporateSubscription) ||
            (vm.createUser.customer.corporateSubscription &&
                (vm.corporateAccounts.used === vm.corporateAccounts.available ||
                    !vm.currentCoach.entity.activeSubscription))
    }

    function showDialog(subscription) {
        ModalService.getSubscriptionInfo(subscription);
    }
}
