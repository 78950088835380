import React from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { observer } from 'mobx-react';

import { FLAGS, useFlag } from '../../../../../clients/unleash';
import { LbInput } from '../../../../../components/form-components';
import {
  Nutrient,
  PersonalInfo,
  Scans,
  SportFilters,
  Score,
  Meal,
  NoFoodTracking,
} from './filter-groups';

import { loggedInUserDataStore } from '../../../../../stores/loggedInUserDataStore';
import { CustomFilterFormValues } from './AddCustomFilterForm.settings';
import { transformCustomFiltersFormValuesToData } from './utils/transformCustomFiltersFormValuesToData';
import { transformCustomFiltersDataToFormValues } from './utils/transformCustomFiltersDataToFormValues';
import { validateIsFilterNameExist } from './utils/validateIsFilterNameExist';
import { customFilterStore } from '../../../stores/customFilterStore';
import { validateWhitespace } from '../../../../../utils/reactHookFormUtils';
import { ModalInputBox } from '../../../../../components/modals';

type AddCustomFilterFormProps = {
  formId: string;
};

const AddCustomFilterForm = observer(({ formId }: AddCustomFilterFormProps) => {
  const { entityType, isSuperAdmin, paymentFlowType } = loggedInUserDataStore;
  const { editedFilterInfo, selectedResourceType, handelSubmitFilter } =
    customFilterStore;

  const formMethod = useForm<CustomFilterFormValues>({
    mode: 'onBlur',
    defaultValues: transformCustomFiltersDataToFormValues(editedFilterInfo),
  });
  const { control, handleSubmit } = formMethod;

  const isShowMealBasedFilters = useFlag(
    FLAGS.SHOW_REACT_CUSTOMER_COMMUNICATION_FILTER_PARAMETERS,
  );

  const prepareDataToSubmit = (data: CustomFilterFormValues) => {
    const preparedData = transformCustomFiltersFormValuesToData(data);
    handelSubmitFilter(preparedData);
  };

  const isShowSportFilter =
    entityType.TEAM ||
    entityType.SCHOOL ||
    paymentFlowType.NEW_USER_FLOW_2024 ||
    (isSuperAdmin && selectedResourceType.CUSTOMERS);

  return (
    <FormProvider {...formMethod}>
      <form id={formId} onSubmit={handleSubmit(prepareDataToSubmit)} noValidate>
        <ModalInputBox>
          <LbInput
            control={control}
            size="small"
            variant="outlined"
            name="name"
            label="Filter name"
            rules={{
              required: 'Filter name is required',
              validate: { validateIsFilterNameExist, validateWhitespace },
            }}
          />
        </ModalInputBox>
        <PersonalInfo />
        <NoFoodTracking />
        <Scans />
        {isShowSportFilter && <SportFilters />}
        {isShowMealBasedFilters && (
          <>
            <Nutrient />
            <Score />
            <Meal />
          </>
        )}
      </form>
    </FormProvider>
  );
});

export { AddCustomFilterForm };
