import React, { useState } from 'react';

import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Button, Checkbox, Divider } from '@mui/material';

import { observer } from 'mobx-react';
import { enqueueSnackbar } from 'notistack';
import classes from './CheckoutForm.module.scss';
import { LbFormControlLabel } from '../../../../../shared/components/form-components';
import { PaymentButton } from './PaymentButton/PaymentButton';
import { paymentElementsOptions } from './CheckoutForm.settings';
import { TermsLinks } from '../../../../../shared/components/TermsLinks/TermsLinks';
import { getPriceView } from '../../../../../shared/utils/stringUtils';
import { getRouteParam } from '../../../../../shared/utils/angularUtils';
import { generatePaymentResultUrl } from '../../../../utils/generatePaymentResultUrl/generatePaymentResultUrl';
import { checkoutStore } from '../../../../stores/checkoutStore';
import { apiStatusStore } from '../../../../../shared/stores/apiStatusStore';
import { checkoutQueryParams } from '../../../../constants/pathQueryParams';

const CheckoutForm = observer(() => {
  const [termsAccepted, setTermsAccepted] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const retryPath = getRouteParam(checkoutQueryParams.retryPath);
  const paymentResultType = getRouteParam(checkoutQueryParams.paymentResultType);
  const consultationType = getRouteParam(checkoutQueryParams.consultationType);

  const { checkoutInfo, subscriptionTypeFlags } = checkoutStore;
  const { loadingStart, loadingEnd } = apiStatusStore;

  if (!checkoutInfo) {
    return null;
  }

  const { paymentIntent, checkoutDetails, subscription } = checkoutInfo;

  const returnUrl = generatePaymentResultUrl({
    retryPath,
    paymentResultType,
    connectedAccountId: paymentIntent.connectedAccountId,
    consultationType,
  });

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    loadingStart(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: returnUrl,
      },
    });

    if (error) {
      enqueueSnackbar(error.message || 'Something went wrong. Please try again later', {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }

    loadingEnd(true);
  };

  const handleChangeTermsAccepted = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    setTermsAccepted(checked);
  };

  const getTermsAcceptedLabel = () => {
    if (subscriptionTypeFlags.REGULAR) {
      return `I understand that this is a recurring subscription and I'm going to be billed ${getPriceView(
        subscription.monthlyRenewalPriceFinal,
      )} amount next month (plus taxes) after initial plan ends`;
    }

    return `I understand that this is a recurring subscription and I'm going to be billed ${getPriceView(
      subscription.monthlyRenewalPriceFinal,
    )} amount next month (plus taxes)`;
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      <PaymentButton type="googlePay" returnUrl={returnUrl} />
      <PaymentButton
        type="applePay"
        returnUrl={returnUrl}
        className={classes.applePayButton}
      />
      <Divider className={classes.divider} flexItem textAlign="center">
        Or pay with card
      </Divider>
      <PaymentElement
        id="card-info-box" // A&A test id
        options={paymentElementsOptions}
      />
      <Button
        disabled={!termsAccepted}
        className={classes.formElements}
        variant="contained"
        type="submit"
        fullWidth
      >
        Pay {getPriceView(checkoutDetails.totalAmount)}
      </Button>
      <div className={classes.terms}>
        <LbFormControlLabel
          className={classes.formElements}
          align="start"
          control={
            <Checkbox
              checked={termsAccepted}
              onChange={handleChangeTermsAccepted}
              color="primary"
              name="saveCard"
            />
          }
          label={getTermsAcceptedLabel()}
        />
        <TermsLinks />
      </div>
    </form>
  );
});

export { CheckoutForm };
