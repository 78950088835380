(function () {
  'use strict';

  angular.module('app.entityProfile').controller('SubscriptionsController', SubscriptionsController);
  SubscriptionsController.$inject = ['$scope', 'SubscriptionsService', 'ModalService', 'constants', 'utilService'];

  function SubscriptionsController($scope, SubscriptionsService, ModalService, constants, utilService) {
    const vm = this;
    const defaultSubscription = {
      subscriptionType: '',
      active: true,
      title: '',
      initialDaysOfSubscription: 30,
      initialSubscriptionAmount: 0,
      autoRenewal: true,
      renewDaysOfSubscription: 30,
      renewalSubscriptionAmount: 0
    }

    vm.entityId = $scope.entityId;
    vm.spinner = false;
    vm.isAddNew = false;
    vm.subscriptions = [];
    vm.subscriptionTypesEnum = constants.enums.subscriptionTypes;
    vm.isChildEntity = utilService.getIsChildEntity();
    vm.autoRenewalVariants = [
      { text: 'Yes', value: true },
      { text: 'No', value: false }
    ];
    vm.newSubscription = { ...defaultSubscription };

    vm.init = init;
    vm.getSubscriptions = getSubscriptions;
    vm.getAvailableSubscriptionTypes = getAvailableSubscriptionTypes;
    vm.onPriceChange = onPriceChange;
    vm.onAutoRenewalChange = onAutoRenewalChange;
    vm.getTaxPaymentValue = getTaxPaymentValue;
    vm.getTotalBill = getTotalBill;
    vm.onAddNew = onAddNew;
    vm.onCancel = onCancel;
    vm.handleCancelSubscriptionCreation = handleCancelSubscriptionCreation;
    vm.onSubscriptionTypeChange = onSubscriptionTypeChange;
    vm.addSubscription = addSubscription;
    vm.onSubscriptionStatusChange = onSubscriptionStatusChange;
    vm.changeSubscriptionStatus = changeSubscriptionStatus;
    vm.resetNewSubscription = resetNewSubscription;

    vm.init();

    function init() {
      vm.getAvailableSubscriptionTypes();
      vm.getSubscriptions();
    }

    function getAvailableSubscriptionTypes() {
      vm.spinner = true;
      SubscriptionsService.getSubscriptionTypes(vm.entityId)
          .then(res => {
            if (res.status === 200) {
              vm.subscriptionTypes = res.data.map(subscriptionType => {
                switch (subscriptionType) {
                  case vm.subscriptionTypesEnum.SUPPLEMENT:
                    return { value: subscriptionType, text: 'Supplement'};
                  case vm.subscriptionTypesEnum.NUTRITION:
                    return { value: subscriptionType, text: 'Nutrition'};
                  case vm.subscriptionTypesEnum.NON_INBODY:
                    return { value: subscriptionType, text: 'Non InBody'}; 
                  case vm.subscriptionTypesEnum.SCHOLARSHIP:
                    return { value: subscriptionType, text: 'Scholarship'};
                  case vm.subscriptionTypesEnum.CORPORATE:
                    return { value: subscriptionType, text: 'Corporate' };
                  case vm.subscriptionTypesEnum.SCHOLASTIC:
                    return { value: subscriptionType, text: 'Scholastic' };
                  default: 
                    return { value: subscriptionType, text: subscriptionType };
                }
              });
            }
          })
          .finally(() => vm.spinner = false);
    }

    function getSubscriptions() {
      vm.spinner = true;
      SubscriptionsService.getSubscriptions(vm.entityId)
          .then(res => {
            if (res.status === 200) {
              vm.subscriptions = res.data.subscriptions;
              vm.tax = res.data.tax;
              vm.stripe = res.data.stripe;
            }
          })
          .finally(() => vm.spinner = false);
    }

    function onAddNew() {
      vm.isAddNew = true;
    }

    function onPriceChange(price) {
      vm.getTaxPaymentValue(price);
      vm.getTotalBill(price);
    }

    function onAutoRenewalChange(subscription) {
      subscription.renewalSubscriptionAmount = 0;
    }

    function getTaxPaymentValue(price) {
      return (price * (vm.tax / 100)).toFixed(2);
    }

    function getTotalBill(price) {
      return (price + +vm.getTaxPaymentValue(price)).toFixed(2);
    }

    function resetNewSubscription() {
      vm.newSubscription = { ...defaultSubscription };
    }

    function onCancel(isFormNotValid) {
      if (isFormNotValid) {
        vm.handleCancelSubscriptionCreation()
      } else {
        ModalService.confirm('Unsaved changes', vm.addSubscription, null,
          'You have unsaved changes. Do you want to save?', 'Cancel', 'Save', vm.handleCancelSubscriptionCreation);
      }
    }

    function handleCancelSubscriptionCreation() {
      vm.isAddNew = false;
      vm.resetNewSubscription();
    }

    function onSubscriptionTypeChange(subscription) {
      switch (subscription.subscriptionType) {
        case vm.subscriptionTypesEnum.CORPORATE:
        case vm.subscriptionTypesEnum.SCHOLARSHIP: {
          subscription.renewDaysOfSubscription = 30;
          subscription.renewalSubscriptionAmount = 0;
          subscription.initialSubscriptionAmount = 0;
          break;
        }
        case vm.subscriptionTypesEnum.SCHOLASTIC: {
          // SCHOLASTIC not support renewal
          subscription.autoRenewal = false;
          subscription.renewDaysOfSubscription = 0;
          break;
        }
      }
    }

    function addSubscription() {
      vm.spinner = true;
      const newSubscriptionInfo = angular.copy(vm.newSubscription);

      SubscriptionsService.addSubscription(vm.entityId, newSubscriptionInfo)
          .then(res => {
            if (res.status === 200) {
              vm.getSubscriptions();
            }
          })
          .finally(() => {
            vm.spinner = false;
            vm.handleCancelSubscriptionCreation()
          });
    }

    function changeSubscriptionStatus(subscription) {
      vm.spinner = true;
      SubscriptionsService.changeSubscriptionStatus(subscription.subscriptionID)
          .then(res => {
            if (res.status === 200) {
              subscription.active = !subscription.active;
            }
          })
          .finally(() => vm.spinner = false);
    }

    function onSubscriptionStatusChange(subscription, isAddNewCardChange = false) {
      if (!isAddNewCardChange) {
        subscription.active = !subscription.active;
        if (subscription.active) {
          ModalService.confirm('Are you sure you want to deactivate this subscription?',
              vm.changeSubscriptionStatus.bind(vm.subscriptions, subscription),
              subscription, 'This subscription will not be displayed for coaches. Users with this subscription will not' +
              ' be able to access the platform\'s services.', 'Cancel', 'Yes');
        } else {
          vm.changeSubscriptionStatus(subscription);
        }
      }
    }
  }
})();
