import React from 'react';

import { FormProvider, useForm } from 'react-hook-form';

import { LbBigModal } from '../../../../../../shared/components/modals';
import { CompareScanTable } from './CompareScanTable/CompareScanTable';
import { CompareDateForm } from './CompareDateForm/CompareDateForm';
import { InbodyIndicatorInfo } from '../../../../../interfaces/inbodyIndicator';
import { CompareScanMetricsFormValues } from './CompareScanMetricsModal.settings';

type CustomerInfo = {
  gender: string;
  age: number;
};

type CompareScanMetricsModalProps = {
  customerInfo: CustomerInfo;
  customerInbodyIndicators: InbodyIndicatorInfo[];
  onClose: () => void;
};
// create utils for this
const getScanDateTimes = (customerInbodyIndicators: InbodyIndicatorInfo[]) => {
  const scanDates = new Set<string>();

  customerInbodyIndicators.forEach((indicator) => {
    indicator.scans.forEach((scan) => {
      scanDates.add(scan.scanDateTime);
    });
  });

  return Array.from(scanDates);
};

const CompareScanMetricsModal = ({
  customerInfo,
  customerInbodyIndicators,
  onClose,
}: CompareScanMetricsModalProps) => {
  const formMethods = useForm<CompareScanMetricsFormValues>();

  const { gender, age } = customerInfo;
  const scanDateTimes = getScanDateTimes(customerInbodyIndicators);
  return (
    <LbBigModal
      open
      title="Compare Scan Metrics"
      description={`${gender}, ${age} years`}
      buttonOptions={{ yesVisible: false, noText: 'Close', noHandler: onClose }}
    >
      <FormProvider {...formMethods}>
        <CompareDateForm scanDateTimes={scanDateTimes} />
        <CompareScanTable customerInbodyIndicators={customerInbodyIndicators} />
      </FormProvider>
    </LbBigModal>
  );
};

export { CompareScanMetricsModal };
