import React from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { observer } from 'mobx-react';

import classes from './WorkoutSettingsStep.module.scss';
import { ButtonsRow } from '../../components/ButtonsRow/ButtonsRow';
import { QuestionnaireAnswerOverview } from '../../components/QuestionnaireAnswerOverview/QuestionnaireAnswerOverview';
import { SelectActivityForm } from './SelectActivityForm/SelectActivityForm';
import { WorkoutMeals } from './WorkoutMeals/WorkoutMeals';
import { MealsPerDay } from './MealsPerDay/MealsPerDay';
import { activityService } from '../../../../services/activityService';
import { CustomerActivities } from '../../../../interfaces/activities';
import { WorkoutSettingsFormValues } from './WorkoutSettingsStep.settings';
import { getWorkoutSettingsFormValues } from './utils/getWorkoutSettingsFormValues';
import { customerSetupStore } from '../../../../stores/customerSetupStore';
import { mealPlanService } from '../../../../../nutrition';

type WorkoutSettingsStepProps = {
  customerId: number;
};

const WorkoutSettingsStep = observer(({ customerId }: WorkoutSettingsStepProps) => {
  const formMethods = useForm<WorkoutSettingsFormValues>({
    mode: 'onBlur',
    defaultValues: getWorkoutSettingsFormValues,
  });
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = formMethods;

  const { stepperMethods, setMealsPerDay } = customerSetupStore;

  const handleFormSubmit = handleSubmit(
    async ({ selectActivity, workoutMeals, defaultMealsPerDay }) => {
      const customerActivities: CustomerActivities = {
        activities: [...selectActivity.workouts, ...selectActivity.sports],
      };

      await Promise.all([
        await activityService.setCustomerActivities(customerId, customerActivities),
        await mealPlanService.createWorkoutMealPlan(customerId, {
          ...workoutMeals,
          defaultMealsPerDay,
        }),
      ]);

      setMealsPerDay(defaultMealsPerDay);
      stepperMethods.goToNextStep();
    },
  );

  return (
    <FormProvider {...formMethods}>
      <div>
        <div className={classes.row}>
          <QuestionnaireAnswerOverview />
          <SelectActivityForm />
        </div>
        <WorkoutMeals />
        <MealsPerDay />
        <ButtonsRow onNext={handleFormSubmit} yesDisabled={isSubmitting} />
      </div>
    </FormProvider>
  );
});

export { WorkoutSettingsStep };
