import React from 'react';
import { Control } from 'react-hook-form';

import { AnswerInfoWithDetails } from '../../../../interfaces/QuestionnaireInfo';
import { RadioOption } from '../../../../../general-types';
import { TitleWithDescription } from '../../../../../shared/components/text-formation-components';
import { InfoBox } from '../../../../../shared/components/ui-components';
import { LbRadioGroup } from '../../../../../shared/components/form-components';
import classes from './RadioQuestion.module.scss';

type RadioQuestionProps = {
  control: Control;
  questionId: number;
  name: string;
  answers: AnswerInfoWithDetails[];
  questionNumber: number;
};

const RadioQuestion = ({
  control,
  questionId,
  name,
  answers,
  questionNumber,
}: RadioQuestionProps) => {
  const getQuestionRadioOptions = (options: AnswerInfoWithDetails[]): RadioOption[] =>
    options.map((option) => ({
      value: option.answerId,
      label: (
        <TitleWithDescription
          title={option.name}
          description={option.description}
          titleFont="inherit"
        />
      ),
    }));

  return (
    <InfoBox title={`${questionNumber}. ${name}`}>
      <LbRadioGroup
        name={questionId.toString()}
        control={control}
        options={getQuestionRadioOptions(answers)}
        rules={{ required: 'The question is required' }}
        className={classes.radioGroup}
        align="start"
      />
    </InfoBox>
  );
};

export { RadioQuestion };
