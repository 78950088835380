import React from 'react';
import { Button, Paper } from '@mui/material';
import { useForm } from 'react-hook-form';

import classes from './PromoCodeCard.module.scss';
import { LbInput } from '../../../../shared/components/form-components';
import { validatePromoCode } from '../../../utils/validatePromoCode/validatePromoCode';
import { TitleWithDescription } from '../../../../shared/components/text-formation-components';

type PromoCodeCardProps = {
  onApplyPromoCode: (promoCode: string) => void;
};

type PromoCodeFormValues = {
  promoCode: string;
};

const PromoCodeCard = ({ onApplyPromoCode }: PromoCodeCardProps) => {
  const { control, handleSubmit } = useForm<PromoCodeFormValues>();

  const onSubmit = handleSubmit((data) => {
    onApplyPromoCode(data.promoCode);
  });

  return (
    <Paper variant="box" className={classes.container}>
      <TitleWithDescription
        title="Have a promo code?"
        description="Please enter the promo code to receive a discount."
        descriptionColor="natural-600"
      />
      <form onSubmit={onSubmit} className={classes.form}>
        <LbInput
          control={control}
          name="promoCode"
          label="Promo code"
          variant="outlined"
          size="small"
          placeholder="Enter promo code"
          rules={{
            validate: { validatePromoCode },
          }}
        />
        <Button type="submit" variant="contained" color="primary" size="small">
          Apply
        </Button>
      </form>
    </Paper>
  );
};

export { PromoCodeCard, type PromoCodeCardProps };
