import { Components } from '@mui/material';

export const MuiSvgIconTheme: Components['MuiSvgIcon'] = {
  variants: [
    {
      props: { fontSize: 'extraSmall' },
      style: {
        fontSize: '12px',
      },
    },
    {
      props: { fontSize: 'small' },
      style: {
        fontSize: '16px',
      },
    },
    {
      props: { fontSize: 'medium' },
      style: {
        fontSize: '20px',
      },
    },
    {
      props: { fontSize: 'large' },
      style: {
        fontSize: '24px',
      },
    },
    {
      props: { fontSize: 'extraLarge' },
      style: {
        fontSize: '35px',
      },
    },
  ],
};

declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsSizeOverrides {
    extraSmall: true;
    extraLarge: true;
  }
}
