import React from 'react';

import { observer } from 'mobx-react';

import classes from './CustomerRow.module.scss';
import {
  CheckboxCell,
  GridTableCell,
  GridTableRow,
  UserInfoCell,
} from '../../../../shared/components/table-components';
import { CustomerContactLink } from '../../../../shared/components/buttons';
import { StatusCell } from './StatusCell/StatusCell';
import { CustomerCommunicationInfo } from '../../../interfaces/CustomerCommunicationInfo';
import { getFormattedDate } from '../../../../shared/utils/dateUtils';
import {
  localDataFormat,
  oldDateFormat,
} from '../../../../shared/constants/apiDateFormat';
import { customerCommunicationStore } from '../../../stores/customerCommunicationStore';
import { pathQueryParams } from '../../../../shared/constants/pathQueryParams';
import { loggedInUserDataStore } from '../../../../shared/stores/loggedInUserDataStore';
import { getFlagsObject } from '../../../../shared/utils/sortingAndGroupingUtil';
import { USER_ACTIVATION_STATUSES } from '../../../constants/USER_ACTIVATION_STATUSES';
import goHighLevelLogo from '../../../../shared/assets/image/goHighLevelLogo.webp';
import { H20BStatus } from './H20BStatus/H20BStatus';

type CustomerRowProps = {
  userInfo: CustomerCommunicationInfo;
};

const CustomerRow = observer(({ userInfo }: CustomerRowProps) => {
  const { isShowCoachColumn, isShowLocationColumn, selectMethod, filterMethod } =
    customerCommunicationStore;
  const { isCoach } = loggedInUserDataStore;

  const { getIsSelected, selectItem } = selectMethod;
  const { isShowFilterRow } = filterMethod;

  const coachName = `${userInfo.coachFirstName} ${userInfo.coachLastName}`;

  const isChecked = getIsSelected(userInfo.id);
  const handleCheck = () => {
    selectItem(userInfo.id);
  };

  const { hasScansAvailable } = pathQueryParams;

  const activationStatus = getFlagsObject(USER_ACTIVATION_STATUSES, [userInfo.pStatus]);

  const userLink =
    activationStatus.PREREGISTERED && isCoach
      ? `#!/customer-setup/flow-2024/${userInfo.id}`
      : `#!/customer-profile/${userInfo.id}?${hasScansAvailable}=${userInfo.newScansAvailable}`;

  const isUserLinkDisabled = activationStatus.PREREGISTERED && !isCoach;
  const isGHLConnected = !!userInfo.ghlContactId;

  return (
    <GridTableRow
      variant="row"
      highlight={userInfo.newScansAvailable}
      dataTestId="customer-row"
    >
      <CheckboxCell
        showCheckbox={isShowFilterRow}
        onCheck={handleCheck}
        isChecked={isChecked}
      >
        <UserInfoCell
          firstName={userInfo.firstName}
          lastName={userInfo.lastName}
          email={userInfo.email}
          phone={userInfo.phone}
          href={userLink}
          disabled={isUserLinkDisabled}
          dataTestId="customer-info-cell"
          nameAdornment={
            <div className={classes.statusContainer}>
              {isGHLConnected && (
                <img src={goHighLevelLogo} alt="GoHighLevel logo" width={16} />
              )}
              <H20BStatus status={userInfo.h20bScaleStatus} />
            </div>
          }
        />
      </CheckboxCell>
      <GridTableRow variant="unstyled">
        {isShowLocationColumn && (
          <GridTableCell dataTestId="location-cell">{userInfo.gymName}</GridTableCell>
        )}
        <GridTableCell dataTestId="group-cell">{userInfo.customerGroup}</GridTableCell>
        {isShowCoachColumn && (
          <GridTableCell dataTestId="coach-cell" className="private-info">
            {coachName}
          </GridTableCell>
        )}
        <GridTableCell dataTestId="start-cell">
          {getFormattedDate(userInfo.registrationDate, localDataFormat, oldDateFormat)}
        </GridTableCell>
        <StatusCell
          activationStatus={userInfo.pStatus}
          subscriptionExpire={userInfo.subscriptionExpire}
        />
        <div />
      </GridTableRow>
      <GridTableCell justify="end" dataTestId="contact-cell">
        <CustomerContactLink
          customerId={userInfo.id}
          useReactModal
          resourceType="customers"
        />
      </GridTableCell>
    </GridTableRow>
  );
});

export { CustomerRow };
