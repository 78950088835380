import React from 'react';

import { UseFormProps, useForm } from 'react-hook-form';

import { RadioQuestion } from './RadioQuestion/RadioQuestion';
import { TextQuestion } from './TextQuestion/TextQuestion';
import { QuestionInfo } from '../../../interfaces/QuestionnaireInfo';
import { QUESTION_FIELD_TYPE } from '../../../constants/question';

type QuestionnaireFormProps = {
  formConfig: UseFormProps;
  questions: QuestionInfo[];
  onSubmit: (data: Record<string, number>) => void;
  formId: string;
};

const QuestionnaireForm = ({
  formConfig,
  questions,
  onSubmit,
  formId,
}: QuestionnaireFormProps) => {
  const { control, handleSubmit } = useForm(formConfig);

  const handleFormSubmit = handleSubmit(onSubmit);

  return (
    <form onSubmit={handleFormSubmit} id={formId} noValidate>
      {questions.map(({ questionId, name, fieldType, answers }, index) => {
        switch (fieldType) {
          case QUESTION_FIELD_TYPE.TEXT:
            return (
              <TextQuestion
                key={questionId}
                control={control}
                questionId={questionId}
                name={name}
              />
            );
          case QUESTION_FIELD_TYPE.RADIO:
            return (
              <RadioQuestion
                key={questionId}
                control={control}
                questionId={questionId}
                name={name}
                answers={answers}
                questionNumber={index + 1}
              />
            );
          default:
            return null;
        }
      })}
    </form>
  );
};

export { QuestionnaireForm };
