import { enqueueSnackbar } from "notistack";
import { FLAGS } from "../../modules/shared/clients/unleash";

(function () {
    'use strict';

    angular.module('app.coachProfile').controller('CoachProfileController', CoachProfileController);

    CoachProfileController.$inject = [
        '$scope',
        '$location',
        'utilService',
        'AuthService',
        'CoachService',
        'GeneralService',
        '$window',
        'PaidModulesUtil',
        'constants',
        'BaseUserUtils',
        'ModalService',
    ];

    function CoachProfileController($scope, $location, utilService, AuthService, CoachService, GeneralService, $window, PaidModulesUtil, constants, BaseUserUtils, ModalService) {
        var vm = this;

        vm.loggedInUser = utilService.getLoggedInUserData();
        vm.patterns = constants.enums.patterns;
        vm.coachAccountSettings = {};
        vm.coachPassword = {};
        vm.isActiveCalendarEnabled = false;
        vm.isShowReactCoachProfile = false;
        vm.spinner = { active: false };

        vm.goToMoodleAccount = goToMoodleAccount;
        vm.getCoachAccountSettings = getCoachAccountSettings;
        vm.updateCoachAccountSettings = updateCoachAccountSettings;
        vm.initTabName = initTabName;
        vm.changeTab = changeTab;
        vm.changeAvatar = changeAvatar;
        vm.setEnabledModules = setEnabledModules;
        vm.updateCoachAccountSettingsReact = updateCoachAccountSettingsReact;
        vm.updateCoachBio = updateCoachBio;


        vm.$onInit =() => {
            utilService.watchFlagAngular({
                vm,
                $scope,
                flagName: FLAGS.SHOW_REACT_COACH_PROFILE,
                fieldName: "isShowReactCoachProfile",
            }); 

            vm.initTabName();
            vm.getCoachAccountSettings();
            vm.setEnabledModules();
        }

        function changeAvatar(newAvatar) {
            BaseUserUtils.updateAvatar(newAvatar, vm.coachAccountSettings);
        }

        function goToMoodleAccount(path) {
            $window.open(path, '_blank');
        }

        function initTabName() {
            vm.tabName = $location.url() === '/coach-profile/notes' ? 'notes' : 'accountSettings';
        }

        function getCoachAccountSettings() {
            vm.spinner.active = true;
            CoachService.getCoachAccountSettings(vm.loggedInUser.id).then(function (res) {
                if (res.status === 200) {
                    vm.coachAccountSettings = res.data;
                }
            }).finally(() => {
                vm.spinner.active = false;
            });
        }

        function updateCoachAccountSettings() {
            vm.spinner.active = true;
            CoachService.updateCoachAccountSettings(vm.loggedInUser.id, vm.coachAccountSettings).then(function (res) {
                if (res.status === 200) {
                    vm.loggedInUser = res.data;
                    ModalService.savedChanges();
                }
            }).finally(() => {
                vm.spinner.active = false;
            });
        }

        function updateCoachAccountSettingsReact(data) {
            vm.spinner.active = true;
            CoachService.updateCoachAccountSettings(vm.loggedInUser.id, data).then(function (res) {
                if (res.status === 200) {
                    vm.loggedInUser = res.data; 
                    vm.coachAccountSettings = angular.copy(res.data);
                    enqueueSnackbar('Coach info updated successfully', { variant: 'success' });
                    utilService.applyAsyncFunc($scope);
                }
            }).finally(() => {
                vm.spinner.active = false;
            });
        }

        function updateCoachBio(bio) {
            vm.coachAccountSettings = angular.copy({
                ...vm.coachAccountSettings,
                additional: {
                    ...vm.coachAccountSettings.additional,
                   ...bio,
                },
            });

            utilService.applyAsyncFunc($scope);
        }

        function changeTab(tabName) {
            vm.tabName = tabName;
            if (vm.tabName === 'password') {
                vm.coachPassword = {};
            }
        }

        function setEnabledModules () {
            vm.isActiveCalendarEnabled = PaidModulesUtil.getIsActiveCalendarEnabled();
        }
    }
})();
