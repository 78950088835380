import { apiService } from '../../shared/services/apiService';
import { AccessParams } from '../../shared/interfaces/AccessParams';
import { CustomerSubscription } from '../interfaces/customerSubscription';
import { PromoCodeInfo } from '../interfaces/promoCodeInfo';
import { ConsultationType } from '../../shared/constants/coach/consultationType';
import { GenderType } from '../../shared/constants/user-info/gender';

export type GetCustomerCouchDataResponse = {
  age: number | null;
  bio: string | null;
  coachId: number;
  coachAvatar: null | string;
  coachName: string;
  entityId: number;
  entityName: string;
  consultationType: ConsultationType;
  gender: GenderType | null;
};

export type ApplyPromoCodeResponse = {
  subscriptions: CustomerSubscription[];
  promocode: PromoCodeInfo;
};

export const signUpSubscriptionsService = {
  async getSubscriptions(entityId: number, email: string) {
    const params = { entityId, email };

    const response = await apiService.post<CustomerSubscription[]>(
      '/api/sign-up/subscriptions',
      params,
    );

    return response.data;
  },

  async applyPromoCode(email: string, entityId: number, promocode: string) {
    const params = { email, entityId, promocode };

    const response = await apiService.post<ApplyPromoCodeResponse>(
      '/api/sign-up/subscriptions/apply-promo',
      params,
    );

    return response.data;
  },

  async getSubscriptionsForReactivation(accessParams: AccessParams) {
    const { token, timestamp, hash } = accessParams;

    const resp = await apiService.get<CustomerSubscription[]>(
      `/api/sign-in/subscriptions/${token}/${timestamp}/${hash}`,
    );

    return resp.data;
  },

  async getCustomerCouchData(accessParams: AccessParams) {
    const { token, timestamp, hash } = accessParams;

    const resp = await apiService.get<GetCustomerCouchDataResponse>(
      `/api/sign-in/user/coach-data/${token}/${timestamp}/${hash}`,
    );

    return resp.data;
  },
};
