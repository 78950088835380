const autoNgTemplateLoaderTemplate1 = require('../modals/saved-changes.modal.html');
const autoNgTemplateLoaderTemplate2 = require('../modals/video.modal.html');
const autoNgTemplateLoaderTemplate3 = require('../modals/buy-certificates.modal.html');
const autoNgTemplateLoaderTemplate4 = require('../modals/add-micro-nutrient.modal.html');
const autoNgTemplateLoaderTemplate5 = require('../modals/choose.modal.html');
const autoNgTemplateLoaderTemplate6 = require('../modals/info-message.modal.html');
const autoNgTemplateLoaderTemplate7 = require('../modals/send-message.modal.html');
const autoNgTemplateLoaderTemplate8 = require('../modals/sending-message.modal.html');
const autoNgTemplateLoaderTemplate9 = require('../modals/canceled-plan-info.modal.html');
const autoNgTemplateLoaderTemplate10 = require('../modals/failed-goal.modal.html');
const autoNgTemplateLoaderTemplate11 = require('../modals/reached-goal.modal.html');
const autoNgTemplateLoaderTemplate12 = require('../modals/certification-assigned.modal.html');
const autoNgTemplateLoaderTemplate13 = require('../modals/complete-meal-plan-set-up.modal.html');
const autoNgTemplateLoaderTemplate14 = require('../modals/add-food.modal.html');
const autoNgTemplateLoaderTemplate15 = require('../modals/add-food-for-meal.modal.html');
const autoNgTemplateLoaderTemplate16 = require('../modals/pos-errors-list.modal.html');
const autoNgTemplateLoaderTemplate17 = require('../modals/add-food-to-meal.modal.html');
const autoNgTemplateLoaderTemplate18 = require('../modals/add-favorite-category.modal.html');
const autoNgTemplateLoaderTemplate19 = require('../modals/save-recipe-as-food.modal.html');
const autoNgTemplateLoaderTemplate20 = require('../modals/suggest-scenario.modal.html');
const autoNgTemplateLoaderTemplate21 = require('../modals/suggest-scenario.modal.html');
const autoNgTemplateLoaderTemplate22 = require('../modals/add-new-scan.modal.html');
const autoNgTemplateLoaderTemplate23 = require('../modals/check-in.modal.html');
const autoNgTemplateLoaderTemplate24 = require('../modals/meal-info.modal.html');
const autoNgTemplateLoaderTemplate25 = require('../modals/reassign-coaches.modal.html');
const autoNgTemplateLoaderTemplate26 = require('../modals/scan-data.modal.html');
const autoNgTemplateLoaderTemplate27 = require('../modals/info.modal.html');
const autoNgTemplateLoaderTemplate28 = require('../modals/goal-explanation.modal.html');
const autoNgTemplateLoaderTemplate29 = require('../modals/subscription-info.modal.html');
const autoNgTemplateLoaderTemplate30 = require('../modals/corp-plans-info.modal.html');
const autoNgTemplateLoaderTemplate31 = require('../modals/unlink-lightspeed-service.modal.html');
const autoNgTemplateLoaderTemplate32 = require('../modals/canceled-entity-plan-info.modal.html');
const autoNgTemplateLoaderTemplate33 = require('../modals/change-meal-time.modal.html');
const autoNgTemplateLoaderTemplate34 = require('../modals/add-new-compliance-score.modal.html');
const autoNgTemplateLoaderTemplate35 = require('../modals/edit-meals.modal.html');
const autoNgTemplateLoaderTemplate36 = require('../modals/edit-workout-days.modal.html');
const autoNgTemplateLoaderTemplate37 = require('../modals/save-confirm.modal.html');
const autoNgTemplateLoaderTemplate38 = require('../modals/select-ingredient.modal.html');
const autoNgTemplateLoaderTemplate39 = require('../modals/add-food-to-meal.modal.html');
const autoNgTemplateLoaderTemplate40 = require('../modals/stripe.modal.html');

'use strict';

angular.module('app').factory('ModalService', ModalService);

ModalService.$inject = ['$mdDialog'];

function ModalService($mdDialog) {
    var modalService = {
        savedChanges: function (onRemove, title) {
            $mdDialog.show({
                controller: ['$scope', '$mdDialog', function ($scope, $mdDialog) {
                    $scope.title = title || 'Changes have been saved successfully';
                    $scope.close = function () {
                        if (typeof (onRemove) === 'function') {
                            onRemove();
                        }
                        $mdDialog.cancel();
                    };
                }],
                templateUrl: autoNgTemplateLoaderTemplate1,
                clickOutsideToClose: true
            });
        },
        showDialogWithVideo: function (video) {
            $mdDialog.show({
                controller: ['$scope', '$mdDialog', function ($scope, $mdDialog) {
                    $scope.video = video;
                    setTimeout(function () {
                        var wrapper = document.getElementsByClassName('js-videoWrapper')[0];
                        var iframe = wrapper.getElementsByClassName('js-videoIframe')[0];

                        iframe.setAttribute('data-src', video.youtubevideo);
                        iframe.setAttribute('src', video.youtubevideo);
                    });
                    $scope.closeDialog = function () {
                        $mdDialog.cancel();
                    }
                }],
                templateUrl: autoNgTemplateLoaderTemplate2,
                clickOutsideToClose: true
            });
        },
        buyCertificates: function (availableCertificates, cb) {
            $mdDialog.show({
                controller: ['$scope', '$mdDialog', function ($scope, $mdDialog) {
                    $scope.selectedCertificate = null;
                    $scope.certificates = availableCertificates;
                    $scope.cancel = function () {
                        $mdDialog.cancel();
                    };
                    $scope.buyCertificate = function () {
                        $mdDialog.cancel();
                        cb($scope.selectedCertificate);
                    }
                }],
                templateUrl: autoNgTemplateLoaderTemplate3,
                clickOutsideToClose: true
            });
        },
        addMicroNutrient: function (vm) {
            $mdDialog.show({
                controller: ['$scope', '$mdDialog', function ($scope, $mdDialog) {
                    $scope.vm = vm;
                    $scope.close = function () {
                        $mdDialog.cancel();
                    };
                    $scope.add = function () {
                        vm.addNutrientFromList();
                        $scope.close();
                    };
                }],
                templateUrl: autoNgTemplateLoaderTemplate4,
                parent: angular.element(document.body),
                clickOutsideToClose: true
            });
        },
        alert: function (message, title, callback) {
            $mdDialog.show($mdDialog.alert({
                title: title,
                textContent: message || '',
                ok: 'Close',
                ariaLabel: 'alert-dialog',
            })).finally(function () {
                if (typeof (callback) === 'function') {
                    callback();
                }
            });
        },
        confirm: function (title, onSuccess, attrForSuccesFunction, message, cancelBtn, OkBtn, onCancel) {
            $mdDialog.show($mdDialog.confirm({
                title: title,
                ariaLabel: OkBtn === 'Delete' ? 'delete-dialog' : 'confirm-dialog',
                textContent: message || '',
                ok: OkBtn || 'Confirm',
                cancel: cancelBtn || 'Cancel',
            })).then(function () {
                if (typeof (onSuccess) === 'function') {
                    onSuccess(attrForSuccesFunction);
                }
            }).catch(() => {
                if (typeof (onCancel) === 'function') {
                    onCancel();
                }
            });
        },
        choose: function (title, message, firstBtn, secondBtn, onFirstBtnClick, onSecondBtnClick) {
            $mdDialog.show({
                controller: ['$scope', '$mdDialog', function ($scope, $mdDialog) {
                    $scope.title = title || '';
                    $scope.message = message || '';
                    $scope.firstBtn = firstBtn || 'Confirm',
                    $scope.secondBtn = secondBtn || 'Cancel',
                    $scope.onFirstBtnClick = function () {
                        $mdDialog.cancel();
                        onFirstBtnClick();
                    }
                    $scope.onSecondBtnClick = function () {
                        $mdDialog.cancel();
                        onSecondBtnClick();
                    }
                }],
                templateUrl: autoNgTemplateLoaderTemplate5,
                clickOutsideToClose: false
            });
        },
        getInfoMessage: function (message, onRemove) {
            return $mdDialog.show({
                controller: ['$scope', '$mdDialog',
                    function ($scope, $mdDialog) {
                        $scope.message = message;

                        $scope.cancel = function () {
                            if (typeof (onRemove) === 'function') {
                                onRemove();
                            }
                            $mdDialog.cancel();
                        };
                    }],
                templateUrl: autoNgTemplateLoaderTemplate6,
                clickOutsideToClose: false
            });
        },
        sendMessage: function (prms, users, chatUsersIds, isEkinUsers) {
            return $mdDialog.show({
                controller: ['$scope', '$mdDialog',
                    function ($scope, $mdDialog) {
                        $scope.prms = prms;
                        $scope.isEkinUsers = isEkinUsers;
                        $scope.chatUsersIds = chatUsersIds;

                        $scope.cancel = function () {
                            $mdDialog.cancel();
                        };

                        $scope.send = function () {
                            modalService.sendingMessage(users, chatUsersIds, prms);
                        }
                    }],
                templateUrl: autoNgTemplateLoaderTemplate7,
                clickOutsideToClose: true
            });
        },
        sendingMessage: function (users, chatUsers, data) {
            return $mdDialog.show({
                controller: ['$scope', '$location', '$mdDialog', '$rootScope', 'ApiService', 'utilService',
                    function ($scope, $location, $mdDialog, $rootScope, ApiService, utilService) {
                        $scope.role = utilService.getLoggedInUserData().role;
                        $scope.path = $location.path();
                        $scope.$on('finishSending', () => {
                            stopSendingMessagesLoading();
                        });

                        init();

                        $scope.cancel = function () {
                            $mdDialog.cancel();
                        };

                        $scope.resend = function (via) {
                            data.via = via;
                            if (via === 'email' && !data.title) {
                                data.title = 'LifeBase Default Subject'
                            }

                            init();
                        };

                        function stopSendingMessagesLoading() {
                            $scope.sendingMessagesLoading = false;
                            $scope.$apply();
                        }

                        async function init() {
                            $scope.sendingMessagesLoading = true;
                            $scope.usersNum = data.userIds.length;
                            $scope.notSendedList = [];

                            if (data.via !== 'chat') {
                                ApiService.post('api/coach/message/users', data).then(function (result) {
                                    data.userIds = [];
                                    $scope.sent = result.data.sent;
                                    $scope.sent.map(function (item) {
                                        users.map(function (user) {
                                            if (user === item.userId) {
                                                item.name = item.firstName + ' ' + item.lastName;
                                                $scope.notSendedList.push(item);
                                                data.userIds.push(user);
                                            }
                                        });
                                    });

                                    setTimeout(function () {
                                        $scope.sendingMessagesLoading = false;
                                        $scope.$digest();
                                    }, 1000);
                                });
                            }
                        }
                    }],
                templateUrl: autoNgTemplateLoaderTemplate8,
                parent: angular.element(document.body)
            });
        },
        canceledPlanInfo: function (canceledPlan, loggedInUser, getEntitiesPaymentTransaction, isCoach) {
            return $mdDialog.show({
                controller: ['$scope', function ($scope) {
                    $scope.canceledPlan = canceledPlan;
                    $scope.currentCustomerDetails = loggedInUser;

                    $scope.cancel = function () {
                        $mdDialog.cancel();

                        if (isCoach) {
                            getEntitiesPaymentTransaction();
                        } else {
                            if (loggedInUser.customer.corporateSubscription) {
                                getEntitiesPaymentTransaction();
                            } else {
                                canceledPlan.renewal_active = false;
                                localStorage.setItem('loggedInUser', JSON.stringify(loggedInUser));
                            }
                        }
                    };
                }],
                templateUrl: autoNgTemplateLoaderTemplate9,
                parent: angular.element(document.body)
            });
        },
        nonReachedGoal: function (vm, item, key) {
            $mdDialog.show({
                controller: ['$scope', '$mdDialog', function ($scope, $mdDialog) {
                    $scope.cancel = function (prms) {
                        $mdDialog.cancel(prms);
                    };

                    $scope.edit = function () {
                        $scope.cancel();
                        vm.editGoal(item, key);
                    }
                }],
                templateUrl: autoNgTemplateLoaderTemplate10,
                parent: angular.element(document.body),
                clickOutsideToClose: true
            });
        },
        reachedGoal: function (vm) {
            $mdDialog.show({
                controller: ['$scope', '$mdDialog', function ($scope, $mdDialog) {
                    $scope.cancel = function (prms) {
                        $mdDialog.cancel(prms);
                    };

                    $scope.add = function () {
                        $scope.cancel();
                        vm.goToAddNewGoal();
                    }
                }],
                templateUrl: autoNgTemplateLoaderTemplate11,
                parent: angular.element(document.body),
                clickOutsideToClose: true
            });
        },
        certificationAssigned: function () {
            $mdDialog.show({
                controller: ['$scope', '$mdDialog', function ($scope, $mdDialog) {
                    $scope.cancel = function (prms) {
                        $mdDialog.cancel(prms);
                    };
                }],
                templateUrl: autoNgTemplateLoaderTemplate12,
                parent: angular.element(document.body),
                clickOutsideToClose: true
            });
        },
        completeMealPlanSetUp: function () {
            $mdDialog.show({
                controller: ['$scope', '$mdDialog', function ($scope, $mdDialog) {
                    $scope.cancel = function (prms) {
                        $mdDialog.cancel(prms);
                    };
                }],
                templateUrl: autoNgTemplateLoaderTemplate13,
                parent: angular.element(document.body),
                clickOutsideToClose: true
            });
        },
        addFood: function (foodInfo, measurements, foodTabName, doSearch) {
            $mdDialog.show({
                controller: ['$scope', '$mdDialog', 'FoodService', function ($scope, $mdDialog, FoodService) {
                    $scope.foodInfo = foodInfo;
                    $scope.measurements = measurements;
                    $scope.cancel = function () {
                        $mdDialog.cancel();
                    };
                    $scope.add = function () {
                        $scope.foodInfo.tags = [];
                        delete $scope.foodInfo['checked'];

                        FoodService.addFood($scope.foodInfo, {category: foodTabName}).then(function (res) {
                            if (res.status === 200) {
                                doSearch(foodTabName);
                                $scope.cancel();
                            }
                        });
                    };
                }],
                templateUrl: autoNgTemplateLoaderTemplate14,
                parent: angular.element(document.body),
                clickOutsideToClose: true
            });
        },
        addFoodForMeal: function (selectedFood, selectedTypeOfFood, doSearch) {
            $mdDialog.show({
                controller: ['$scope', '$mdDialog', 'FoodService', function ($scope, $mdDialog, FoodService) {
                    $scope.selectedFood = selectedFood;
                    $scope.selectedFoodAmountUnit = $scope.selectedFood.measurements[0];

                    $scope.cancel = function () {
                        $mdDialog.cancel();
                    };

                    $scope.add = function () {
                        FoodService.addFoodWithMyMetDiary($scope.selectedFood.my_net_diary_bean_id, null, {
                            unit: $scope.selectedFoodAmountUnit.unit,
                            category: selectedTypeOfFood
                        }).then(function (res) {
                            if (res.status === 200) {
                                doSearch();
                                $mdDialog.cancel();
                            }
                        });
                    };
                }],
                templateUrl: autoNgTemplateLoaderTemplate15,
                parent: angular.element(document.body),
                clickOutsideToClose: true
            });
        },
        posErrors: function (vm) {
            $mdDialog.show({
                controller: ['$scope', '$mdDialog', 'PosService', function ($scope, $mdDialog, PosService) {
                    $scope.posErrors = vm.posErrors;

                    $scope.cancel = function () {
                        $mdDialog.cancel();
                    };

                    $scope.syncToPOSManually = function () {
                        if (vm.posStatus !== 'FAILED' && vm.posStatus !== 'IN_PROGRESS') {
                            vm.connectManually();
                        } else if (vm.posStatus === 'FAILED') {
                            $mdDialog.cancel();
                            vm.connectManually();
                        }
                    };

                    $scope.connectManuallyByUser = function (error) {
                        if (!error.inProgress) {
                            error.inProgress = true;
                            PosService.connectManuallyByUser('admin', error).then(function (result) {
                                error.inProgress = false;
                                if (result.status === 200) {
                                    result.data.posFailSyncDate === null ? vm.posErrors.splice(vm.posErrors.indexOf(error), 1) : error.error = result.data.posSyncError;

                                    localStorage.setItem('posErrors', JSON.stringify(vm.posErrors));

                                    if (vm.posErrors.length === 0) {
                                        $scope.cancel();
                                        vm.getSyncStatus();
                                    }
                                }
                            });
                        }
                    };
                }],
                templateUrl: autoNgTemplateLoaderTemplate16,
                parent: angular.element(document.body),
                clickOutsideToClose: true
            });
        },
        addFoodToMeal: function (selectedFood, addedFoodList, myNetDiaryFood, roundMeal) {
            return $mdDialog.show({
                controller: ['$scope', '$mdDialog', function ($scope, $mdDialog) {
                    $scope.roundMeal = roundMeal;
                    $scope.selectedFood = selectedFood;
                    $scope.selectedFoodAmountUnit = selectedFood.wasFoundOnMyNedDiary ?
                        $scope.selectedFood.measurements[0] :
                        {
                            unit: selectedFood.amountUnit,
                            proteins: selectedFood.proteins,
                            carbs: selectedFood.carbs,
                            fats: selectedFood.fats,
                            sodium: selectedFood.sodium,
                            sugar: selectedFood.sugar,
                            veggies: selectedFood.veggies,
                            fiber: selectedFood.fiber,
                            calories: selectedFood.calorie
                        };

                    $scope.food = {selectedFoodAmountUnit: $scope.selectedFoodAmountUnit};

                    $scope.cancel = function () {
                        $mdDialog.cancel();
                    };

                    $scope.add = function () {
                        selectedFood.amountUnit = $scope.food.selectedFoodAmountUnit.unit;
                        selectedFood.proteins = $scope.food.selectedFoodAmountUnit.proteins * selectedFood.amount;
                        selectedFood.carbs = $scope.food.selectedFoodAmountUnit.carbs * selectedFood.amount;
                        selectedFood.fats = $scope.food.selectedFoodAmountUnit.fats * selectedFood.amount;
                        selectedFood.sugar = $scope.food.selectedFoodAmountUnit.sugar * selectedFood.amount;
                        selectedFood.fiber = $scope.food.selectedFoodAmountUnit.fiber * selectedFood.amount;
                        selectedFood.veggies = $scope.food.selectedFoodAmountUnit.veggies * selectedFood.amount;
                        selectedFood.sodium = $scope.food.selectedFoodAmountUnit.sodium * selectedFood.amount;
                        selectedFood.calories = $scope.food.selectedFoodAmountUnit.calories * selectedFood.amount;

                        if (selectedFood.wasFoundOnMyNedDiary) {
                            myNetDiaryFood.push(selectedFood);
                        }

                        addedFoodList.push(selectedFood);
                        $mdDialog.cancel();
                    };
                }],
                templateUrl: autoNgTemplateLoaderTemplate17,
                parent: angular.element(document.body),
                clickOutsideToClose: true
            });
        },
        addFavoriteCategory: function (favoriteCategory, getFavoriteFoodForMeal) {
            return $mdDialog.show({
                controller: ['$scope', '$mdDialog', 'FoodService', function ($scope, $mdDialog, FoodService) {
                    $scope.favoriteCategory = favoriteCategory;
                    $scope.getFavoriteFoodForMeal = getFavoriteFoodForMeal;

                    $scope.cancel = function () {
                        $mdDialog.cancel();
                    };

                    $scope.addCategory = function () {
                        var category = angular.copy(favoriteCategory);
                        if (favoriteCategory.meal === 'snack1' || favoriteCategory.meal === 'snack2' || favoriteCategory.meal === 'snack3') {
                            category.meal = 'snack';
                        }

                        FoodService.updateFavoriteCategory(category).then(function (result) {
                            $mdDialog.cancel();
                            if (result.status === 200) {
                                $scope.getFavoriteFoodForMeal();
                            }
                        });
                    };
                }],
                templateUrl: autoNgTemplateLoaderTemplate18,
                parent: angular.element(document.body),
                clickOutsideToClose: true
            });
        },
        saveRecipeAsFood: function (recipe, roundMeal, updateState) {
            return $mdDialog.show({
                controller: ['$scope', '$mdDialog', 'RecipeService', function ($scope, $mdDialog, RecipeService) {
                    $scope.measurements = ['Serving', recipe.servingMeasurement];
                    $scope.defaultServingSize = recipe.servingSize;
                    $scope.defaultAmount = recipe.amount;
                    $scope.macrosPerServing = angular.copy(recipe.macrosPerServing);
                    $scope.macrosPerUnit = {
                        proteins: recipe.totalMacros.proteins / recipe.amount,
                        carbs: recipe.totalMacros.carbs / recipe.amount,
                        fats: recipe.totalMacros.fats / recipe.amount,
                        fiber: recipe.totalMacros.fiber / recipe.amount,
                        sugar: recipe.totalMacros.sugar / recipe.amount,
                        sodium: recipe.totalMacros.sodium / recipe.amount,
                        veggies: recipe.totalMacros.veggies / recipe.amount
                    };
                    $scope.recipe = {
                        name: recipe.name,
                        amountUnit: $scope.measurements[0],
                        recommendedServingSize: recipe.servingSize,
                        proteins: recipe.totalMacros.proteins,
                        carbs: recipe.totalMacros.carbs,
                        fats: recipe.totalMacros.fats,
                        fiber: recipe.totalMacros.fiber,
                        sugar: recipe.totalMacros.sugar,
                        sodium: recipe.totalMacros.sodium,
                        veggies: recipe.totalMacros.veggies
                    };
                    $scope.roundMeal = roundMeal;

                    $scope.cancel = function () {
                        $mdDialog.cancel();
                    };

                    $scope.calculateTotalMacros = function () {
                        if ($scope.recipe.recommendedServingSize) {
                            for (var property in $scope.macrosPerUnit) {
                                $scope.recipe[property] =
                                    parseFloat((($scope.recipe.amountUnit === $scope.measurements[0] ? $scope.macrosPerServing[property] : $scope.macrosPerUnit[property]) * $scope.recipe.recommendedServingSize)
                                        .toFixed(3))
                            }
                        }
                    };

                    $scope.changeMeasurement = function () {
                        $scope.recipe.recommendedServingSize = $scope.recipe.amountUnit === $scope.measurements[0] ?
                            $scope.defaultServingSize : $scope.defaultAmount;
                        $scope.calculateTotalMacros();
                    };

                    $scope.addCategory = function () {
                        RecipeService.saveAsFood($scope.recipe).then(function (result) {
                            $mdDialog.cancel();
                            if (result.status === 200) {
                                updateState();
                                modalService.alert('Recipe added to the favorite food.', 'Success');
                            }
                        });
                    };
                }],
                templateUrl: autoNgTemplateLoaderTemplate19,
                parent: angular.element(document.body),
                clickOutsideToClose: true
            });
        },
        suggestScenario: function (suggestScenario) {
            $mdDialog.show({
                controller: ['$scope', '$mdDialog', function ($scope, $mdDialog) {
                    $scope.vm = { suggestScenario };
                    $scope.isOpenScansDetails = false;
                    $scope.cancel = function () {
                        $mdDialog.cancel();
                    };
                }],
                templateUrl: autoNgTemplateLoaderTemplate20,
                parent: angular.element(document.body),
                clickOutsideToClose: true,
            });
        },
        showCurrentSuggestScenario: function (customerId) {
            $mdDialog.show({
                controller: ['$scope', '$mdDialog', 'ScenariosService', function ($scope, $mdDialog, ScenariosService) {
                    $scope.vm = { suggestScenario: null };
                    $scope.isOpenScansDetails = false;
                    $scope.cancel = function () {
                        $mdDialog.cancel();
                    };

                    function init() {
                        ScenariosService.getSuggestionForUser(customerId).then(function (res) {
                            $scope.vm.suggestScenario = res.data;
                         });
                    }

                    init();
                }],
                templateUrl: autoNgTemplateLoaderTemplate21,
                parent: angular.element(document.body),
                clickOutsideToClose: true,
            });
        },
        addNewScan: function (scanType, isAddScanManually, id, popup) {
            $mdDialog.show({
                controller: ['$scope', '$mdDialog', 'ApiService', 'DateTimeUtil', 'constants',
                    function ($scope, $mdDialog, ApiService, DateTimeUtil, constants) {
                    $scope.currentDate = DateTimeUtil.now();
                    $scope.minDate = DateTimeUtil.minusYears($scope.currentDate, 100);
                    $scope.isAddScanManually = isAddScanManually;
                    $scope.scanType = scanType;
                    $scope.inbodyTypes = constants.inbodyTypes;
                    $scope.inbodyDataList = {
                        scanType: scanType,
                        bodyFatMass: 0,
                        leanBodyMass: 0,
                        totalBodyWatter: 0,
                        ecwtbw: 0
                    };

                    $scope.cancel = function () {
                        $mdDialog.cancel();
                    };

                    $scope.createInbodyDataManually = function () {
                        var inbodyData = Object.assign({}, $scope.inbodyDataList);
                        inbodyData.datetimes = DateTimeUtil.formatToDatetime(inbodyData.scanDate, inbodyData.scanTime);
                        delete inbodyData.scanTime;
                        delete inbodyData.scanDate;

                        ApiService.post('api/coach/inbody/manual/' + id, inbodyData).then(
                            function () {
                                popup({status: 'Success'});
                            },
                            function (error) {
                                modalService.alert(error.data.message, 'Error');
                            }
                        );
                    };

                    $scope.setScanDate = function () {
                        $scope.inbodyDataList.scanDate = moment($scope.scanDate).format('YYYY-MM-DD');
                        $scope.$evalAsync();
                    };

                    $scope.calculateBodyFatAndMass = function () {
                        $scope.inbodyDataList.bodyFatMass = ($scope.inbodyDataList.weight && $scope.inbodyDataList.percentBodyFat) ?
                            +(($scope.inbodyDataList.weight * ($scope.inbodyDataList.percentBodyFat / 100)).toFixed(1)) : null;
                        $scope.inbodyDataList.leanBodyMass = ($scope.inbodyDataList.weight && $scope.inbodyDataList.bodyFatMass) ?
                            +(($scope.inbodyDataList.weight - $scope.inbodyDataList.bodyFatMass).toFixed(1)) : null;
                    };

                    $scope.calculateTotalBodyWatter = function () {
                        $scope.inbodyDataList.totalBodyWatter = ($scope.inbodyDataList.inWatter && $scope.inbodyDataList.exWatter) ?
                            +(($scope.inbodyDataList.inWatter + $scope.inbodyDataList.exWatter).toFixed(1)) : null;
                        $scope.inbodyDataList.ecwtbw = ($scope.inbodyDataList.exWatter && $scope.inbodyDataList.totalBodyWatter) ?
                            +(($scope.inbodyDataList.exWatter / $scope.inbodyDataList.totalBodyWatter).toFixed(3)) : null;
                    };
                }],
                templateUrl: autoNgTemplateLoaderTemplate22,
                parent: angular.element(document.body),
                clickOutsideToClose: true,
            });
        },
        checkInDialog: function (inbodyType, entryFields, id, popup) {
            $mdDialog.show({
                controller: ['$scope', '$mdDialog', 'ApiService', 'DateTimeUtil', 'constants',
                    function ($scope, $mdDialog, ApiService, DateTimeUtil, constants) {
                    $scope.bodyFatMethods = constants.bodyFatMethods;
                    $scope.currentDate = DateTimeUtil.now();
                    $scope.minDate = DateTimeUtil.minusYears($scope.currentDate, 100);
                    $scope.inbodyDataList = {
                        scanType: inbodyType
                    };
                    $scope.scanDateIsOpen = false;
                    $scope.entryFields = entryFields;

                    $scope.cancel = function () {
                        $mdDialog.cancel();
                    };

                    $scope.addCheckIn = function () {
                        var inbodyData = angular.copy($scope.inbodyDataList);

                        var time = inbodyData.scanTime;
                        var date = angular.copy($scope.scanDate);
                        inbodyData.datetimes = DateTimeUtil.formatToDatetime(date, time);

                        delete inbodyData.scanTime;
                        delete inbodyData.scanDate;

                        ApiService.post('api/coach/inbody/manual/' + id, inbodyData).then(
                            function () {
                                $mdDialog.cancel();
                                popup({status: 'Success'});
                            }, function (error) {
                                modalService.alert(error.data.message, 'Error');
                            }
                        );
                    };

                    $scope.setScanDate = function () {
                        $scope.inbodyDataList.scanDate = moment($scope.scanDate).format('YYYY-MM-DD');
                        $scope.$evalAsync();
                    };

                    $scope.calculateBodyFatAndMass = function () {
                        $scope.inbodyDataList.bodyFatMass = ($scope.inbodyDataList.weight && $scope.inbodyDataList.percentBodyFat) ?
                            +(($scope.inbodyDataList.weight * ($scope.inbodyDataList.percentBodyFat / 100)).toFixed(1)) : null;
                        $scope.inbodyDataList.leanBodyMass = ($scope.inbodyDataList.weight && $scope.inbodyDataList.bodyFatMass) ?
                            +(($scope.inbodyDataList.weight - $scope.inbodyDataList.bodyFatMass).toFixed(1)) : null;
                    };

                    $scope.calculateTotalBodyWatter = function () {
                        $scope.inbodyDataList.totalBodyWatter = ($scope.inbodyDataList.inWatter && $scope.inbodyDataList.exWatter) ?
                            +(($scope.inbodyDataList.inWatter + $scope.inbodyDataList.exWatter).toFixed(1)) : null;
                        $scope.inbodyDataList.ecwtbw = ($scope.inbodyDataList.exWatter && $scope.inbodyDataList.totalBodyWatter) ?
                            +(($scope.inbodyDataList.exWatter / $scope.inbodyDataList.totalBodyWatter).toFixed(3)) : null;
                    };
                }],
                templateUrl: autoNgTemplateLoaderTemplate23,
                parent: angular.element(document.body),
                clickOutsideToClose: true,
            });
        },
        mealInfo: function (mealDayId, meal) {
            $mdDialog.show({
                controller: ['$scope', '$mdDialog', 'CoachService', function ($scope, $mdDialog, CoachService) {
                    $scope.mealInfo = [];
                    $scope.infoSpinner = {active: false};

                    init();

                    $scope.close = function () {
                        $mdDialog.cancel();
                    };

                    function init() {
                        $scope.infoSpinner.active = true;
                        CoachService.getComplianceDetailForSpecificDayForSpecificMeal(mealDayId, meal).then(function (result) {
                            $scope.infoSpinner.active = false;
                            if (result.status === 200) {
                                $scope.mealInfo = result.data;
                            }
                        });
                    }
                }],
                templateUrl: autoNgTemplateLoaderTemplate24,
                clickOutsideToClose: true
            });
        },
        reassignCoaches: function (filteredCustomers, applyFilters) {
            $mdDialog.show({
                controller: ['$scope', '$mdDialog', 'CustomersFilterService',
                    function ($scope, $mdDialog, CustomersFilterService) {
                        $scope.availableCoaches = [];
                        $scope.newCoach = {user: {}};
                        $scope.spinner = {active: false};

                        init();

                        $scope.close = function () {
                            $mdDialog.cancel();
                        };

                        $scope.reassignCoach = function () {
                            var customerIds = [];

                            for (var i in filteredCustomers) {
                                if (filteredCustomers[i].checked) {
                                    customerIds.push(filteredCustomers[i].id);
                                }
                            }

                            CustomersFilterService.reassignCoachForCustomers($scope.newCoach.user.id, {ids: customerIds}).then(function (res) {
                                if (res.status === 200) {
                                    $scope.close();
                                    applyFilters(0);
                                }
                            });
                        };

                        function init() {
                            $scope.spinner.active = true;
                            CustomersFilterService.getCoachesForReassigning().then(function (result) {
                                $scope.spinner.active = false;
                                if (result.status === 200) {
                                    $scope.availableCoaches = result.data.names;
                                }
                            })
                        }
                    }],
                templateUrl: autoNgTemplateLoaderTemplate25,
                clickOutsideToClose: true
            });
        },
        getScanData: function (prefillReading) {
            return $mdDialog.show({
                controller: ['$scope', '$mdDialog', function ($scope, $mdDialog) {
                    $scope.prefillReading = prefillReading;

                    $scope.cancel = function () {
                        $mdDialog.cancel();
                    };
                }],
                templateUrl: autoNgTemplateLoaderTemplate26,
                parent: angular.element(document.body),
                clickOutsideToClose: true
            });
        },
        getDetailInfo: function (info) {
            return $mdDialog.show({
                controller: ['$scope', '$mdDialog', function ($scope, $mdDialog) {
                    $scope.info = info;

                    $scope.cancel = function () {
                        $mdDialog.cancel();
                    };
                }],
                templateUrl: autoNgTemplateLoaderTemplate27,
                parent: angular.element(document.body),
                clickOutsideToClose: true
            });
        },
        getGoalExplanation: function (userGoal, metrics, goalError, bodyFatGoalError, form, onRemove) {
            return $mdDialog.show({
                controller: ['$scope', '$mdDialog', function ($scope, $mdDialog) {
                    $scope.userGoal = userGoal;
                    $scope.metrics = metrics;
                    $scope.goalError = goalError;
                    $scope.bodyFatGoalError = bodyFatGoalError;
                    $scope.explanation = getExplanation();

                    $scope.adjust = function (goal, bodyFat) {
                        if (typeof (onRemove) === 'function') {
                            onRemove(form, goal, bodyFat);
                        }
                        $scope.cancel();
                    };

                    $scope.cancel = function () {
                        $mdDialog.cancel();
                    };

                    $scope.isProceedAvailable = function () {
                        return !(userGoal.goal.id === 1 && (metrics.changes.leanBodyMass < 0 || metrics.changes.bodyFat > 0));
                    };

                    function getExplanation() {
                        var error = null;
                        if (userGoal.goal.id === 1 && metrics.changes.leanBodyMass < 0) {
                            error = 'Error: Lean Body Mass Decrease is not allowed. Please adjust your body fat %' +
                                'goal and weight goals.';
                        } else if (userGoal.goal.id === 1 && metrics.changes.leanBodyMass > 0 && metrics.changes.bodyFat < 0) {
                            error = 'Great! You selected a Goal to Lose ' + Math.abs(metrics.changes.bodyFat) +
                                ' lbs of Fat and gain ' + metrics.changes.leanBodyMass + ' lbs of lean body mass!';
                        } else if (userGoal.goal.id === 1 && metrics.changes.bodyFat > 0) {
                            error = 'You selected a Body Fat ' + metrics.goal.percentBodyFat + '% and Weight ' +
                                metrics.goal.weight + ' that results in gaining FAT. Please readjust your target Weight and Body Fat.';
                        } else if (userGoal.goal.id === 2 && metrics.changes.leanBodyMass < 0) {
                            error = 'You selected goals that require you lose Lean muscle. Do you want to proceed anyway?';
                        } else if (userGoal.goal.id === 3 && metrics.changes.bodyFat / metrics.changes.leanBodyMass >= 2.5) {
                            error = 'You selected a Goal to Gain ' + Math.abs(metrics.changes.leanBodyMass) +
                                ' lbs of Lean Muscle;' + Math.abs(metrics.changes.bodyFat) + ' lbs of Fat and ' +
                                Math.abs(metrics.changes.weight) + ' lbs of Weight.';
                        } else if (userGoal.goal.id === 3 && metrics.changes.bodyFat / metrics.changes.leanBodyMass < 2.5) {
                            error = 'You selected a Goal to Gain Lean Muscle. Your Desired Body Fat ' +
                                metrics.goal.percentBodyFat + '% and Weight ' + metrics.goal.weight +
                                ' do not correspond with a 2.5:1 Ratio of Muscle to Fat.';
                        } else if (userGoal.goal.id === 4) {
                            error = 'You selected a goal to gain muscle and fat; proceed to macro set up.';
                        }
                        return error;
                    }
                }],
                templateUrl: autoNgTemplateLoaderTemplate28,
                parent: angular.element(document.body),
                clickOutsideToClose: false
            });
        },
        getSubscriptionInfo: function ({ title, info }) {
            return $mdDialog.show({
                controller: ['$scope', '$mdDialog', function ($scope, $mdDialog) {
                    $scope.title = title;
                    $scope.description = info;

                    $scope.cancel = function () {
                        $mdDialog.cancel();
                    };
                }],
                templateUrl: autoNgTemplateLoaderTemplate29,
                parent: angular.element(document.body),
                clickOutsideToClose: true
            });
        },
        getCorporatePlansInfo: function (isChildEntity) {
            return $mdDialog.show({
                controller: ['$scope', '$mdDialog', function ($scope, $mdDialog) {
                    $scope.isChildEntity = isChildEntity;
                    $scope.cancel = function () {
                        $mdDialog.cancel();
                    };
                }],
                templateUrl: autoNgTemplateLoaderTemplate30,
                parent: angular.element(document.body),
                clickOutsideToClose: true
            });
        },
        //  status: 'Success' | 'Failed'
        // can not delete status and use only hasScenario because possible that status is 'Failed' and hasScenario is true
        changeMealPlanDialog: function (customer, status, currentTab, hasScenario, scanDates) {
            return $mdDialog.show({
                controller: ['$rootScope', '$scope', '$mdDialog', 'constants', function ($rootScope, $scope, $mdDialog, constants) {
                    $scope.customer = customer;
                    $scope.status = status;
                    $scope.scanDates = scanDates;
                    
                    $scope.cancel = function () {
                        $mdDialog.cancel();
                    };

                    function changeToCheckInHistoryTab() {
                        $rootScope.$broadcast('changeTab', {
                            mainTabName: constants.tabNames.CHECK_IN_HISTORY,
                        });
                    }
                    
                    function changeToInBodyIndicatorsTab() {
                        $rootScope.$broadcast('changeTab', {
                            mainTabName: constants.tabNames.IN_BODY_INDICATORS,
                        });
                    }
                    

                    $scope.change = function () {
                        // var editPage = constants.tabNames.EDIT_MACRO_PLAN;
                        // if (hasScenario && status === 'Success') {
                        //     editPage = constants.tabNames.SUGGESTED_MEAL_PLAN;
                        // }
                        // currentTab === editPage ?
                        //     $rootScope.$broadcast('loadScans') :
                        //     $rootScope.$broadcast('changeTab', {
                        //         mainTabName: constants.tabNames.MEAL_PLAN,
                        //         tabName: editPage
                        //     });

                        const isNonInbody = customer.customer.inbodyType === constants.inbodyTypes.NON_INBODY.value;

                        if (isNonInbody) {
                            changeToCheckInHistoryTab();
                        } else if (hasScenario && status === 'Success') {
                            changeToInBodyIndicatorsTab();
                            modalService.showCurrentSuggestScenario(customer.id);
                        } else {
                            changeToInBodyIndicatorsTab();
                        }

                        $mdDialog.cancel();
                    };
                }],
                template: `<ngz-performance-metrics-overview-modal 
                                customer-id="customer.id"
                                scan-dates="scanDates"
                                on-review="change()"
                                on-close="cancel()"
                            >
                            </ngz-performance-metrics-overview-modal>`,
                parent: angular.element(document.body),
                clickOutsideToClose: false,
                hasBackdrop: false
            });
        },
        unlinkLightSpeedDialog: function (unlink) {
            return $mdDialog.show({
                controller: ['$scope', '$mdDialog', function ($scope, $mdDialog) {
                    $scope.cancel = function () {
                        $mdDialog.cancel();
                    };

                    $scope.unlink = function () {
                        unlink();
                        $mdDialog.cancel();
                    };
                }],
                templateUrl: autoNgTemplateLoaderTemplate31,
                parent: angular.element(document.body),
                clickOutsideToClose: true
            });
        },
        canceledEntityPlanInfoDialog: function (canceledPlan, onRemove) {
            return $mdDialog.show({
                controller: ['$scope', '$mdDialog', function ($scope, $mdDialog) {
                    $scope.canceledPlan = canceledPlan;

                    $scope.cancel = function () {
                        if (typeof (onRemove) === 'function') {
                            onRemove();
                        }
                        $mdDialog.cancel();
                    };
                }],
                templateUrl: autoNgTemplateLoaderTemplate32,
                parent: angular.element(document.body),
                clickOutsideToClose: true
            });
        },
        changeMealTime: function (meal, workoutDays, selectedMealPlans, mealTime, mealIndex) {
            return $mdDialog.show({
                controller: ['$scope', '$mdDialog', function ($scope, $mdDialog) {
                    $scope.meal = meal;
                    $scope.workoutDays = workoutDays;
                    $scope.selectedMealPlans = selectedMealPlans;
                    $scope.mealTime = mealTime;

                    init();

                    function init() {
                        var currentTime = '';
                        for (var i = 0; i < $scope.mealTime[mealIndex].time.length; i++) {
                            if ($scope.mealTime[mealIndex].time[i] !== 'n/a') {
                                currentTime = $scope.mealTime[mealIndex].time[i];
                                break;
                            }
                        }
                        $scope.newTimeHour = currentTime.slice(0, currentTime.length - 5);
                        $scope.newTimeMinute = currentTime.slice(currentTime.length - 4, currentTime.length - 2);
                        $scope.newTimeDayPart = currentTime.slice(currentTime.length - 2, currentTime.length);
                    }

                    $scope.setAnteMeridiem = function () {
                        $scope.newTimeDayPart = 'am';
                    };

                    $scope.setPostMeridiem = function () {
                        $scope.newTimeDayPart = 'pm';
                    };

                    $scope.increaseTimeHour = function () {
                        if ($scope.newTimeHour < 12) {
                            $scope.newTimeHour++;
                        }
                    };

                    $scope.decreaseTimeHour = function () {
                        if ($scope.newTimeHour > 1) {
                            $scope.newTimeHour--;
                        }
                    };

                    $scope.increaseTimeMinute = function () {
                        if ($scope.newTimeMinute < 60) {
                            $scope.newTimeMinute++;
                        }

                        if ($scope.newTimeMinute < 10) {
                            $scope.newTimeMinute = '0' + $scope.newTimeMinute;
                        }
                    };

                    $scope.decreaseTimeMinute = function () {
                        $scope.newTimeMinute = parseFloat($scope.newTimeMinute);

                        if ($scope.newTimeMinute > 0) {
                            $scope.newTimeMinute--;
                        }

                        if ($scope.newTimeMinute < 10) {
                            $scope.newTimeMinute = '0' + $scope.newTimeMinute;
                        }
                    };

                    // same exist in CustomerMealTimeTabController if change here change there
                    // mealIndex is index of row, 0 = Breakfast, 1 = Snack....
                    // dayIndex is index of column, impotent sunday is first column in ui but index is 6, 0 = monday, 1 = tuesday...
                    function isTimeFieldNonActive(dayIndex) {
                        const isInMealPlanOnlyTwoSnacks = $scope.selectedMealPlans[dayIndex].mealPlan === 5;
                        const isFirstSnackMeal = mealIndex === 1;
                        const isDisabledFirstSnackMeal = isInMealPlanOnlyTwoSnacks && isFirstSnackMeal;

                        const isNotWorkoutDay = !$scope.workoutDays[dayIndex];
                        const isPreOrPostWorkoutMeal = mealIndex === 6 || mealIndex === 7;
                        const isDisabledWorkoutMeal = isNotWorkoutDay && isPreOrPostWorkoutMeal;

                        return isDisabledFirstSnackMeal || isDisabledWorkoutMeal;   
                    }

                    $scope.setNewMealTime = function () {
                        for (var j = 0; j < 7; j++) {
                            if (!isTimeFieldNonActive(j)) {
                                $scope.mealTime[mealIndex].time[j] =
                                    $scope.newTimeHour + ':' + $scope.newTimeMinute + $scope.newTimeDayPart;
                            }
                        }
                        $mdDialog.cancel();
                    };

                    $scope.cancel = function () {
                        $mdDialog.cancel();
                    };
                }],
                templateUrl: autoNgTemplateLoaderTemplate33,
                parent: angular.element(document.body),
                clickOutsideToClose: false
            });
        },
        addNewComplianceScoreDialog: function (isEdit, currentScore, save) {
            return $mdDialog.show({
                controller: ['$scope', '$mdDialog', function ($scope, $mdDialog) {
                    $scope.isEdit = isEdit;
                    $scope.currentScore = currentScore;

                    $scope.save = function () {
                        $scope.cancel();
                        save();
                    };

                    $scope.cancel = function () {
                        $mdDialog.cancel();
                    };
                }],
                templateUrl: autoNgTemplateLoaderTemplate34,
                parent: angular.element(document.body),
                clickOutsideToClose: true
            });
        },
        editMealsDialog: function (changeWorkoutPlan, changeNonWorkoutPlan, currentMealPlan, onChange) {
            return $mdDialog.show({
                controller: ['$scope', '$mdDialog', function ($scope, $mdDialog) {
                    $scope.changeWorkoutPlan = changeWorkoutPlan;
                    $scope.changeNonWorkoutPlan = changeNonWorkoutPlan;
                    $scope.currentMealPlan = currentMealPlan;

                    $scope.change = function () {
                        if (typeof (onChange) === 'function') {
                            if ($scope.changeWorkoutPlan || $scope.changeNonWorkoutPlan) {
                                onChange();
                            }
                        }
                        $mdDialog.cancel();
                    };

                    $scope.cancel = function () {
                        $mdDialog.cancel();
                    };
                }],
                templateUrl: autoNgTemplateLoaderTemplate35,
                parent: angular.element(document.body),
                clickOutsideToClose: true
            });
        },
        editWorkoutDaysDialog: function (newWorkoutDays, onCancel, onChange) {
            return $mdDialog.show({
                controller: ['$scope', '$mdDialog', function ($scope, $mdDialog) {
                    $scope.newWorkoutDays = newWorkoutDays;

                    $scope.change = function () {
                        onChange();
                    };

                    $scope.cancel = function () {
                        if (typeof (onCancel) === 'function') {
                            onCancel();
                        }
                        $mdDialog.cancel();
                    };
                }],
                templateUrl: autoNgTemplateLoaderTemplate36,
                parent: angular.element(document.body),
                clickOutsideToClose: false
            });
        },
        saveConfirmModal: function (onSave, title) {
            return $mdDialog.show({
                controller: ['$scope', '$mdDialog', function ($scope, $mdDialog) {
                    $scope.title = title;

                    $scope.save = function (immediately) {
                        $mdDialog.cancel();
                        onSave(null, immediately);
                    };

                    $scope.cancel = function () {
                        $mdDialog.cancel();
                    };
                }],
                templateUrl: autoNgTemplateLoaderTemplate37,
                parent: angular.element(document.body),
                clickOutsideToClose: true
            })
        },
        selectIngredient: function (onAdd, roundMeal) {
            return $mdDialog.show({
                controller: ['$scope', '$mdDialog', 'FoodService', 'utilService',
                    function ($scope, $mdDialog, FoodService, utilService) {

                        $scope.foodSpinner = {active: false};
                        $scope.roundMeal = roundMeal;

                        $scope.getFoodWithCategory = getFoodWithCategory;
                        $scope.newFoodTab = newFoodTab;
                        $scope.doSearch = doSearch;
                        $scope.initInfiniteItems = initInfiniteItems;
                        $scope.formatDate = formatDate;
                        $scope.add = add;
                        $scope.cancel = cancel;

                        newFoodTab('core');

                        function getFoodWithCategory() {
                            $scope.foodList = [];
                            $scope.isNextPageExist = true;
                            $scope.currentPage = 0;
                            $scope.initInfiniteItems();
                        }

                        function newFoodTab(value) {
                            $scope.foodTab = value;
                            $scope.query = '';
                            $scope.getFoodWithCategory();
                        }

                        function doSearch() {
                            if ($scope.query === '') {
                                $scope.getFoodWithCategory();
                            } else {
                                $scope.foodList = [];
                                $scope.isNextPageExist = true;
                                $scope.currentPage = 0;
                                $scope.foodSpinner.active = true;

                                FoodService.searchFoods({
                                    q: $scope.query || '',
                                    src: $scope.foodTab,
                                    my_food_diary: true
                                }).then(function (result) {
                                    $scope.foodSpinner.active = false;
                                    if (result.status === 200) {
                                        $scope.foodList = result.data.foods;
                                    }
                                });
                            }
                        }

                        function initInfiniteItems() {
                            if ($scope.isNextPageExist) {
                                $scope.foodSpinner.active = true;
                                FoodService.getAllFoods114($scope.foodTab, {page: $scope.currentPage, limit: 10})
                                    .then(function (result) {
                                        $scope.foodSpinner.active = false;
                                        if (result.status === 200) {
                                            $scope.foodList = $scope.foodList.concat(result.data.foods);
                                            $scope.currentPage = result.data.page + 1;
                                            $scope.isNextPageExist = result.data.more;
                                        }
                                    });
                            }
                        }

                        function formatDate(category, format) {
                            return utilService.formatDate(category, format);
                        }

                        function add(ingredient) {
                            modalService.ingredientDetails(ingredient, roundMeal, onAdd, null);
                        }

                        function cancel() {
                            $mdDialog.cancel();
                        }
                    }],
                templateUrl: autoNgTemplateLoaderTemplate38,
                parent: angular.element(document.body),
                clickOutsideToClose: true
            })
        },
        ingredientDetails: function (ingredient, roundMeal, onSave, onDelete) {
            return $mdDialog.show({
                controller: ['$scope', '$mdDialog', function ($scope, $mdDialog) {
                    $scope.isEditMode = onDelete !== null;
                    ingredient.proteins /= ingredient.amount;
                    ingredient.carbs /= ingredient.amount;
                    ingredient.fats /= ingredient.amount;
                    ingredient.sodium /= ingredient.amount;
                    ingredient.sugar /= ingredient.amount;
                    ingredient.veggies /= ingredient.amount;
                    ingredient.fiber /= ingredient.amount;
                    ingredient.calorie /= ingredient.amount;
                    $scope.selectedFood = ingredient;
                    $scope.selectedFoodAmountUnit = ingredient.wasFoundOnMyNedDiary ?
                        ($scope.isEditMode ? ingredient.measurements.find(function (obj) {
                                return obj.unit === ingredient.amountUnit
                            }) :
                            ingredient.measurements[0]) : {
                            unit: ingredient.amountUnit,
                            proteins: ingredient.proteins,
                            carbs: ingredient.carbs,
                            fats: ingredient.fats,
                            sodium: ingredient.sodium,
                            sugar: ingredient.sugar,
                            fiber: ingredient.fiber,
                            calories: ingredient.calorie
                        };
                    $scope.defaultVeggiesAmount = ingredient.veggies;
                    $scope.selectedFoodAmountUnit.veggies = ingredient.veggies;
                    $scope.food = {selectedFoodAmountUnit: $scope.selectedFoodAmountUnit};
                    $scope.roundMeal = roundMeal;
                    $scope.isVegetable = $scope.defaultVeggiesAmount > 0;
                    $scope.isIngredientDialog = true;

                    $scope.add = add;
                    $scope.confirmRemoving = confirmRemoving;
                    $scope.setVegetableValue = setVegetableValue;
                    $scope.cancel = cancel;

                    function add() {
                        if ($scope.selectedFood.veggies !== $scope.food.selectedFoodAmountUnit.veggies) {
                            $scope.food.selectedFoodAmountUnit.veggies /= $scope.selectedFood.amount;
                        }

                        var food = $scope.food.selectedFoodAmountUnit;
                        $scope.selectedFood.amountUnit = food.unit;
                        $scope.selectedFood.proteins = food.proteins * $scope.selectedFood.amount;
                        $scope.selectedFood.carbs = food.carbs * $scope.selectedFood.amount;
                        $scope.selectedFood.fats = food.fats * $scope.selectedFood.amount;
                        $scope.selectedFood.sugar = food.sugar * $scope.selectedFood.amount;
                        $scope.selectedFood.fiber = food.fiber * $scope.selectedFood.amount;
                        $scope.selectedFood.veggies = food.veggies * $scope.selectedFood.amount;
                        $scope.selectedFood.sodium = food.sodium * $scope.selectedFood.amount;
                        $scope.selectedFood.calorie = food.calories * $scope.selectedFood.amount;
                        onSave($scope.selectedFood);
                        cancel();
                    }

                    function confirmRemoving() {
                        modalService.confirm(
                            'Are you sure you want to delete ingredient?',
                            onDelete,
                            ingredient,
                            null,
                            'Cancel',
                            'Delete'
                        )
                    }

                    function setVegetableValue(value) {
                        $scope.food.selectedFoodAmountUnit.veggies = value ? $scope.defaultVeggiesAmount : 0;
                        $scope.isVegetable = value;
                    }

                    function cancel() {
                        $mdDialog.cancel();
                    }
                }],
                templateUrl: autoNgTemplateLoaderTemplate39,
                parent: angular.element(document.body),
                clickOutsideToClose: true
            })
        },
        showStripe: function (entityId, callback, paymentRequestBody) {
            return $mdDialog.show({
                controller: ['$scope', '$mdDialog', 'PaymentService', function ($scope, $mdDialog, PaymentService) {
                    const orderData = entityId ? {entityId} : paymentRequestBody;
                    $scope.setupElements = setupElements;
                    $scope.makePaymentRecurring = makePaymentRecurring;
                    $scope.onSuccess = onSuccess;

                    getIntentPayment().then(res => {
                        if (res.status === 200) {
                            return $scope.setupElements(res.data);
                        }
                    })
                      .then(stripeData => {
                          document.querySelector("#submit").addEventListener("click", function(evt) {
                              evt.preventDefault();
                              // Initiate payment
                              makePaymentRecurring(stripeData.stripe, stripeData.card, stripeData.clientSecret);
                          });
                      });

                    function getIntentPayment() {
                        if (entityId) {
                            return PaymentService.entityPaymentIntent(entityId);
                        } else {
                            return PaymentService.createPaymentIntent(orderData);
                        }
                    }

                    function setupElements(data) {
                        if (entityId) {
                            $scope.stripe = Stripe(data.publishableKey);
                        } else {
                            $scope.stripe = Stripe(data.publishableKey, {stripeAccount: data.connectedAccountId});
                        }
                        const elements = $scope.stripe.elements();
                        document.getElementById("order-amount").innerHTML = data.orderAmount/100 + " $";
                        const card = elements.create("card");
                        card.mount("#card-element");

                        return {
                            stripe: $scope.stripe,
                            card: card,
                            clientSecret: data.clientSecret,
                            id: data.id
                        };
                    }

                    function makePaymentRecurring(stripe, card, clientSecret) {
                        var isSavingCard = true;
                        // var isSavingCard = document.querySelector("#save-card").checked;

                        var data = {
                            card: card,
                            billing_details: {}
                        };

                        changeLoadingState(true);

                        // Initiate the payment.
                        // If authentication is required, confirmCardPayment will automatically display a modal

                        // Use setup_future_usage to save the card and tell Stripe how you plan to charge it in the future
                        $scope.stripe.confirmCardPayment(clientSecret, {
                            payment_method: data,
                            setup_future_usage: isSavingCard ? "off_session" : ""
                        })
                          .then(function (result) {
                              if (result.error) {
                                  changeLoadingState(false);
                                  var errorMsg = document.querySelector(".sr-field-error");
                                  errorMsg.textContent = result.error.message;
                                  setTimeout(function () {
                                      errorMsg.textContent = "";
                                  }, 4000);
                              } else {
                                  orderComplete(clientSecret);
                                  // There's a risk the customer will close the browser window before the callback executes
                                  // Fulfill any business critical processes async using a
                                  // In this sample we use a webhook to listen to payment_intent.succeeded
                                  // and add the PaymentMethod to a Customer
                              }
                          });
                    }

                    function orderComplete(clientSecret) {
                        $scope.stripe.retrievePaymentIntent(clientSecret).then(function(result) {
                            var paymentIntent = result.paymentIntent;
                            orderData.paymentMethod = paymentIntent.payment_method;
                            processEntityPayment(orderData, paymentIntent);
                        });
                    }

                    function processEntityPayment(orderData, paymentIntent) {
                        if (entityId) {
                            PaymentService.processEntityPayment(orderData).then(() => {
                                changeStripeView(paymentIntent);
                            });
                        } else {
                            PaymentService.processPayment(orderData).then(res => {
                                changeStripeView(paymentIntent);
                                if (res.status === 400 && res.data.message === 'User has renewal subscription.' +
                                  ' If you wish to purchase another plan, please cancel current subscription.') {
                                    callback();
                                }
                            });
                        }
                    }

                    function changeStripeView(paymentIntent) {
                        document.querySelectorAll(".payment-view").forEach(function (view) {
                            view.classList.add("hidden");
                        });
                        document.querySelectorAll(".completed-view").forEach(function (view) {
                            view.classList.remove("hidden");
                        });
                        document.querySelector(".status").textContent =
                          paymentIntent.status === "succeeded" ? "succeeded" : "did not complete";
                    }

                    function changeLoadingState(isLoading) {
                        if (isLoading) {
                            document.querySelector(".stripe-modal button").disabled = true;
                            document.querySelector("#spinner").classList.remove("hidden");
                            document.querySelector("#button-text").classList.add("hidden");
                        } else {
                            document.querySelector(".stripe-modal button").disabled = false;
                            document.querySelector("#spinner").classList.add("hidden");
                            document.querySelector("#button-text").classList.remove("hidden");
                        }
                    }

                    function onSuccess() {
                        $mdDialog.cancel();
                        callback();
                    }
                }],
                templateUrl: autoNgTemplateLoaderTemplate40,
                parent: angular.element(document.body),
                clickOutsideToClose: true
            });
        }
    };

    return modalService;
}
