import React, { useEffect, useState } from 'react';

import { angularize } from 'react-in-angularjs';
import { FormProvider, useForm } from 'react-hook-form';
import { useUpdateEffect } from 'react-use';

import { SystemProviders } from '../../../../../shared/providers';
import { InbodyIndicatorsTab } from './InbodyIndicatorsTab/InbodyIndicatorsTab';
import { scanServices } from '../../../../services/scanServices';
import { InbodyIndicatorInfo } from '../../../../interfaces/inbodyIndicator';
import { Option } from '../../../../../general-types';
import { InbodyType } from '../../../../../shared/constants/inbody-info/InbodyType';
import { transformToOptions } from '../../../../../shared/utils/arrayUtils/transformToOptions/transformToOptions';
import { MealPlanOnScanDayInfo } from '../../../../interfaces/mealPlanOnScanDayInfo';

type InbodyIndicatorsFormValues = {
  scanDate: string;
  inbodyType: InbodyType | 'ALL';
};

type NgzInbodyIndicatorsTabProps = {
  customerId: number;
  age: number;
  gender: string;
  height: number;
  inbodyId: string;
  inbodyType: InbodyType;
};
// TODO: copy from checkInHistoryTab
// need to refactor
const NgzInbodyIndicatorsTab = ({
  customerId,
  age,
  gender,
  height,
  inbodyId,
  inbodyType,
}: NgzInbodyIndicatorsTabProps) => {
  const [customerInbodyIndicators, setInbodyIndicators] = useState<
    InbodyIndicatorInfo[] | null
  >(null);
  const [inbodyTypeOptions, setInbodyTypeOptions] = useState<Option[]>([]);
  const [mealPlansOnScanDay, setMealPlansOnScanDay] = useState<MealPlanOnScanDayInfo[]>(
    [],
  );
  const formMethods = useForm<InbodyIndicatorsFormValues>({
    defaultValues: { inbodyType: 'ALL', scanDate: '' },
  });
  const { watch, resetField } = formMethods;

  const selectedInbodyType = watch('inbodyType');

  const getInbodyIndicators = async () => {
    const { inbodyIndicators } = await scanServices.getInbodyIndicators(
      customerId,
      selectedInbodyType,
    );

    setInbodyIndicators(inbodyIndicators);
  };

  const getInbodyTypeOptions = async () => {
    const inbodyTypes = await scanServices.getCustomerInbodyTypeOptions(customerId);
    const options = transformToOptions({
      list: inbodyTypes,
      valueKey: 'userInbodyType',
      labelKey: 'label',
    });

    setInbodyTypeOptions(options);
  };

  const getMealPlansOnScanDay = async () => {
    const mealPlans = await scanServices.getMealPlansOnScanDay(customerId);

    setMealPlansOnScanDay(mealPlans);
  };

  useUpdateEffect(() => {
    resetField('scanDate'); // TODO fix shift, create on utils for inbody and date change
    getInbodyIndicators();
  }, [selectedInbodyType]);

  useEffect(() => {
    getInbodyIndicators();
    getInbodyTypeOptions();
    getMealPlansOnScanDay();
  }, []);

  return (
    <SystemProviders>
      <FormProvider {...formMethods}>
        {customerInbodyIndicators && (
          <InbodyIndicatorsTab
            customerInbodyIndicators={customerInbodyIndicators}
            mealPlansOnScanDay={mealPlansOnScanDay}
            inbodyTypeOptions={inbodyTypeOptions}
            customerInfo={{ id: customerId, age, gender, height, inbodyId, inbodyType }}
          />
        )}
      </FormProvider>
    </SystemProviders>
  );
};

angularize(
  NgzInbodyIndicatorsTab,
  'ngzInbodyIndicatorsTab',
  angular.module('app.inbody'),
  {
    customerId: '<',
    age: '<',
    gender: '<',
    height: '<',
    inbodyId: '<',
    inbodyType: '<',
  },
);

export { NgzInbodyIndicatorsTab };
