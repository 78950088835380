import React from 'react';

import {
  SelectSubscriptionCard,
  SelectSubscriptionCardProps,
} from './SelectSubscriptionCard/SelectSubscriptionCard';
import { PromoCodeCard, PromoCodeCardProps } from './PromoCodeCard/PromoCodeCard';

type SelectSubscriptionBlockProps = PromoCodeCardProps & SelectSubscriptionCardProps;

const SelectSubscriptionBlock = ({
  onApplyPromoCode,
  ...props
}: SelectSubscriptionBlockProps) => {
  return (
    <>
      <SelectSubscriptionCard {...props} />
      <PromoCodeCard onApplyPromoCode={onApplyPromoCode} />
    </>
  );
};

export { SelectSubscriptionBlock };
