import { apiService } from '../../shared/services/apiService';
import {
  CustomerQuestionnaire,
  QuestionnaireInfoResponse,
  QuestionWithAnswer,
} from '../interfaces/QuestionnaireInfo';

export const questionnaireService = {
  async getQuestions(entityId?: number): Promise<QuestionnaireInfoResponse> {
    const response = await apiService.get<QuestionnaireInfoResponse>(
      '/api/sign-up/questionary',
      {
        params: {
          entityId,
        },
      },
    );

    return response.data;
  },

  async submitUserAnswers(customerId: number, userAnswers: QuestionWithAnswer[]) {
    const response = await apiService.post<CustomerQuestionnaire>(
      `/api/sign-up/questionary/${customerId}`,
      {
        userAnswers,
      },
    );

    return response.data;
  },

  async getCustomerQuestionnaire(customerId: number) {
    const response = await apiService.get<CustomerQuestionnaire>(
      `/api/user/${customerId}/questionary`,
    );

    return response.data;
  },
};
