import { ValueOfKey } from '../../utils-types';

export const INBODY_ACCOUNT_ACCESS_PERMISSION = {
  NO: 'N',
  REQUESTED: 'R',
  YES: 'Y',
} as const;

export type InbodyAccountAccessPermission = ValueOfKey<
  typeof INBODY_ACCOUNT_ACCESS_PERMISSION
>;
