import moment from 'moment';

export const validateAge = (value, minAge = 12) => {
  const errorMessage = `The user must be ${minAge} years old at least`;

  return isDateLessMinAge(value, minAge) ? true : errorMessage;
};

export const isDateLessMinAge = (value, minAge) =>
  moment(value) < moment().subtract(minAge, 'years');

export const validateMinAge = (value) => validateAge(value);
