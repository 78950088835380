import { ValueOfKey } from '../../utils-types';
import { Option } from '../../general-types';

export const CUSTOMER_SPORT_TYPE = {
  AVERAGE_PERSON: 'AVERAGE_PERSON',
  ATHLETE: 'ATHLETE',
  ATHLETE_SCHOOL: 'ATHLETE_SCHOOL',
} as const;

export type CustomerSportType = ValueOfKey<typeof CUSTOMER_SPORT_TYPE>;

export const customerSportTypeOptions: Option<CustomerSportType>[] = [
  { value: 'AVERAGE_PERSON', label: 'Average person looking to be healthier' },
  { value: 'ATHLETE', label: 'Athlete' },
  {
    value: 'ATHLETE_SCHOOL',
    label: 'Athlete - School/Team/Organization Nutrition Coaching Program',
  },
];
