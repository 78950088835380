import { customerService } from '../../../../../services/customerService';
import { UserProfileFromValue } from '../UserProfileStep.settings';

export const getUserProfileFromValue = async (
  customerId: number,
): Promise<UserProfileFromValue> => {
  const { customer, user, inbodyInfo, additionallyInfo, customerGroup } =
    await customerService.getCustomerInfo(customerId);

  const defaultScanData = {
    height: customer.height,
  } as UserProfileFromValue['scanData'];

  return {
    customer,
    user,
    inbodyInfo,
    additionallyInfo,
    customerGroup: customerGroup || null,
    scanData: defaultScanData,
  };
};
