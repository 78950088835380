import React from 'react';

import { Control, useWatch, UseFormRegister } from 'react-hook-form';

import {
  HiddenInput,
  InputBox,
  InputView,
  LbInput,
} from '../../../../../../../shared/components/form-components';
import { validateOnlyInteger } from '../../../../../../../shared/utils/reactHookFormUtils';
import { INBODY_TYPES } from '../../../../../../../shared/constants/inbody-info/InbodyType';
import { UserProfileFromValue } from '../../NgzUserProfileTabFlow2024.settings';
import { getHumanizeInbodyType } from '../../../../../../../shared/utils/inbodyUtils';

type GeneralDataFromProps = {
  control: Control<UserProfileFromValue>;
  register: UseFormRegister<UserProfileFromValue>;
};

const InbodyInfoForm = ({ control, register }: GeneralDataFromProps) => {
  const generalInputProps = {
    control,
    size: 'small',
    variant: 'standard',
  } as const;

  const privateInfoProps = { InputProps: { className: 'private-info' } };

  const inbodyType = useWatch({ control, name: 'inbodyInfo.inbodyType' });
  const isInbodyType = inbodyType && inbodyType !== INBODY_TYPES.NON_INBODY;

  return (
    <InputBox title="InBody Info">
      <HiddenInput register={register} name="inbodyInfo.inbodyType" value={inbodyType} />
      <InputView value={getHumanizeInbodyType(inbodyType)} label="InBody type" />
      {isInbodyType && (
        <LbInput
          {...generalInputProps}
          {...privateInfoProps}
          name="inbodyInfo.inbodyId"
          label="InBody ID"
          rules={{
            required: 'InBody ID is required',
            validate: { validateOnlyInteger },
          }}
        />
      )}
    </InputBox>
  );
};

export { InbodyInfoForm };
