import { apiService } from '../../shared/services/apiService';
import { AccessParams } from '../../shared/interfaces/AccessParams';
import { SelfSetupCustomer } from '../interfaces/Customer';
import { EntitySingInData } from '../interfaces/EntitySingInData';

export const customerSelfSetupService = {
  async createCustomer(customerInfo: SelfSetupCustomer) {
    const response = await apiService.post<SelfSetupCustomer>(
      '/api/sign-up/customer',
      customerInfo,
    );

    return response.data;
  },

  async updateCustomer(customerId: number, customerInfo: SelfSetupCustomer) {
    const response = await apiService.put<SelfSetupCustomer>(
      `/api/sign-up/customer/${customerId}`,
      customerInfo,
    );

    return response.data;
  },

  async getEntityData(accessParams: AccessParams) {
    const { token, timestamp, hash } = accessParams;
    const params = { sub1: hash, sub2: timestamp, sub3: token };

    const resp = await apiService.get<EntitySingInData>('api/sign-in/entity-data', {
      params,
    });

    return resp.data;
  },
};
