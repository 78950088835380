import { useEffect, useMemo, useState } from 'react';

import { Sport } from '../../shared/interfaces/Sport';
import { sportsService } from '../../shared/services/sportsService';
import { SchoolInfo } from '../../shared/interfaces/school/SchoolInfo';
import { schoolsService } from '../../shared/services/schools/schoolsService';
import { filterInactiveSchools } from '../utils/filterInactiveSchools/filterInactiveSchools';

type UseAdditionalInfoOptionsReturns = {
  sports: Sport[];
  schools: SchoolInfo[];
};

export const useAdditionalInfoOptions = (
  entityId: number,
  selectedSchoolId?: number,
): UseAdditionalInfoOptionsReturns => {
  const [sports, setSports] = useState<Sport[]>([]);
  const [allSchools, setAllSchools] = useState<SchoolInfo[]>([]);

  const schools = useMemo(
    () => filterInactiveSchools(allSchools, selectedSchoolId),
    [allSchools, selectedSchoolId],
  );

  const getAdditionalData = async () => {
    const sportsResponse = await sportsService.getSports();
    const schoolsResponse = await schoolsService.getSchoolsList(entityId);

    setSports(sportsResponse);
    setAllSchools(schoolsResponse.customerSchoolGroups);
  };

  useEffect(() => {
    getAdditionalData();
  }, []);

  return { sports, schools };
};
