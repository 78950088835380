import React from 'react';

import { FormProvider, useForm, UseFormProps } from 'react-hook-form';
import { InputAdornment } from '@mui/material';

import {
  ComboBox,
  HiddenInput,
  InputBox,
  LbAutocomplete,
  LbDatePicker,
  LbInput,
  LbSelect,
  SelectSportPickers,
} from '../../../../shared/components/form-components';
import { genderOptions } from '../../../../shared/constants/user-info/gender';
import { states } from '../../../../shared/constants/states';
import {
  validateEmail,
  validatePhone,
  validateAge,
  validateZipCode,
  isDateLessMinAge,
} from '../../../../shared/utils/reactHookFormUtils';
import { validationService } from '../../../services/validationService';
import { SelfSetupCustomer } from '../../../interfaces/Customer';
import { transformToOptions } from '../../../../shared/utils/arrayUtils';
import {
  CUSTOMER_SPORT_TYPE,
  CustomerSportType,
} from '../../../constants/customerSportType';
import { Sport } from '../../../../shared/interfaces/Sport';
import { SchoolInfo } from '../../../../shared/interfaces/school/SchoolInfo';

type PersonalInfoFormFormValues = SelfSetupCustomer;

type PersonalInfoFormProps = {
  formConfig: UseFormProps<PersonalInfoFormFormValues>;
  onSubmit: (data: PersonalInfoFormFormValues) => void;
  formId: string;
  customerSportType: CustomerSportType;
  entitySchools?: SchoolInfo[];
  sports?: Sport[];
};

const PersonalInfoForm = ({
  formConfig,
  onSubmit,
  formId,
  customerSportType,
  entitySchools = [],
  sports = [],
}: PersonalInfoFormProps) => {
  const formMethods = useForm<PersonalInfoFormFormValues>(formConfig);

  const { control, register, handleSubmit, setError, formState, watch } = formMethods;

  const { dirtyFields } = formState;

  const dateOfBirth = watch('dateOfBirth');

  const generalInputProps = {
    control,
    size: 'small',
    variant: 'standard',
  } as const;

  const validateExistUser = async (email: string) => {
    try {
      await validationService.validateEmail(email, [400]);
    } catch (error: any) {
      setError('email', {
        message: error.response?.data?.message || 'User already exists',
      });
      throw error;
    }
  };

  const handleFormSubmit = handleSubmit(async (data) => {
    if (dirtyFields.email) {
      await validateExistUser(data.email);
    }

    onSubmit(data);
  });

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleFormSubmit} id={formId} noValidate>
        <InputBox title="Personal Data">
          <LbInput
            {...generalInputProps}
            name="firstName"
            label="First Name"
            rules={{ required: 'First Name is required' }}
          />
          <LbInput
            {...generalInputProps}
            name="lastName"
            label="Last Name"
            rules={{ required: 'Last Name is required' }}
          />
          <LbInput
            {...generalInputProps}
            name="email"
            label="Email address"
            type="email"
            rules={{ required: 'Email is required', validate: { validateEmail } }}
          />
          <LbInput
            {...generalInputProps}
            name="phone"
            label="Cell Phone"
            type="tel"
            rules={{ required: 'Cell Phone is required', validate: { validatePhone } }}
          />
          <LbDatePicker
            {...generalInputProps}
            name="dateOfBirth"
            label="Date of Birth"
            helperText="You need to be at least 14 years to join the LifeBase"
            openTo="year"
            rules={{
              required: 'Date of Birth is required',
              validate: { validateMinAge: (value) => validateAge(value, 14) },
            }}
          />
          <LbSelect
            {...generalInputProps}
            name="gender"
            label="Gender"
            options={genderOptions}
            rules={{ required: 'Gender is required' }}
          />
          <LbInput
            {...generalInputProps}
            name="height"
            label="Height"
            type="number"
            rules={{
              required: 'Height is required',
              min: { value: 1, message: 'Height must be greater than 1' },
              max: { value: 140, message: 'Height must be less than 140' },
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">inches</InputAdornment>,
            }}
          />
        </InputBox>
        <HiddenInput
          register={register}
          name="additionallyInfo.sportType"
          value={customerSportType}
        />
        {customerSportType === CUSTOMER_SPORT_TYPE.ATHLETE_SCHOOL && (
          <InputBox title="Additionally">
            <ComboBox
              {...generalInputProps}
              name="additionallyInfo.schoolId"
              label="School/Team/Organization"
              rules={{ required: 'School/Team/Organization is required' }}
              options={transformToOptions({
                list: entitySchools,
                valueKey: 'id',
                labelKey: 'name',
              })}
            />
            <LbInput
              {...generalInputProps}
              name="additionallyInfo.parentEmail"
              label="Parent/Guardian email"
              rules={{
                required: isDateLessMinAge(dateOfBirth, 18)
                  ? false
                  : 'Parent/Guardian email is required',
                validate: { validateEmail },
              }}
            />
            <SelectSportPickers
              control={control}
              sports={sports}
              sportFieldProps={{
                name: 'additionallyInfo.sport',
                rules: { required: 'Sport is required' },
              }}
              sportPositionFieldProps={{
                name: 'additionallyInfo.sportPosition',
                rules: { required: 'Position is required' },
              }}
            />
          </InputBox>
        )}
        <InputBox title="Address">
          <LbInput
            {...generalInputProps}
            name="address"
            label="Address"
            rules={{ required: 'Address is required' }}
          />
          <LbInput
            {...generalInputProps}
            name="city"
            label="City"
            rules={{ required: 'City is required' }}
          />
          <LbAutocomplete
            {...generalInputProps}
            name="state"
            label="State"
            options={states}
            rules={{ required: 'State is required', deps: ['zipCode'] }}
          />
          <LbInput
            {...generalInputProps}
            name="zipCode"
            label="Zip Code"
            rules={{
              required: 'Zip Code is required',
              validate: async (zipCode, { state }) => validateZipCode(zipCode, state),
            }}
          />
        </InputBox>
      </form>
    </FormProvider>
  );
};

export { PersonalInfoForm, PersonalInfoFormFormValues, PersonalInfoFormProps };
