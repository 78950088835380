import React from 'react';

import { Control } from 'react-hook-form';
import { Button, SvgIcon } from '@mui/material';
import { useToggle } from 'react-use';

import classes from './BioFormSection.module.scss';
import { InfoBox, LbChip } from '../../../../../shared/components/ui-components';
import { ReactComponent as PenIcon } from '../../../../../shared/assets/icons/pen.svg';
import { CoachBio, CoachInfoFormValue } from '../../../../interfaces/CoachData';
import { TitleWithDescription } from '../../../../../shared/components/text-formation-components';
import { loggedInUserDataStore } from '../../../../../shared/stores/loggedInUserDataStore';
import { ChipLabel } from './ChipLabel/ChipLabel';
import { LbSwitch } from '../../../../../shared/components/form-components';
import { CoachBioModal } from './CoachBioModal/CoachBioModal';
import { coachService } from '../../../../services/coachService';
import {
  CONSULTATION_TYPE,
  ConsultationType,
} from '../../../../../shared/constants/coach/consultationType';

type BioFormSectionProps = {
  control: Control<CoachInfoFormValue>;
  coachId: number;
  coachBio: CoachBio;
  onUpdateCoachBio: (data: CoachBio) => void;
  consultationType: ConsultationType;
};

const BioFormSection = ({
  coachId,
  control,
  coachBio,
  onUpdateCoachBio,
  consultationType,
}: BioFormSectionProps) => {
  const [isShowBioModal, toggleShowBioModal] = useToggle(false);

  const { isSuperAdmin } = loggedInUserDataStore;

  const handleUpdateBio = async (data: CoachBio) => {
    if (isSuperAdmin) {
      const bio = await coachService.updateBioBySuperAdmin(coachId, data);
      onUpdateCoachBio(bio);
    } else {
      const bio = await coachService.updateBioByCoach(coachId, { bio: data.bio! });
      onUpdateCoachBio(bio);
    }
  };

  const publishChip = coachBio.publishBio ? (
    <LbChip label={<ChipLabel label="Published" />} size="small" color="success" />
  ) : (
    <LbChip label={<ChipLabel label="Unpublished" />} size="small" color="default" />
  );

  const isRemote = consultationType === CONSULTATION_TYPE.REMOTE;

  const hideBio = !isSuperAdmin && !isRemote;

  if (hideBio) {
    return null;
  }

  return (
    <>
      <InfoBox
        title={
          <TitleWithDescription
            title={
              <div className={classes.bioChipBox}>
                Bio
                {isRemote && publishChip}
              </div>
            }
            description="Describe your skills or experience briefly to help customers choose you as their coach"
            titleFont="inherit"
          />
        }
        icon={
          <LbSwitch
            control={control}
            name="additional.consultationType"
            label="Remote coaching services enabled"
            checkedValue={CONSULTATION_TYPE.REMOTE}
            uncheckedValue={CONSULTATION_TYPE.OFFLINE}
            disabled={!isSuperAdmin}
          />
        }
        hideConfig={{ show: isRemote }}
      >
        <div className={classes.bioDescription}>
          <span>{coachBio.bio || 'No Description'}</span>
          <Button
            variant="text"
            color="secondary"
            size="small"
            startIcon={<SvgIcon component={PenIcon} inheritViewBox fontSize="small" />}
            onClick={toggleShowBioModal}
          >
            Edit
          </Button>
        </div>
      </InfoBox>
      {isShowBioModal && (
        <CoachBioModal
          bio={coachBio.bio}
          onSubmit={handleUpdateBio}
          onClose={toggleShowBioModal}
        />
      )}
    </>
  );
};

export { BioFormSection };
