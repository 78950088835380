import React, { useMemo } from 'react';

import { Button, Divider, Paper } from '@mui/material';
import { useToggle } from 'react-use';
import { getService } from 'react-in-angularjs';

import { useFormContext } from 'react-hook-form';
import ShowChartIcon from '@mui/icons-material/ShowChartRounded';
import FormatListBulletedRoundedIcon from '@mui/icons-material/FormatListBulletedRounded';
import { enqueueSnackbar } from 'notistack';

import classes from './InbodyIndicatorsTab.module.scss';
import { ComboBox, LbSelect } from '../../../../../../shared/components/form-components';
import {
  LbTabPanel,
  LbTabs,
  LbTabsList,
  TabsConfig,
} from '../../../../../../shared/components/tabs';
import { InbodyIndicatorsTableView } from './InbodyIndicatorsTableView/InbodyIndicatorsTableView';
import { InbodyIndicatorsChartView } from './InbodyIndicatorsChartView/InbodyIndicatorsChartView';
import { CustomerParam } from './CustomerParam/CustomerParam';
import { InbodyIndicatorInfo } from '../../../../../interfaces/inbodyIndicator';
import { Option } from '../../../../../../general-types';
import { getScanDateOptions } from './utils/getScanDateOptions';
import { InbodyIndicatorsOptions } from '../InbodyIndicatorsOptions/InbodyIndicatorsOptions';
import { SocialMediaScanMetricsModal } from '../SocialMediaScanMetricsModal/SocialMediaScanMetricsModal';
import { CompareScanMetricsModal } from '../CompareScanMetricsModal/CompareScanMetricsModal';
import { MealPlanOnScanDayInfo } from '../../../../../interfaces/mealPlanOnScanDayInfo';
import { getFormattedDate } from '../../../../../../shared/utils/dateUtils';
import {
  dateFormat,
  dateTimeFormat,
} from '../../../../../../shared/constants/apiDateFormat';
import {
  InbodyType,
  INBODY_TYPES,
} from '../../../../../../shared/constants/inbody-info/InbodyType';

const CheckInViewTabs = {
  CHART: 'CHART',
  TABLE: 'TABLE',
} as const;
const { CHART, TABLE } = CheckInViewTabs;

type InbodyIndicatorsFormValues = {
  scanDate: string;
  inbodyType: string;
};

type CustomerInfo = {
  id: number;
  age: number;
  gender: string;
  height: number;
  inbodyId: string;
  inbodyType: InbodyType;
};

type InbodyIndicatorsTabProps = {
  customerInbodyIndicators: InbodyIndicatorInfo[];
  mealPlansOnScanDay: MealPlanOnScanDayInfo[];
  inbodyTypeOptions: Option[];
  customerInfo: CustomerInfo;
};

const InbodyIndicatorsTab = ({
  customerInbodyIndicators,
  mealPlansOnScanDay,
  inbodyTypeOptions,
  customerInfo,
}: InbodyIndicatorsTabProps) => {
  const [isShowSocialMediaScanMetricsModal, toggleShowSocialMediaScanMetricsModal] =
    useToggle(false);
  const [isShowCompareScanMetricsModal, toggleShowCompareScanMetricsModal] =
    useToggle(false);
  const { watch, control } = useFormContext<InbodyIndicatorsFormValues>();

  const scanDateOptions = getScanDateOptions(customerInbodyIndicators);

  const selectedScanTime = watch('scanDate');

  const openScanSummary = async () => {
    const ScenariosService = getService('ScenariosService');
    const ModalService = getService('ModalService');

    const scanDate = selectedScanTime
      ? getFormattedDate(selectedScanTime, dateFormat, dateTimeFormat)
      : undefined;

    const resp = await ScenariosService.getSuggestionForUser(customerInfo.id, scanDate);

    if (resp.status === 200) {
      ModalService.suggestScenario(resp.data);
    } else {
      enqueueSnackbar(resp.data.message, {
        variant: 'error',
      });
    }
  };

  const tabsConfigs: TabsConfig[] = [
    {
      label: <ShowChartIcon fontSize="small" />,
      value: CHART,
      panel: (
        <InbodyIndicatorsChartView
          customerInbodyIndicators={customerInbodyIndicators}
          mealPlansOnScanDay={mealPlansOnScanDay}
          selectedScanTime={selectedScanTime}
        />
      ),
    },
    {
      label: <FormatListBulletedRoundedIcon fontSize="small" />,
      value: TABLE,
      panel: (
        <InbodyIndicatorsTableView
          customerInbodyIndicators={customerInbodyIndicators}
          selectedScanTime={selectedScanTime}
        />
      ),
    },
  ];

  const generalInputProps = {
    control,
    className: classes.input,
    variant: 'outlined',
    size: 'small',
  } as const;

  const hideScanSummaryButton = useMemo(() => {
    const notSupportScenario: InbodyType[] = [
      INBODY_TYPES.INBODY_270,
      INBODY_TYPES.INBODY_370,
    ];

    return notSupportScenario.includes(customerInfo.inbodyType);
  }, [customerInfo.inbodyType]);

  return (
    <>
      <LbTabs defaultValue={CHART}>
        <Paper variant="box" className={classes.topBlock}>
          <div className={classes.leftItems}>
            <CustomerParam
              title="ID"
              value={customerInfo.inbodyId}
              className="private-info"
            />
            <Divider orientation="vertical" flexItem />
            <CustomerParam title="Height" value={`${customerInfo.height}in`} />
            <Divider orientation="vertical" flexItem />
            <CustomerParam title="Age" value={customerInfo.age} />
            <Divider orientation="vertical" flexItem />
            <CustomerParam title="Gender" value={customerInfo.gender} />
            <Divider orientation="vertical" flexItem />
            <LbSelect
              {...generalInputProps}
              name="inbodyType"
              label="InBody Machine"
              options={inbodyTypeOptions}
            />
            <Divider orientation="vertical" flexItem />
            <ComboBox
              {...generalInputProps}
              name="scanDate"
              label="Scan Date"
              options={scanDateOptions}
            />
          </div>
          <div className={classes.rightItems}>
            <LbTabsList
              className={classes.tabsList}
              tabsConfig={tabsConfigs}
              variant="toggle"
            />
            {!hideScanSummaryButton && (
              <Button variant="outlined" size="small" onClick={openScanSummary}>
                Scan Summary
              </Button>
            )}
            <InbodyIndicatorsOptions
              onCompareScanMetrics={toggleShowCompareScanMetricsModal}
              onRequestSocialTemplate={toggleShowSocialMediaScanMetricsModal}
            />
          </div>
        </Paper>
        <LbTabPanel tabsConfig={tabsConfigs} />
      </LbTabs>
      {isShowSocialMediaScanMetricsModal && (
        <SocialMediaScanMetricsModal
          customerId={customerInfo.id}
          scanDateTimes={scanDateOptions.map((option) => String(option.value))} // fix get separate scan dates
          onClose={toggleShowSocialMediaScanMetricsModal}
        />
      )}
      {isShowCompareScanMetricsModal && (
        <CompareScanMetricsModal
          customerInfo={customerInfo}
          customerInbodyIndicators={customerInbodyIndicators}
          onClose={toggleShowCompareScanMetricsModal}
        />
      )}
    </>
  );
};

export { InbodyIndicatorsTab };
