import { Option } from '../../../general-types';
import { ValueOfKey } from '../../../utils-types';
// replace to module
// use getHumanizeInbodyType utils to get showed name

export const INBODY_TYPES = {
  INBODY_270: 'INBODY_270',
  INBODY_370: 'INBODY_370',
  INBODY_380H: 'INBODY_380H',
  INBODY_570: 'INBODY_570',
  INBODY_580H: 'INBODY_580H',
  INBODY_770: 'INBODY_770',
  INBODY_970: 'INBODY_970',
  H20B: 'H20B',
  NON_INBODY: 'NON_INBODY',
} as const;

export type InbodyType = ValueOfKey<typeof INBODY_TYPES>;

// used on old api (entity info supportedInbodyTypes) prefer use InbodyType
export type InbodyTypeKey =
  | 'inbody_270'
  | 'inbody_370'
  | 'inbody_380H'
  | 'inbody_570'
  | 'inbody_580H'
  | 'inbody_770'
  | 'inbody_970';

export const inbodyTypeOptions: Option<Exclude<InbodyType, 'H20B'>>[] = [
  { value: 'INBODY_270', label: 'InBody 270' },
  { value: 'INBODY_370', label: 'InBody 370' },
  { value: 'INBODY_380H', label: 'InBody 380H' },
  { value: 'INBODY_570', label: 'InBody 570' },
  { value: 'INBODY_580H', label: 'InBody 580H' },
  { value: 'INBODY_770', label: 'InBody 770' },
  { value: 'INBODY_970', label: 'InBody 970' },
  { value: 'NON_INBODY', label: 'Non InBody' },
];

export const inbodyTypeOptionsWithAll: Option<Exclude<InbodyType, 'H20B'> | ''>[] = [
  { value: '', label: 'All' },
  ...inbodyTypeOptions,
];

export const humanizeInbodyType: Record<InbodyType, string> = {
  INBODY_270: 'InBody 270',
  INBODY_370: 'InBody 370',
  INBODY_380H: 'InBody 380H',
  INBODY_570: 'InBody 570',
  INBODY_580H: 'InBody 580H',
  INBODY_770: 'InBody 770',
  INBODY_970: 'InBody 970',
  H20B: 'H20B',
  NON_INBODY: 'Non InBody',
};
